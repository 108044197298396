
import '../src/assets/styles/App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import "lightgallery.js/dist/css/lightgallery.css";
import PrivateRouter from './Utils/PrivateRouter';
import AuthLoginPage from './AuthPages/AuthLoginPage';
import ContactAdminPage from './AuthPages/ContactAdmin'
import MainLayout from './Layout/MainLayout';
import Error404Page from './Pages/Error404Page';
import PrivateRoutes from './Routes/Routes'
import SuspenseLoader from './Utils/SuspenseLoader';
// const LazyHomePage = React.lazy(() => import('./Pages/HomePage'))
// const LazyProductPage = React.lazy(() => import('./Pages/ProductsPage'))
import AuthContextProvider from '../src/context/auth/authProvider';
import { RefreshProvider } from './Components/GlobalComponents/DataTableRefresh';
import { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';

function App(props) {

  // Destructure accessRights from redux
  const { accessRights, isAuthorised } = props;

  // Initialize an empty array to hold module keys
  const accessModuleKeys = [];

  // Map over accessRights to collect module keys
  const checkRouteKey = accessRights && accessRights.map((valid, index) => {
    if (valid.module_key) {
      accessModuleKeys.push(valid.module_key);
    }
  });

  // Filter PrivateRoutes to include only those routes that match module keys
  const filteredRoutes = PrivateRoutes.filter(route =>
    // Check if the routerKey of the route is in the accessModuleKeys array
    accessModuleKeys.includes(route.routerKey)
  );

  // console.log("filteredRoutes", filteredRoutes);

  return (
    <div className="App">

      {/*----------------------------- Toaster Section ----------------------*/}
      <Toaster position="top-right1"></Toaster>

      <AuthContextProvider>
        <RefreshProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route element={<PrivateRouter />}>
                <Route element={<MainLayout />}>
                  {
                    filteredRoutes.length > 0 ? filteredRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact
                          element={
                            <React.Suspense fallback={<SuspenseLoader />}>
                              {route.element}
                            </React.Suspense>
                          } />
                      )
                    })
                      :
                      <Route path="/contact-admin" element={<ContactAdminPage />} />
                  }
                </Route>
              </Route>

              {/* Login Route */}
              <Route path="/login" element={<AuthLoginPage />} />

              {/* Handle invalid routes */}
              <Route path="*"
                element={
                  isAuthorised ? (
                    <Error404Page /> // Show 404 if logged in
                  ) : (
                    <Navigate to="/login" /> // Redirect to login if not logged in
                  )
                }
              />
            </Routes>
          </BrowserRouter>
        </RefreshProvider>
      </AuthContextProvider>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    accessRights: state.accessRights,
    isAuthorised: state.isAuthorised,

  }
};

export default connect(mapStateToProps)(App)

