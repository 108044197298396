// imageUrlUtils.js

// Function to check if a URL contains a domain name
function hasDomain(url) {
  try {
    // URL object banane ki koshish karte hain
    const urlObj = new URL(url);
    // Agar URL object me protocol aur host hai, toh domain maujood hai
    return urlObj.protocol && urlObj.host;
    // return !!urlObj.protocol && !!urlObj.hostname;
  } catch (error) {
    // Agar error aata hai, toh URL me domain nahi hai
    return false;
  }
}

// URL type ko determine karne wala function
function checkImageUrl(url) {
  // Check karte hain agar URL me domain hai
  const isFullUrl = hasDomain(url);
  // URL ka type return karte hain (Full URL ya Relative URL)
  return isFullUrl ? "Full URL" : "Relative URL";
}

// Export the function for use in other parts of your application
export { checkImageUrl };
