// imageErrorHandler.js

// Import the fallback image (adjust the path as needed)
import NoImageFound from "../assets/images/no_image_available.png";

// Define the image error handler function
export function imageOnErrorHandler(event) {
    // Set the image source to the fallback image when an error occurs
    event.currentTarget.src = NoImageFound;
    // Remove the onerror event handler to prevent an infinite loop
    event.currentTarget.onerror = null;
}