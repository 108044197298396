import React from 'react';


// --------------- Dashboard Section Pages -----------------------
const DashboardLazy = React.lazy(() => import('../Pages/HomePage'));

// --------------- Header Section Pages -----------------------
const ForceUpdateLazy = React.lazy(() => import('../Pages/ForceUpdatePage'));
const AccessControlLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeAccess'));
const AddNewAdminUsersLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeAccess/AddNewAdminUser'));
const UpdateNewAdminUsersLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeAccess/UpdateNewAdminUser'));

// ------------- Settings Pagess ---------------------------
const ZoneSettingLazy = React.lazy(() => import('../Pages/SettingPages/ZoneSettingPage'));
const DeliveryChargeSurgeLazy = React.lazy(() => import('../Pages/SettingPages/DeliveryChargeSurge'));
const DeliveryChargeLazy = React.lazy(() => import('../Pages/SettingPages/DeliveryChargePage'));
const DistanceSettingLazy = React.lazy(() => import('../Pages/SettingPages/DistanceSettingPage'));
const EmployeeRoleLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeRole'));
const AddEmployeeRoleLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeRole/AddEmployeeRole'));
const EditEmployeeRoleLazy = React.lazy(() => import('../Pages/AccessControlPage/EmployeeRole/EditEmployeeRole'));
const PlatformFeesLazy = React.lazy(() => import('../Pages/SettingPages/PlatformFeesPage'));
const AdminPanelSettingLazy = React.lazy(() => import('../Pages/AccessControlPage/AdminPanelSetting'));


// ------------- User Pages ---------------------------
const UserListLazy = React.lazy(() => import('../Pages/UserPages/UserList'));
const UpdateUserListLazy = React.lazy(() => import('../Pages/UserPages/UserList/UpdateNewUser'));
const UserWalletRecordLazy = React.lazy(() => import('../Pages/UserPages/UserWalletRecordPage'));


// ------------- Wallet Offers Pages ---------------------------
const WalletOffersLazy = React.lazy(() => import('../Pages/WalletOffersPage'));


// ------------- Announcement Offers Pages ---------------------------
const AnnouncementLazy = React.lazy(() => import('../Pages/AnnouncementPage'));


// ------------- Cuisines Pages ---------------------------
const CuisinesLazy = React.lazy(() => import('../Pages/CuisinesPage'));

// ------------- Restaurant Listin Request Pages ---------------------------
const RestaurantListingRequestLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantListingRequest'));

// ------------- Delivery Partner Pages ---------------------------
const DeliveyPartnerListLazy = React.lazy(() => import('../Pages/DeliveryPartnerPage/DeliveyPartnerList'));
const AddDeliveryBoyLazy = React.lazy(() => import('../Pages/DeliveryPartnerPage/DeliveyPartnerList/AddDeliveryBoy'));
const UpdateDeliveryBoyListLazy = React.lazy(() => import('../Pages/DeliveryPartnerPage/DeliveyPartnerList/UpdateDeliveryPartner'));
////// Add Hnadover 
const AddHandoverListLazy = React.lazy(() => import('../Pages/DeliveryPartnerPage/AddHandover'));
const JobCandidateLazy = React.lazy(() => import('../Pages/DeliveryPartnerPage/JobCandidate'));


// ------------- Cuisines Pages ---------------------------
const CityManagerLazy = React.lazy(() => import('../Pages/CityManagerPage'));

// ------------- Restaurant List Pages ---------------------------
const RestaurantListLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantList'));
const AddRestaurantLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantList/AddRestaurant'));
const EditRestaurantLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantList/EditRestaurant'));
const RestaurantDetailsLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantList/RestaurantDetailsPage'));
const RestaurantMenuLazy = React.lazy(() => import('../Pages/RestaurantPage/MenuPages'));
const ProductCustomizationLazy = React.lazy(() => import('../Pages/RestaurantPage/MenuPages/MenuProducts/ProductCustomization'));
const ResturantBannersLazy = React.lazy(() => import('../Pages/RestaurantPage/ResturantBanners'));
const RestaurantReviewsLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantReviews'));
const RestaurantTimingLogsLazy = React.lazy(() => import('../Pages/RestaurantPage/RestaurantTimingLogs'));



// ------------- Dispacher Pages ---------------------------
const DispacherListLazy = React.lazy(() => import('../Pages/DispacherPage'));
const OrderDetailsListLazy = React.lazy(() => import('../Components/DispacherComponents/EditOrders'));


// ------------- Deliveries Pages ---------------------------
const CrisfoodDeliveriesLazy = React.lazy(() => import('../Pages/DeliveriesPage/CrisfoodDeliveries'));
const FlipDeliveriesLazy = React.lazy(() => import('../Pages/DeliveriesPage/FlipDeliveries'));

// ------------- Info Pages ---------------------------
const AboutUsPageLazy = React.lazy(() => import('../Pages/InfoPages/AboutUsPage'));
const ContactUsPageLazy = React.lazy(() => import('../Pages/InfoPages/ContactUsPage'));
const CancellationAndRefundLazy = React.lazy(() => import('../Pages/InfoPages/CancellationAndRefund'));
const PrivacyPolicyLazy = React.lazy(() => import('../Pages/InfoPages/PrivacyPolicyPage'));
const DeliveryPrivacyPolicyLazy = React.lazy(() => import('../Pages/InfoPages/PrivacyPolicyDelivery'));
const RestaurantPrivacyPolicyLazy = React.lazy(() => import('../Pages/InfoPages/PrivacyPolicyRestaurant'));
const TermsConditionLazy = React.lazy(() => import('../Pages/InfoPages/TermsCondition'));
const DeliveryTermsConditionLazy = React.lazy(() => import('../Pages/InfoPages/TermsConditionDelivery'));
const RestaurantTermsConditionLazy = React.lazy(() => import('../Pages/InfoPages/TermsConditionRestaurant'));
const FrequentlyAskedQuestionsLazy = React.lazy(() => import('../Pages/InfoPages/FrequentlyAskedQuestions'));

// ------------- Website Offers Pages ---------------------------
const WebsiteOffersLazy = React.lazy(() => import('../Pages/WebsitesPage/WebsiteOffers'));
const WebsiteAdsLazy = React.lazy(() => import('../Pages/WebsitesPage/WebsiteAds'));

// ------------- Admin Chat Pages ---------------------------
const AdminChatLazy = React.lazy(() => import('../Pages/AdminChat'));


// ------------- Promocodes Pages ---------------------------
const PromocodesLazy = React.lazy(() => import('../Pages/PromocodesPage/PromocodesList'));
const AddPromocodesLazy = React.lazy(() => import('../Pages/PromocodesPage/PromocodesList/AddPromocodes'));
const EditPromocodesLazy = React.lazy(() => import('../Pages/PromocodesPage/PromocodesList/EditPromocodes'));
const PromoBannerLazy = React.lazy(() => import('../Pages/PromocodesPage/PromoBanners'));


// ------------- SubscriptionPlans Pages ---------------------------
const SubscriptionPlansLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionPlans'));
const AddSubscriptionPlansLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionPlans/AddSubscriptionPlans'));
const EditSubscriptionPlansLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionPlans/EditSubscriptionPlans'));

// ------------- Subscription Benefits Pages ---------------------------
const SubscriptionBenefitsLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionBenefits'));
const AddSubscriptionBenefitsLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionBenefits/AddBenefits'));
const EditSubscriptionBenefitsLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionBenefits/EditBenefits'));

// ------------- Subscription History Pages ---------------------------
const SubscriptionHistoryLazy = React.lazy(() => import('../Pages/SubscriptionsPage/SubscriptionHistory'));

// ------------- Payment Settings Pages ---------------------------
const PaymentOptionsLazy = React.lazy(() => import('../Pages/PaymentSettingsPages/PaymentOptions'));
const PaymentGatewayLazy = React.lazy(() => import('../Pages/PaymentSettingsPages/PaymentGateway'));
const PaymentActiveMethodLazy = React.lazy(() => import('../Pages/PaymentSettingsPages/ActivePaymentMethod'));

// ------------- Pick Drop Settings Pages ---------------------------
const PickDropZoneLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropZoneSettings'));
const PickDropDeliveryChargeLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropDeliveryCharge'));
const PickDropServiceChargeLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropServiceCharge'));
const PickDropPlatformFeesLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropPlatformFees'));
const PickDropGSTLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropGST'));
const PickDropDistanceSettingLazy = React.lazy(() => import('../Pages/PickDropPages/PickDropSettings/PickDropDistanceSetting'));



// ------------- Dispute History Pages ---------------------------
const PicDropOrderEditLazy = React.lazy(() => import('../Pages/PickDropPages/PickdropDispacherPage/PickDropOrderEditPage'));
const UserDisputeyLazy = React.lazy(() => import('../Pages/DisputePage/UserDisputePage'));
const PickDropDisputeyLazy = React.lazy(() => import('../Pages/DisputePage/PickDropDisputePage'));



// Catch-all route for 404
const Error404PageLazy = React.lazy(() => import('../Pages/Error404Page'));





const routes = [
    // --------------- Default Section Pages -----------------------
    // { path: '/', exact: true, name: 'Default', element: <DispacherListLazy /> },

    // --------------- Dashboard Section Pages -----------------------
    { path: '/dashboard', exact: true, name: 'Default', routerKey: "DASHBOARD", element: <DashboardLazy /> },

    // --------------- Header Section Pages -----------------------
    { path: '/force-update', exact: true, name: 'Default', routerKey: "FORCE_UPDATE", element: <ForceUpdateLazy /> },
    { path: '/access-control', exact: true, name: 'Default', routerKey: "ACCESS_CONTROL", element: <AccessControlLazy /> },
    { path: '/add-new-admin-users', exact: true, name: 'Default', routerKey: "ADD_ADMIN_USER", element: <AddNewAdminUsersLazy /> },
    { path: '/update-new-admin-users/:adminUserId', exact: true, name: 'Default', routerKey: "UPDATE_ADMIN_USER", element: <UpdateNewAdminUsersLazy /> },

    // ------------- Settings Pages ---------------------------
    { path: '/zone-setting', exact: true, name: 'Default', routerKey: "ZONE_SETTING", element: <ZoneSettingLazy /> },
    { path: '/delivery-charge-surge', exact: true, name: 'Default', routerKey: "DELIVERY_CHARGE_SURGE", element: <DeliveryChargeSurgeLazy /> },
    { path: '/delivery-charge', exact: true, name: 'Default', routerKey: "DELIVERY_CHARGE", element: <DeliveryChargeLazy /> },
    { path: '/distance-setting', exact: true, name: 'Default', routerKey: "DISTANCE_SETTING", element: <DistanceSettingLazy /> },
    { path: '/employee-role', exact: true, name: 'Default', routerKey: "EMPLOYEE_ROLE", element: <EmployeeRoleLazy /> },
    { path: '/add-employee-role', exact: true, name: 'Default', routerKey: "ADD_EMPLOYEE_ROLE", element: <AddEmployeeRoleLazy /> },
    { path: '/edit-employee-role/:roleId', exact: true, name: 'Default', routerKey: "EDIT_EMPLOYEE_ROLE", element: <EditEmployeeRoleLazy /> },
    { path: '/platform-fees', exact: true, name: 'Default', routerKey: "PLATFORM_FEES", element: <PlatformFeesLazy /> },
    { path: '/admin-panel-setting', exact: true, name: 'Default', routerKey: "UPDATE_ADMIN_USER", element: <AdminPanelSettingLazy /> },

    // ------------- User Pages ---------------------------
    { path: '/user-list', exact: true, name: 'Default', routerKey: "USER_LIST", element: <UserListLazy /> },
    { path: '/update-user-list/:userId', exact: true, name: 'Default', routerKey: "UPDATE_USER_LIST", element: <UpdateUserListLazy /> },
    { path: '/user-wallet-record', exact: true, name: 'Default', routerKey: "USER_LIST", element: <UserWalletRecordLazy /> },

    // ------------- Wallet Offers Pages ---------------------------
    { path: '/wallet-offers', exact: true, name: 'Default', routerKey: "WALLET_OFFERS", element: <WalletOffersLazy /> },

    // ------------- Announcement Pages ---------------------------
    { path: '/announcement', exact: true, name: 'Default', routerKey: "ANNOUNCEMENT", element: <AnnouncementLazy /> },

    // ------------- Cuisines Pages ---------------------------
    { path: '/cuisines', exact: true, name: 'Default', routerKey: "CUISINES", element: <CuisinesLazy /> },

    // ------------- Delivery Partner Pages ---------------------------
    { path: '/delivey-partner-list', exact: true, name: 'Default', routerKey: "DELIVERY_PARTNER_LIST", element: <DeliveyPartnerListLazy /> },
    { path: '/add-delivery-boy', exact: true, name: 'Default', routerKey: "ADD_DELIVERY_BOY", element: <AddDeliveryBoyLazy /> },
    { path: '/delivery-partner-details/:deliveryBoyId', exact: true, name: 'Default', routerKey: "UPDATE_DELIVERY_BOY", element: <UpdateDeliveryBoyListLazy /> },
    { path: '/add-handover', exact: true, name: 'Default', routerKey: "ADD_HANDOVER", element: <AddHandoverListLazy /> },
    { path: '/job-candidate', exact: true, name: 'Default', routerKey: "JOB_CANDIDATE", element: <JobCandidateLazy /> },

    // ------------- City Manager Pages ---------------------------
    { path: '/city-manager', exact: true, name: 'Default', routerKey: "CITY_MANAGER", element: <CityManagerLazy /> },

    // ------------- Restaurant List Pages ---------------------------
    { path: '/restaurant-list', exact: true, name: 'Default', routerKey: "RESTAURANT_LIST", element: <RestaurantListLazy /> },
    { path: '/add-restaurant', exact: true, name: 'Default', routerKey: "ADD_RESTAURANT", element: <AddRestaurantLazy /> },
    { path: '/edit-restaurant/:restaurantId', exact: true, name: 'Default', routerKey: "EDIT_RESTAURANT", element: <EditRestaurantLazy /> },
    { path: '/restaurant-details/:restaurantId', exact: true, name: 'Default', routerKey: "RESTAURANT_DETAILS", element: <RestaurantDetailsLazy /> },
    { path: '/restaurant-menu/:restaurantId', exact: true, name: 'Default', routerKey: "RESTAURANT_MENU", element: <RestaurantMenuLazy /> },
    { path: '/product-customization/:restaurantId/:productId', exact: true, name: 'Default', routerKey: "PRODUCT_CUSTOMIZATION", element: <ProductCustomizationLazy /> },
    { path: '/restaurantListingRequest', exact: true, name: 'Default', routerKey: "RESTAURANT_LISTING_REQUEST", element: <RestaurantListingRequestLazy /> },
    { path: '/resturant-banners', exact: true, name: 'Default', routerKey: "RESTAURANT_BANNERS", element: <ResturantBannersLazy /> },
    { path: '/restaurant-reviews', exact: true, name: 'Default', routerKey: "RESTAURANT_BANNERS", element: <RestaurantReviewsLazy /> },
    { path: '/restaurant-timing-logs', exact: true, name: 'Default', routerKey: "RESTAURANT_BANNERS", element: <RestaurantTimingLogsLazy /> },



    // ------------- Dispatcher Pages ---------------------------
    { path: '/dispacher-list', exact: true, name: 'Default', routerKey: "DISPATCHER_LIST", element: <DispacherListLazy /> },
    { path: '/order-details/:orderId', exact: true, name: 'Default', routerKey: "ORDER_DETAILS", element: <OrderDetailsListLazy /> },

    // ------------- Deliveries Pages ---------------------------
    { path: '/crisfood-deliveries', exact: true, name: 'Default', routerKey: "CRISFOOD_DELIVERIES", element: <CrisfoodDeliveriesLazy /> },
    { path: '/flip-deliveries', exact: true, name: 'Default', routerKey: "FLIP_DELIVERIES", element: <FlipDeliveriesLazy /> },

    // ------------- Info Pages ---------------------------
    { path: '/about-page', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <AboutUsPageLazy /> },
    { path: '/contact-page', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <ContactUsPageLazy /> },
    { path: '/cancellation-refund', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <CancellationAndRefundLazy /> },
    { path: '/privacy-policy', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <PrivacyPolicyLazy /> },
    { path: '/delivery-privacy-policy', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <DeliveryPrivacyPolicyLazy /> },
    { path: '/restaurant-privacy-policy', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <RestaurantPrivacyPolicyLazy /> },
    { path: '/terms-conditions', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <TermsConditionLazy /> },
    { path: '/delivery-terms-conditions', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <DeliveryTermsConditionLazy /> },
    { path: '/restaurant-terms-conditions', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <RestaurantTermsConditionLazy /> },
    { path: '/frequently-asked-questions', exact: true, name: 'Default', routerKey: "INFO_PAGES", element: <FrequentlyAskedQuestionsLazy /> },

    // ------------- Website Pages ---------------------------
    { path: '/website-offers', exact: true, name: 'Default', routerKey: "WEBSITE_OFFERS", element: <WebsiteOffersLazy /> },
    { path: '/website-ads', exact: true, name: 'Default', routerKey: "WEBSITE_ADS", element: <WebsiteAdsLazy /> },

    // ------------- Admin Chat Pages ---------------------------
    { path: '/admin-chat', exact: true, name: 'Default', routerKey: "ADMIN_CHAT", element: <AdminChatLazy /> },

    // ------------- Promocodes Pages ---------------------------
    { path: '/promocodes-list', exact: true, name: 'Default', routerKey: "PROMOCODES", element: <PromocodesLazy /> },
    { path: '/add-promocodes', exact: true, name: 'Default', routerKey: "ADD_PROMOCODES", element: <AddPromocodesLazy /> },
    { path: '/edit-Promocodes/:promocodeId', exact: true, name: 'Default', routerKey: "EDIT_PROMOCODES", element: <EditPromocodesLazy /> },
    { path: '/promo-banners', exact: true, name: 'Default', routerKey: "PROMO_BANNER", element: <PromoBannerLazy /> },

    // ------------- Subscription Plans Pages ---------------------------
    { path: '/subscription-plans', exact: true, name: 'Default', routerKey: "SUBSCRIPTION_PLANS", element: <SubscriptionPlansLazy /> },
    { path: '/add-subscription-plans', exact: true, name: 'Default', routerKey: "ADD_SUBSCRIPTION_PLANS", element: <AddSubscriptionPlansLazy /> },
    { path: '/edit-subscription-plans/:subscriptionPlanId', exact: true, name: 'Default', routerKey: "EDIT_SUBSCRIPTION_PLANS", element: <EditSubscriptionPlansLazy /> },

    // ------------- Subscription Benefits Pages ---------------------------
    { path: '/subscription-benefits', exact: true, name: 'Default', routerKey: "SUBSCRIPTION_BENEFITS", element: <SubscriptionBenefitsLazy /> },
    { path: '/add-subscription-benefits', exact: true, name: 'Default', routerKey: "ADD_SUBSCRIPTION_BENEFITS", element: <AddSubscriptionBenefitsLazy /> },
    { path: '/edit-subscription-benefits/:BenefitsId', exact: true, name: 'Default', routerKey: "EDIT_SUBSCRIPTION_BENEFITS", element: <EditSubscriptionBenefitsLazy /> },

    // ------------- Subscription History Pages ---------------------------
    { path: '/subscription-history', exact: true, name: 'Default', routerKey: "SUBSCRIPTION_HISTORY", element: <SubscriptionHistoryLazy /> },

    // ------------- Payment Settings Pages ---------------------------
    { path: '/payment-options', exact: true, name: 'Default', routerKey: "PAYMENT_SETTINGS", element: <PaymentOptionsLazy /> },
    { path: '/payment-gateway', exact: true, name: 'Default', routerKey: "PAYMENT_SETTINGS", element: <PaymentGatewayLazy /> },
    { path: '/payment-gateway', exact: true, name: 'Default', routerKey: "PAYMENT_SETTINGS", element: <PaymentActiveMethodLazy /> },
    { path: '/payment-active-method', exact: true, name: 'Default', routerKey: "PAYMENT_SETTINGS", element: <PaymentActiveMethodLazy /> },

    // ------------- Payment Settings Pages ---------------------------
    { path: '/pick-drop-zone-setting', exact: true, name: 'Default', routerKey: "PICK_DROP_ZONE_SETTING", element: <PickDropZoneLazy /> },
    { path: '/pick-drop-delivery-charge', exact: true, name: 'Default', routerKey: "PICK_DROP_DELIVERY_CHARGE", element: <PickDropDeliveryChargeLazy /> },
    { path: '/pick-drop-service-charge', exact: true, name: 'Default', routerKey: "PICK_DROP_SERVICE_CHARGE", element: <PickDropServiceChargeLazy /> },
    { path: '/pick-drop-platform-fees', exact: true, name: 'Default', routerKey: "PICK_DROP_SERVICE_CHARGE", element: <PickDropPlatformFeesLazy /> },
    { path: '/pick-drop-gst', exact: true, name: 'Default', routerKey: "PICK_DROP_GST", element: <PickDropGSTLazy /> },


    // ------------- Dispute History Pages ---------------------------
    { path: '/pick-drop-order-details/:orderId', exact: true, name: 'Default', routerKey: "USER_DISPUTE", element: <PicDropOrderEditLazy /> },
    { path: '/crisfood-dispute', exact: true, name: 'Default', routerKey: "USER_DISPUTE", element: <UserDisputeyLazy /> },
    { path: '/pickDrop-dispute', exact: true, name: 'Default', routerKey: "USER_DISPUTE", element: <PickDropDisputeyLazy /> },
    { path: '/pick-drop-distance-setting', exact: true, name: 'Default', routerKey: "PICK_DROP_DISTANCE_SETTING", element: <PickDropDistanceSettingLazy /> },


    // Catch-all route for 404
    { path: '*', exact: true, name: 'Default', element: <Error404PageLazy /> },
];



// Function to get routerKey by path
export const getRouterKeyByPath = (path) => {
    const route = routes.find(route => route.path === path);
    return route ? route.routerKey : 'UNKNOWN';
};

export default routes;