// Map the backend order status to ActiveTabKey values
export const mapOrderStatusToTabKey = {
    ORDERED: "Pending Orders",
    RECEIVED: "Pending Orders",
    ASSIGNED: "Assigned Orders",
    PROCESSING: "Processing Orders",
    FOOD_READY: "FoodReady Orders",
    REACHED: "Reached Orders",
    PICKEDUP: "Pickedup Orders",
    ARRIVED: "Arrived Orders",
    COMPLETED: "Completed Orders",
    CANCELLED: "Cancelled Orders",
    SCHEDULED: "Scheduled Orders",
};

// Function to update order status based on notifications
export const updateOrderStatus = (orders, notifications) => {
    console.log("updateOrderStatus", orders, notifications);

    if (notifications.order_type === "shop") {
        console.log("updateOrderStatus if shop", orders, notifications);
        return orders && orders.map((order) => {
            if (order.id === parseInt(notifications.order_id)) {
                // Update card status based on notification type
                if (notifications.out_of_stock === "YES") {
                    order.out_of_stock = notifications.out_of_stock;
                }
                if (notifications.dispute !== "NODISPUTE") {
                    order.dispute = notifications.dispute;
                }
                if (notifications.status) {
                    order.status = notifications.status;
                }
            }
            return order;
        });
    }
    if (notifications.order_type === "pickdrop") {
        return orders && orders.map((order) => {
            if (order.order_reference_number === notifications.order_id) {
                // Update card status based on notification type
                if (notifications.out_of_stock === "YES") {
                    order.out_of_stock = notifications.out_of_stock;
                }
                if (notifications.dispute !== "NODISPUTE") {
                    order.dispute = notifications.dispute;
                }
                if (notifications.status) {
                    order.status = notifications.status;
                }
            }
            return order;
        });
    }
};
