import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../ReduxManager/actions";
import { Col, Row, Form } from "react-bootstrap";
import CrissFoodLogo from "../../assets/images/svg/crisfood_logo_text.svg";
import EyeSvg from "../../assets/images/svg/eye.svg";
import EyeHideSvg from "../../assets/images/svg/eye-hide.svg";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import { useAuth } from "../../context/auth/authContext";
import axiosInstance from "../../axios";
import config from "../../config";
// import jwt from 'jsonwebtoken';
import jwtDecode from "jwt-decode";
import navigation from '../../menu-items';
import routes from "../../Routes/Routes";
import { fetchAndUpdateCityData } from "../../Utils/cityUtils";


const AuthLoginPage = ({ isAuthenticated, isAccessRights, onAdminAuthorize, setCityDataInRedux }) => {
  const navigate = useNavigate();

  // const [token, setToken] = useState(localStorage.getItem('token'));

  const { handleUserData, handleToken } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  // // // If already authenticated, redirect to dashboard
  useEffect(() => {
    if (isAuthenticated) {
      //------------- Finding the first matching route path based on module_key -----------//
      const userAccessRights = isAccessRights;
      const accessRightsMap = new Map();

      // Create a map for access rights
      userAccessRights.forEach((right) => {
        accessRightsMap.set(right.module_key, right);
      });
      // console.log("Access Rights Map:", accessRightsMap);

      // Flatten the navigation structure
      const flattenNavigation = (items) => {
        let flatList = [];
        items.forEach((item) => {
          if (item.isChildren && item.children) {
            flatList.push(...flattenNavigation(item.children));
          } else {
            flatList.push(item);
          }
        });
        return flatList;
      };
      const flattenedItems = flattenNavigation(navigation.superItems);
      // console.log("Flattened Items:", flattenedItems);

      // Filter navigation items based on access rights and preserve original order
      const accessibleItems = flattenedItems.filter((item) => {
        const hasAccess = item.routerKey && accessRightsMap.has(item.routerKey);
        if (item.routerKey && !accessRightsMap.has(item.routerKey)) {
          // console.log(`No access for routerKey: ${item.routerKey}`);
        }
        return hasAccess;
      });
      // console.log("Accessible Items:", accessibleItems);

      // Check if accessible items match any superitem paths
      const accessibleSuperitem = accessibleItems.find((item) => accessRightsMap.has(item.routerKey));
      // console.log("Accessible Superitem:", accessibleSuperitem);

      if (accessibleSuperitem) {
        // console.log("Navigating to Superitem URL:", accessibleSuperitem.url);
        navigate(accessibleSuperitem.url);
      } else {
        // Check if accessible items match any route paths
        const accessibleRoute = routes.find((route) =>
          userAccessRights.some((right) => right.module_key === route.routerKey)
        );
        // console.log("Accessible Route:", accessibleRoute);
        if (userAccessRights.length === 0) {
          // console.log("No access rights found, redirecting to contact-admin");
          navigate('/contact-admin');
        } else if (accessibleRoute) {
          // console.log("Navigating to:", accessibleRoute.path);
          navigate(accessibleRoute.path);
        } else {
          // console.log("No matching route found, redirecting to contact-admin");
          navigate('/contact-admin');
        }
      }
    }
  }, [isAuthenticated, isAccessRights]);

  // ------------------------- ADMIN LOGIN ---------------------------
  const authenticate = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    let emailFlag = false;
    let passwordFlag = false;
    if (!email) {
      validationErrors.email = "Email is required";
    } else {
      if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "Invalid email format";
      } else {
        emailFlag = true;
      }
    }
    if (!password) {
      validationErrors.password = "Password is required";
    } else {
      if (password.length < 6) {
        validationErrors.password =
          "Password must be at least 6 characters long";
      } else {
        // if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)) {
        //   validationErrors.password =
        //     "Password must contain at least one capital letter, one number, and one special character";
        // } else {
        passwordFlag = true;
        // }
      }
    }
    if (Object.keys(validationErrors).length === 0) {
      try {
        const body = {
          username: email,
          password: password,
        };
        const response = await axiosInstance.post(
          `${config.serverUrl}/admin/loginUser`,
          body
        );
        // console.log("response===>", response.data);
        if (response.data.token) {
          // setToken(response.data.token)
          handleToken(response.data.token);
          handleUserData(response.data.token);
          // -------- Token Convert In Date  --------
          const decodedToken = jwtDecode(response.data.token);
          const tokenExpiration = decodedToken.exp;
          const expirationDate = new Date(tokenExpiration * 1000);
          axiosInstance.defaults.headers["Authorization"] =
            "Bearer " + response.data.token;
          axiosInstance
            .get(`/admin/getAdminUserDetailsById/${response.data.userId}`)
            .then((response) => {
              console.log("adminUserData=====>>>", response.data.data);
              // setAdminUserData(response.data.data)
              onAdminAuthorize(
                true,
                response.data.data.id,
                expirationDate,
                response.data.data.name,
                response.data.data.email,
                response.data.data.currency,
                response.data.data.currency_code,
                response.data.data.avatar,
                response.data.data.city,
                response.data.data.access_rights
              );
              setPassword("");
              setEmail("");
              // Call getCity function after successful login
              fetchAndUpdateCityData(setCityDataInRedux);
              //------------- Finding the first matching route path based on module_key -----------//
              const userAccessRights = response.data.data.access_rights;
              const accessRightsMap = new Map();

              // Create a map for access rights
              userAccessRights.forEach((right) => {
                accessRightsMap.set(right.module_key, right);
              });
              // Flatten the navigation structure
              const flattenNavigation = (items) => {
                let flatList = [];
                items.forEach((item) => {
                  if (item.isChildren && item.children) {
                    flatList.push(...flattenNavigation(item.children));
                  } else {
                    flatList.push(item);
                  }
                });
                return flatList;
              };
              const flattenedItems = flattenNavigation(navigation.superItems);
              // Filter navigation items based on access rights and preserve original order
              const accessibleItems = flattenedItems.filter((item) => {
                const hasAccess = item.routerKey && accessRightsMap.has(item.routerKey);
                if (item.routerKey && !accessRightsMap.has(item.routerKey)) {
                  // console.log(`No access for routerKey: ${item.routerKey}`);
                }
                return hasAccess;
              });

              // Check if accessible items match any superitem paths
              const accessibleSuperitem = accessibleItems.find((item) => accessRightsMap.has(item.routerKey));
              if (accessibleSuperitem) {
                navigate(accessibleSuperitem.url);
              } else {
                // Check if accessible items match any route paths
                const accessibleRoute = routes.find((route) =>
                  userAccessRights.some((right) => right.module_key === route.routerKey)
                );
                if (userAccessRights.length === 0) {
                  navigate('/contact-admin');
                } else if (accessibleRoute) {
                  navigate(accessibleRoute.path);
                } else {
                  navigate('/contact-admin');
                }
              }

            });
        }
      } catch (error) {
        console.log("err catch", error);
        setEmail("");
        setPassword("");
        Swal.fire({
          icon: "error",
          title: "Unauthorized User",
          text: "Please Enter valid Email and Password",
        });
      }
    }
    setErrors(validationErrors);
  };


  // -------------- set city in redux -------------------
  // const getCity = async () => {
  //   try {
  //     const response = await axiosInstance.get(`/admin/getActiveZones`);
  //     console.log("reduxcity====>", response.data.data);
  //     const formattedCityOptions = response.data.data && response.data.data.map((cityvalue) => {
  //       return { value: cityvalue.city, label: cityvalue.city.toLowerCase(), id: cityvalue.id };
  //     });
  //     // Add the "All" option
  //     const allOption = { label: "All", value: "ALL", id: "ALL" };
  //     const cityNameOptionsAll = [allOption, ...formattedCityOptions];
  //     // Dispatch to Redux in the required format
  //     setCityDataInRedux(cityNameOptionsAll);
  //   } catch (error) {
  //     console.log("e=>", error);
  //     const errorMsg = error?.message || 'An error occurred';
  //     Swal.fire({
  //       title: errorMsg,
  //       icon: "error",
  //       text: error.data,
  //     });
  //   }
  // };


  return (
    <div>
      <div className="admin-login-page">
        <div className="main-login-card-container">
          <div className="login-card">
            <div className="form-card">
              <div className="admin-name-logo">
                <LazyLoadImage src={CrissFoodLogo} alt="error" />
              </div>
              <Form onSubmit={authenticate}>
                <div className="login-content">
                  <div className="login-account-text">
                    Login to your account
                  </div>
                  <Row>
                    <Col md={12} className="mb-4">
                      <Form.Group
                        controlId="email"
                        className="position-relative"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          placeholder="Enter Your Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className={errors?.email && "validation-border"}
                        />
                        {errors.email && (
                          <span className="validationText">{errors.email}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="password">
                        <Form.Label className="d-flex justify-content-between">
                          <span>Password</span>
                          {/* <span className="forgot-password"><a href="#">Forgot ?</a></span> */}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            placeholder="Enter Your Password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={errors?.password && "validation-border"}
                          />
                          <div
                            className="eyeimage-logo"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <LazyLoadImage
                                src={EyeHideSvg}
                                alt="Hide"
                                width={25}
                              />
                            ) : (
                              <LazyLoadImage src={EyeSvg} alt="Show" />
                            )}
                          </div>
                          {errors.password && (
                            <span className="validationText">
                              {errors.password}
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="login-btn">
                    <button type="submit">Login now</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthorised,
    isAccessRights: state.accessRights,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdminAuthorize: (
      authstate,
      userId,
      authtoken,
      adminname,
      adminemail,
      currencylogo,
      currencycode,
      adminimage,
      admincity,
      accessrights
    ) =>
      dispatch({
        type: actionTypes.ADMIN_AUTHORIZED, payload: {
          authState: authstate,
          userId: userId,
          authToken: authtoken,
          adminName: adminname,
          adminEmail: adminemail,
          currencyLogo: currencylogo,
          currencyCode: currencycode,
          adminImage: adminimage,
          adminCity: admincity,
          accessRights: accessrights,
        },
      }),
    setCityDataInRedux: (reduxcityname) => dispatch({ type: actionTypes.REDUX_CITY_DATA, payload: { reduxCityName: reduxcityname } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLoginPage);
