import { Grid } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import axiosInstance from "../../../axios";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imageOnErrorHandler } from "../../../Utils/imageErrorHandler";
import { checkImageUrl } from "../../../Utils/checkImageUrl";
import config from "../../../config";
import NoMenuImageFound from '../../../assets/images/no-menu.png';
import NonVegIcon from "../../../assets/images/order-non-veg-icon.png";
import VegIcon from "../../../assets/images/order-veg-icon.png";
import { convertTo12Hour, formatDate } from "../../../Utils/DateTimeUtils";

const DeletedItemsFromCart = ({
	orderId,
	reduxCurrencyLogo,
	showDeletedItems,
	sourcePage
}) => {
	// ------------------- useStates -----------------
	const [DeletedItemsFromCart, setDeletedItemsFromCart] = useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const getDeletedItemsFromCartByOrderId = async () => {
		setIsLoading(true);
		try {
			const response = await axiosInstance.get(`/adminOrders/getDeletedItemsFromCartByOrderId/${orderId}`);
			// console.log("deleted_product", response.data.data);

			setDeletedItemsFromCart(response.data.data);
			setIsLoading(false);
		} catch (error) {
			console.log("e=>", error);
			const errorMsg = error?.message || 'An error occurred';
			Swal.fire({
				title: errorMsg,
				icon: "error",
				text: error.data,
			});
			setIsLoading(false);
		}
	};

	// ---------------- useEffect ---------------
	useEffect(() => {
		if (orderId) {
			getDeletedItemsFromCartByOrderId();
		}
	}, [orderId, showDeletedItems]);

	// Determine grid item sizes based on sourcePage
	const gridSizes = {
		editPage: {
			md: 5.1, sm: 4.5, xs: 4.5,
			mdPrice: 1.8, smPrice: 1.3, xsPrice: 1.3,
			mdQty: 1.2, smQty: 1, xsQty: 1,
			mdItemPrice: 1.2, smItemPrice: 1.5, xsItemPrice: 1.5,
			mdAddonPrice: 1.5, smAddonPrice: 1.8, xsAddonPrice: 1.8,
			mdTotalPrice: 1.2, smTotalPrice: 1.8, xsTotalPrice: 1.8
		},
		detailsPage: {
			md: 6, sm: 4.5, xs: 4.5,
			mdPrice: 2, smPrice: 1.3, xsPrice: 1.3,
			mdQty: 1, smQty: 1, xsQty: 1,
			mdItemPrice: 1, smItemPrice: 1.5, xsItemPrice: 1.5,
			mdAddonPrice: 1, smAddonPrice: 1.8, xsAddonPrice: 1.8,
			mdTotalPrice: 1, smTotalPrice: 1.8, xsTotalPrice: 1.8
		}
	};

	const sizes = gridSizes[sourcePage] || gridSizes.editPage;

	return (
		<>
			{
				DeletedItemsFromCart && DeletedItemsFromCart.map((value, index) => {
					const deletedAt = value.deleted_at;
					const [date, time] = deletedAt ? deletedAt.split(" ") : ["", ""];
					return (
						<div key={index} className={`${sourcePage === 'detailsPage' && 'px-2'}`}>
							<Grid container className="position-relative disabled-order-product-row">
								<Grid item md={sizes.md} sm={sizes.sm} xs={sizes.xs}>
									<div className="order-menu-card-img-section clearfix">
										<div className='order-menu-image'>
											<LazyLoadImage
												src={
													value.product_images && value.product_images
														? checkImageUrl(value.product_images) === "Full URL"
															? value.product_images
															: `${config.serverImageUrl}${value.product_images}`
														: NoMenuImageFound
												}
												onError={imageOnErrorHandler}
											/>
										</div>
										<div>
											<div className="category-product-menu-title menu-title-name">
												<span>{value.category}</span>
												{value.subcategory && <span>, {value.subcategory}</span>}
											</div>
											<div className="menu-title-name old-product-opstions ps-1">
												{value.name ? value.name : ""}
												<div className="order-price text-start">{value.description}</div>
												<div className="order-price text-start">{formatDate(date)} {convertTo12Hour(time)}</div>
												<span className="product-out-of-stock-status">Deleted Item</span>
												{value?.discount !== 0 && value.option_name === null && (<span className="product-percent-type">
													{value?.discount_type !== "percentage" && reduxCurrencyLogo}{value?.discount}{value?.discount_type === "percentage" && '%'} OFF
												</span>)}
												<span className="product-food-type-icon">
													{
														value.food_type && value.food_type === "non-veg" ?
															<LazyLoadImage src={NonVegIcon} /> :
															<LazyLoadImage src={VegIcon} />
													}
												</span>
											</div>
										</div>
									</div>
								</Grid>
								<Grid item md={sizes.mdPrice} sm={sizes.smPrice} xs={sizes.xsPrice}>
									<div className="dsp-dish-price">
										<div className={`${value.out_of_stock === "YES" && "out_of_stock_yes_oragen_text"}`}>{value.out_of_stock}</div>
									</div>
								</Grid>
								<Grid item md={sizes.mdQty} sm={sizes.smQty} xs={sizes.xsQty}>
									<div className="dsp-dish-price">
										{value.quantity}
									</div>
								</Grid>
								<Grid item md={sizes.mdItemPrice} sm={sizes.smItemPrice} xs={sizes.xsItemPrice}>
									<div className={`dsp-dish-price ${value.discount !== 0 && value.option_name === null && "product-text-line-through"}`}>
										{reduxCurrencyLogo}{value.item_price}
									</div>
									{value.discount !== 0 && value.option_name === null &&
										(<div className="dsp-dish-price">{reduxCurrencyLogo}{value?.item_original_price}</div>)
									}
								</Grid>
								<Grid item md={sizes.mdAddonPrice} sm={sizes.smAddonPrice} xs={sizes.xsAddonPrice}>
									<div className="dsp-dish-price">
										{reduxCurrencyLogo}{value.addon_price}
									</div>
								</Grid>
								<Grid item md={sizes.mdTotalPrice} sm={sizes.smTotalPrice} xs={sizes.xsTotalPrice}>
									<div className="dsp-dish-price">
										{reduxCurrencyLogo}
										{
											(value?.discount !== 0 && value?.option_name === null)
												? (value?.quantity * value?.item_original_price)
												: value?.total_price
										}
									</div>
								</Grid>
							</Grid>
							<hr />
						</div>
					);
				})
			}
		</>
	);
}

export default memo(DeletedItemsFromCart);
