import React, { memo, useEffect, useRef, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import axiosInstance from "../../../axios";
import Swal from "sweetalert2";
import OrderStatusCardComponent from "../../../Components/DispacherComponents/OrderStatusCard";
import { connect } from "react-redux";
import * as actionTypes from '../../../ReduxManager/actions'
import OrderDetailsModal from "../../DispacherComponents/OrderDetailsModal";
import DisputeModal from "../../DispacherComponents/DisputeModal";
import AssingDeliveryBoyCard from "../../DispacherComponents/AssingDeliveryBoyCard";
import OrderLocationGoogleMap from "../../../Components/DispacherComponents/OrderLocationGoogleMap";




const GlobalOrderSearch = ({ reduxSideBarOpen, onChangeActiveTab }) => {

	const [GlobalOrderSearchData, setGlobalOrderSearchData] = React.useState(false);
	const [OrdersUpdated, setOrdersUpdated] = React.useState(null);
	const [OrdersCancellation, setOrdersCancellation] = React.useState(null);
	const [NavigateToEditPage, setNavigateToEditPage] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [GlobalOrderSearchShow, setGlobalOrderSearchShow] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const handleClose = () => setGlobalOrderSearchShow(false);
	const handleGlobalOrderSearchShow = () => setGlobalOrderSearchShow(true);

	const handleInputChange = (event) => {
		setSearchValue(event.target.value.replace(/[^0-9]/g, ""));
	};


	// ------------------ Delivery Boy  states--------------------
	const [OrdersMediaCardAssingStatus, setOrdersMediaCardAssingStatus] = React.useState(null);
	const [OrdersAssingToTransporter, setOrdersAssingToTransporter] = React.useState(null);
	const [showAssingDeliveryBoy, setShowAssingDeliveryBoy] = useState(false);
	const [DeliveryBoyList, setDeliveryBoyList] = useState([]);

	// ----------------------- Show Map location -------------------
	const [showMapLocation, setShowMapLocation] = useState(false);
	const [deliveryBoysLocation, setDeliveryBoysLocation] = useState([]);
	const [restaurantsLocation, setRestaurantsLocation] = useState([]);

	/*-------------------------------------------------------------
	 Assing Order  Modal States
---------------------------------------------------------------*/
	const [showAssingOrderId, setShowAssingOrderId] = useState("");
	const [showAssingOrder, setShowAssingOrder] = useState(false);
	const handleCloseAssingOrder = () => setShowAssingOrder(false);
	const handleShowAssingOrder = (city, id) => {
		// console.log("=======>", city, id);
		setShowAssingOrder(true);
		getDeliveryBoy(city)
		setShowAssingOrderId(id)
	}

	/*--------------------------------------------------
												DeliveryBoy Dropdown
		--------------------------------------------------*/
	const getDeliveryBoy = async (city) => {
		try {
			const response = await axiosInstance.get(`/adminOrders/getOnlineTransportersByCity/city/${city}`);
			// console.log("DeliveryBoy=>", response.data);
			setDeliveryBoyList(response.data.data)
			setShowAssingDeliveryBoy(true)
			setShowMapLocation(false)
		} catch (error) {
			console.log("e=>", error);
			const errorMsg = error?.message || 'An error occurred';
			Swal.fire({
				title: errorMsg,
				icon: "error",
				text: error.data,
			});
		}
	}

	// ------------ select Assing Delivery Boy function
	const handleAssignButtonClick = async (city, id) => {
		await getDeliveryBoy(city); // Call the getDeliveryBoy function when the button is clicked
		setShowAssingOrderId(id)
	}

	// Use useEffect to handle OrdersUpdated change
	React.useEffect(() => {
		if (OrdersCancellation !== null) {
			onChangeActiveTab("Cancelled Orders");
			setGlobalOrderSearchShow(false);
		}
	}, [OrdersCancellation]);

	// Use useEffect to handle OrdersUpdated change
	React.useEffect(() => {
		if (NavigateToEditPage !== null) {
			setGlobalOrderSearchShow(false);
		}
	}, [NavigateToEditPage]);

	// Use useEffect to handle OrdersUpdated change
	React.useEffect(() => {
		if (OrdersAssingToTransporter !== null) {
			onChangeActiveTab("Assigned Orders");
			setShowAssingDeliveryBoy(false)
			setDeliveryBoyList([])
			setGlobalOrderSearchShow(false);
		}
	}, [OrdersAssingToTransporter]);



	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent form from refreshing the page
		if (searchValue.trim()) {
			handleGlobalOrderSearchShow(); // Open modal when input is submitted and not empty
			setIsLoading(true);
			try {
				const response = await axiosInstance.get(`/adminOrders/getOrderByGlobalIdSearch/id/${searchValue}`);
				// console.log("GlobalOrderSearchData=====>>>", response.data.data);
				setGlobalOrderSearchData(response.data.data);
				setIsLoading(false);
			} catch (error) {
				console.log("e=>", error);
				const errorMsg = error?.message || 'An error occurred';
				Swal.fire({
					title: errorMsg,
					icon: "error",
					text: error.data,
				});
				setIsLoading(false)
			}
		}
	};

	// ------------------- Find Screen width State ---------------------------
	const [sectionWidth, setSectionWidth] = useState(0);
	const sectionRef = useRef(null);
	const initialWidthSet = useRef(false); // useRef for initial width setting



	// ------------------- Find Screen width ---------------------------
	useEffect(() => {
		const handleResize = () => {
			if (sectionRef.current) {
				const width = sectionRef.current.offsetWidth;
				setSectionWidth(width);
			}
		};
		const updateWidth = () => {
			if (sectionRef.current) {
				const width = sectionRef.current.offsetWidth;
				setSectionWidth(width);
			}
		};
		window.addEventListener('resize', handleResize);
		// Set initial width once on component mount
		if (!initialWidthSet.current) {
			updateWidth();
			initialWidthSet.current = true;
		}
		// Update width when reduxSideBarOpen changes
		if (reduxSideBarOpen !== undefined) {
			updateWidth();
		}
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [reduxSideBarOpen]); // Add reduxSideBarOpen as a dependency

	// ------------------------- Get Google Map Location --------------------
	const openDirections = (shopAddress, userAddress) => {
		if (shopAddress && userAddress) {
			const origin = encodeURIComponent(shopAddress); // Replace with actual origin address or coordinates
			const destination = encodeURIComponent(userAddress); // Replace with actual destination address or coordinates
			const mode = 'motorcycle'; // 'b' specifies biking mode
			const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving&dirflg=${mode}`;
			window.open(url, '_blank');
		}
	};



	/*-------------------------------------------------------------
							 Order Details Modal States
	---------------------------------------------------------------*/
	const [selectedOrderId, setSelectedOrderId] = useState([]);
	const [showOrderDetails, setShowOrderDetails] = useState(false);
	const handleCloseOrderDetails = () => setShowOrderDetails(false);
	const handleShowOrderDetails = (id) => {
		setShowOrderDetails(true);
		setSelectedOrderId(id)
	}


	/*-------------------------------------------------------------
	Dispute Modal States
---------------------------------------------------------------*/
	// const [selectedOrderId, setSelectedOrderId] = useState([]);
	const [showDispute, setShowDispute] = useState(false);
	const handleCloseDispute = () => setShowDispute(false);
	const handleShowDispute = (id) => {
		setShowDispute(true);
		setSelectedOrderId(id)
	}



	// ----------------------- Show Map location -------------------
	const handleShowMap = async (shop_lat, shop_long, shop_name, city, id) => {
		setShowAssingOrderId(id)
		setRestaurantsLocation([
			{ lat: shop_lat, lng: shop_long, shopName: shop_name },
		])
		try {
			const response = await axiosInstance.get(`/adminOrders/getOnlineTransportersByCity/city/${city}`);
			// console.log("DeliveryBoy=>", response.data);
			const deliveryBoyData = response.data.data || []
			setDeliveryBoysLocation(deliveryBoyData && deliveryBoyData.map(({ latitude, longitude, name }) => ({ lat: latitude, lng: longitude, name })));
		} catch (error) {
			console.log("Error handling map:", error);
			const errorMsg = error?.message || 'An error occurred';
			Swal.fire({
				title: errorMsg,
				icon: "error",
				text: error.data,
			});
		}
		setShowMapLocation(true);
		setShowAssingDeliveryBoy(false)
	}



	/*-------------------------------------------------------------
	 Location Order  Modal States
---------------------------------------------------------------*/
	const [showLocationOrder, setShowLocationOrder] = useState(false);
	const handleCloseLocationOrder = () => setShowLocationOrder(false);
	const handleShowLocationOrder = async (shop_lat, shop_long, shop_name, city, id) => {
		setShowAssingOrderId(id)
		setShowLocationOrder(true);
		setRestaurantsLocation([
			{ lat: shop_lat, lng: shop_long, shopName: shop_name },
		])
		try {
			const response = await axiosInstance.get(`/adminOrders/getOnlineTransportersByCity/city/${city}`);
			console.log("DeliveryBoy=>", response.data);
			const deliveryBoyData = response.data.data || []
			setDeliveryBoysLocation(deliveryBoyData && deliveryBoyData.map(({ latitude, longitude, name }) => ({ lat: latitude, lng: longitude, name })));
		} catch (error) {
			console.log("Error handling map:", error);
			const errorMsg = error?.message || 'An error occurred';
			Swal.fire({
				title: errorMsg,
				icon: "error",
				text: error.data,
			});
		}
	}

	// Update state in the parent component when the child passes a value
	const handleMediaCardAssingStatusChange = (status) => {
		setOrdersMediaCardAssingStatus(status);
	};



	return (

		<div>
			<form onSubmit={handleSubmit}>
				<TextField
					id="standard-basic"
					variant="standard"
					placeholder="Search..."
					value={searchValue}
					onChange={handleInputChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</form>


			{/* Bootstrap Modal */}
			<Modal
				show={GlobalOrderSearchShow}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				dialogClassName="order-custom-modal"
			// centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Global Order Search</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={7}>
							{GlobalOrderSearchData &&
								<OrderStatusCardComponent
									OrdersDetailsData={GlobalOrderSearchData}
									sectionWidth={sectionWidth}
									handleShowAssingOrder={handleShowAssingOrder}
									openDirections={openDirections}
									handleAssignButtonClick={handleAssignButtonClick}
									handleShowOrderDetails={handleShowOrderDetails}
									handleShowDispute={handleShowDispute}
									handleShowMap={handleShowMap}
									setOrdersUpdated={setOrdersUpdated}
									setOrdersCancellation={setOrdersCancellation}
									setNavigateToEditPage={setNavigateToEditPage}
									handleShowLocationOrder={handleShowLocationOrder}
									onMediaCardAssingStatusChange={handleMediaCardAssingStatusChange}
									GlobalOrderSearchModalOpen={true}
									GlobalOrderSearch={true}
								/>}
						</Col>
						<Col md={5} className="assing-delivery-boy-map-location">
							{
								showAssingDeliveryBoy && DeliveryBoyList &&
								<div>
									<div className="d-flex justify-content-between">
										<h6><span>Assing OrderId:- </span><Badge bg="warning" text="dark">#{showAssingOrderId && showAssingOrderId}</Badge></h6>
										<h6><span>DeliveryBoy Count:- </span><Badge bg="warning" text="dark">{DeliveryBoyList.length && DeliveryBoyList.length}</Badge></h6>
									</div>
									<AssingDeliveryBoyCard
										showAssingOrderId={showAssingOrderId}
										DeliveryBoyList={DeliveryBoyList}
										handleCloseAssingOrder={handleCloseAssingOrder}
										setOrdersUpdated={setOrdersUpdated}
										GlobalOrderSearchModalOpen={true}
										setOrdersAssingToTransporter={setOrdersAssingToTransporter}
									/>
								</div>
							}

							{showMapLocation && (restaurantsLocation && deliveryBoysLocation) && (
								<div>
									<div className="d-flex justify-content-between">
										<h6><span>Assing OrderId:- </span><Badge bg="warning" text="dark">#{showAssingOrderId && showAssingOrderId}</Badge></h6>
										<h6><span>DeliveryBoy Count:- </span><Badge bg="warning" text="dark">{DeliveryBoyList.length && DeliveryBoyList.length}</Badge></h6>
									</div>
									<div className="custom-card resturatn-small-card">
										<div className="resturatn-small-card-title">
											Restaurant & Delivery Boy Location
										</div>
										<OrderLocationGoogleMap
											restaurantCoordinates={restaurantsLocation}
											deliveryBoyCoordinates={deliveryBoysLocation}
										/>
									</div>
								</div>
							)}
						</Col>

					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button className="modal-close-button" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>



			{showOrderDetails &&
				<OrderDetailsModal
					showOrderDetails={showOrderDetails}
					handleCloseOrderDetails={handleCloseOrderDetails}
					selectedOrderId={selectedOrderId}
				/>
			}

			{/* ----------------- Dispute Modal-------------------------- */}

			{showDispute &&
				<DisputeModal
					showDispute={showDispute}
					handleCloseDispute={handleCloseDispute}
					selectedOrderId={selectedOrderId}
				/>
			}


			{/* ----------------- mobile view assing delivery boy card -------------------------- */}
			<Modal
				show={showAssingOrder}
				onHide={handleCloseAssingOrder}
				backdrop="static"
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Assing Delivery Boy</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				</Modal.Body>
				<Row className="order-details-modal">
					<Col md={12}>
						{
							showAssingDeliveryBoy && DeliveryBoyList &&
							<div>
								<div className="">
									<h6><span>Assing OrderId:- </span><Badge bg="warning" text="dark">#{showAssingOrderId && showAssingOrderId}</Badge></h6>
									<h6><span>DeliveryBoy Count:- </span><Badge bg="warning" text="dark">{DeliveryBoyList.length && DeliveryBoyList.length}</Badge></h6>
								</div>
								<AssingDeliveryBoyCard
									showAssingOrderId={showAssingOrderId}
									DeliveryBoyList={DeliveryBoyList}
									handleCloseAssingOrder={handleCloseAssingOrder}
									setOrdersUpdated={setOrdersUpdated}
									GlobalOrderSearchModalOpen={true}
									setOrdersAssingToTransporter={setOrdersAssingToTransporter}
								/>
							</div>
						}
					</Col>
				</Row>
				<Modal.Footer>
					<Button className="modal-close-button" onClick={handleCloseAssingOrder}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>


			{/* ----------------- mobile view Location delivery boy card -------------------------- */}
			<Modal
				show={showLocationOrder}
				onHide={handleCloseLocationOrder}
				backdrop="static"
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Location
						<Badge bg="warning" className="ms-2" text="dark">#{showAssingOrderId}</Badge>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				</Modal.Body>
				<Row className="order-details-modal">
					<Col md={12}>
						{restaurantsLocation && deliveryBoysLocation && (
							<div>
								<div className="">
									<h6><span>Assing OrderId:- </span><Badge bg="warning" text="dark">#{showAssingOrderId && showAssingOrderId}</Badge></h6>
									<h6><span>DeliveryBoy Count:- </span><Badge bg="warning" text="dark">{DeliveryBoyList.length && DeliveryBoyList.length}</Badge></h6>
								</div>
								<div className="custom-card resturatn-small-card">
									<div className="resturatn-small-card-title">
										Restaurant & Delivery Boy Location
									</div>
									<OrderLocationGoogleMap
										restaurantCoordinates={restaurantsLocation}
										deliveryBoyCoordinates={deliveryBoysLocation}
									/>
								</div>
							</div>
						)}
					</Col>
				</Row>
				<Modal.Footer>
					<Button className="modal-close-button" onClick={handleCloseLocationOrder}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>

	)
}



const mapStateToProps = state => {
	return {
		reduxCityName: state.orderCityName,
		reduxRestaurantName: state.ordersRestaurantName,
		reduxDeliveryBoyId: state.ordersDeliveryBoyId,
		reduxSideBarOpen: state.sideBarOpen

	}
};

const mapDispatchToProps = dispatch => {
	return {
		onChangeActiveTab: (tabkey) => dispatch({ type: actionTypes.ORDERS_TAB_KEY_SET, payload: tabkey }),
		adminOrderCityName: (ordercityname) => dispatch({ type: actionTypes.ORDERED_CITY_NAME, payload: { orderCityName: ordercityname } }),
		adminOrderRestaurantName: (ordersrestaurantname) => dispatch({ type: actionTypes.ORDERED_RESTAURANT_NAME, payload: { ordersRestaurantName: ordersrestaurantname } }),
		removeRestaurant: (ordersrestaurantname) => dispatch({ type: actionTypes.ORDERED_RESTAURANT_NAME, payload: { ordersRestaurantName: ordersrestaurantname } }),
		adminOrderDeliveryBoyName: (ordersdeliveryboyid) => dispatch({ type: actionTypes.ORDERED_DELIVERY_BOY_NAME, payload: { ordersDeliveryBoyId: ordersdeliveryboyid } }),
		reduxArrivedOrdersCount: (ArrivedOrderscount) => dispatch({ type: actionTypes.ARRIVED_ORDERS_COUNT, payload: { reduxArrivedOrdersCount: ArrivedOrderscount } })


	}
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(GlobalOrderSearch));

