import React, { memo } from "react";
import { convertTo12Hour, formatDate } from "../../../Utils/DateTimeUtils";
import { Button, Col, Row } from "react-bootstrap";
import axiosInstance from "../../../axios";
import Swal from "sweetalert2";
import PopMesseges from "../../../Utils/popMesseges.json"
import { connect } from "react-redux";




const AssingDeliveryBoyCard = React.memo(({ reduxCityName, reduxRestaurantName, setOrdersAssingToTransporter, GlobalOrderSearchModalOpen, setDeliveryBoyList,
	setOrdersUpdated, showAssingOrderId, DeliveryBoyList, handleCloseAssingOrder, setShowAssingOrderId }) => {


	const updateTransporter = async (order_id, deliveryBoyId) => {
		axiosInstance.put(`/adminOrders/updateOrderStatusToAssign/order_id/${order_id}/transporter_id/${deliveryBoyId}`)
			.then(function (response) {
				if (response.data.status === 1) {
					// console.log("Resppp>>>>>>>>>>", response.data.data);
					Swal.fire({
						icon: 'success',
						title: PopMesseges.ORDERS_LIST.ASSING_ORDER_TRANSPORTER,
						showConfirmButton: false,
						timer: 1500
					})
					setOrdersUpdated(new Date())
					setDeliveryBoyList([])
					setShowAssingOrderId("")
					if (GlobalOrderSearchModalOpen) {
						setOrdersAssingToTransporter(true)
					}
					handleCloseAssingOrder()
				} else {
					Swal.fire(response.data.message.message, '', 'warning');
				}
			})
			.catch(function (error) {
				console.log(error);
				Swal.fire(error.response.data.error || error.response.data.message, '', 'warning');
			});

	};


	return (
		<div>
			{
				DeliveryBoyList && DeliveryBoyList.map((value, index) => {
					const timestamp = value.start_time && value.start_time;
					const [datePart, timePart] = timestamp.split(' ');
					const durationString = value.duration && value.duration;
					const [hours, minutes, seconds] = durationString.split(':');
					return (
						<div className='custom-card custom-dispacher-delivery-card mb-3' key={index}>
							<div className='custom-card-body'>
								<Row>
									<Col md={6} sm={6} xs={6}>
										<div className="dispacher-profile-section">
											<div className="dispacher-delivery-name">{value.name ? value.name : ""}</div>
											<div className="dispacher-delivery-name">{value.phone ? value.phone : ""}</div>
											{
												value.orders_in_queue && value.orders_in_queue.map((val, index) => {
													return (
														<div key={index}>
															<div className="dispacher-delivery-name">{val.shop_name ? val.shop_name : ""} : {val.order_count ? val.order_count : ""}</div>
														</div>
													)
												})
											}
											<div className="order-asing-btn-section mt-3">
												<Button className="dsp-assing-btn" onClick={() => { updateTransporter(showAssingOrderId, value.id,) }} >Assign</Button>
											</div>
										</div>
									</Col>
									<Col md={6} sm={6} xs={6}>
										<div className="dispacher-delivery-name">Completed ({value.flip_total_order + value.shop_orders})</div>
										<div className="dispacher-delivery-name mb-3">Shop Order : {value.shop_orders ? value.shop_orders : "0"}</div>
										<div className="dispacher-delivery-name mb-3">Flip Order : {value.flip_total_order ? value.flip_total_order : "0"}</div>
										<div className="dispacher-delivery-name">Shift Start Time ({`${hours}h ${minutes}m`})</div>
										<div className="dispacher-delivery-name">{timePart ? convertTo12Hour(timePart) : ''} {datePart ? formatDate(datePart) : ""}</div>
									</Col>
								</Row>
							</div>
						</div>
					)
				})
			}
		</div>

	)
})

const mapStateToProps = state => {
	return {

		reduxCityName: state.orderCityName,
		reduxRestaurantName: state.ordersRestaurantName,


	}
};


export default connect(mapStateToProps, null)(memo(AssingDeliveryBoyCard));
