// import React, { useState } from "react";
// import { DataGrid, GridToolbar, GridPagination, } from '@mui/x-data-grid';
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import EditButton from '../../../assets/images/svg/icon_edit.svg'
// import axiosInstance from "../../../axios";
// import Swal from "sweetalert2";
// import Pagination from '../../GlobalComponents/DataTablePagination';
// import { useRefresh } from "../../GlobalComponents/DataTableRefresh";
// import PopMesseges from "../../../Utils/popMesseges.json"
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import csvFileName from '../../../Utils/csvFileName.json'
// import generateExportFileName from "../../../Utils/GenerateExportFileName";
// import CustomNoRowsOverlay from "../../GlobalComponents/CustomNoRowsOverlay";
// import CustomSwitchButton from "../../GlobalComponents/CustomSwitchButton";
// import { formatVisibilityStatus } from "../../../Utils/DataTableValueGetters/formatVisibilityStatus";
// import { formatDate } from "../../../Utils/DateTimeUtils";






// const DisputeModalDataTable = ({ DisputeTable, setDisputeTableUpdated, isLoading }) => {

// 	// ------------- custome page refresh -------------------------
// 	const { refreshTrigger } = useRefresh();

// 	const [columnVisibilityModel, setColumnVisibilityModel] = useState({
// 		id: true,
// 		city: true,
// 		no_of_users: true,
// 		no_of_restaurants: true,
// 		no_of_delivery_men: true
// 	});

// 	const columns = [
// 		{
// 			headerName: "Sr.No",
// 			field: "serialNumber",
// 		},
// 		{
// 			headerName: "Id",
// 			field: "id",
// 		},
// 		{
// 			headerName: "Description",
// 			field: "description",
// 			flex: 1,
// 			minWidth: 180,
// 			renderCell: (params) => (
// 				<pre className="datatable-userlist-adresses">
// 					{params.row.description}
// 				</pre>
// 			),
// 		},
// 		{
// 			headerName: 'Created By	',
// 			field: 'created_by',
// 			flex: 1, minWidth: 150
// 		},
// 		{
// 			headerName: 'Type',
// 			field: 'type',
// 			flex: 1, minWidth: 150
// 		},
// 		{
// 			headerName: 'Status',
// 			field: 'status',
// 			flex: 1, minWidth: 150
// 		},
// 		{
// 			headerName: 'Created On	',
// 			field: 'created_at',
// 			flex: 1, minWidth: 150,
// 			renderCell: (params) => (
// 				params.row.created_at === null ? "" :
// 					<div className="datatable-userlist-adresses">
// 						<div>{formatDate(params.row.created_at)}</div>
// 					</div>
// 			),
// 		},
// 		{
// 			field: 'Action',
// 			headerName: 'Action',
// 			disableExport: true,
// 			width: 100,
// 			renderCell: (params) => (
// 				<div className={params.row.status === "CREATED" ? "admin-active-status custom-cursor-pointer" : "admin-inactive-status"}
// 					onClick={() => {
// 						if (params.row.status === "CREATED") {
// 							DisputeActiveStatus(params.row.id);
// 						}
// 					}}
// 				>
// 					{params.row.status === "CREATED" ? 'Resolve' : 'Resolved'}
// 				</div>
// 			),

// 		},
// 	]

// 	// ----------------------------- change Dispute active status ------------------------
// 	const DisputeActiveStatus = (Id) => {
// 		console.log(Id);
// 		axiosInstance.put(`/adminOrders/UpdateDisputeStatusById/dispute_id/${Id}`)
// 			.then(function (response) {
// 				setDisputeTableUpdated(response)
// 				Swal.fire({
// 					icon: 'success',
// 					title: PopMesseges.DISPACHER_DISPUTES_STATUS_UPDATED,
// 					showConfirmButton: false,
// 					timer: 1000
// 				})
// 			})
// 	}

// 	// --------------- CustomPagination -------------------
// 	function CustomPagination(props) {
// 		return <GridPagination ActionsComponent={Pagination} {...props} />;
// 	}

// 	// --------------- custome export file name -------------------
// 	const fileNamePrefix = csvFileName.DISPACHER_DISPUTES_TABLE_EXPORTED_DATA


// 	// ADD_SRNO_TAG_NAVIGATE
// 	const rows = DisputeTable && DisputeTable.map((row, index) => ({
// 		...row,
// 		serialNumber: index + 1,
// 	}));

// 	return (
// 		<>
// 			<div style={{ height: 'auto', width: '100%' }} className="dispute-modal-data-table">
// 				<DataGrid
// 					autoHeight={true}
// 					rows={rows}
// 					columns={columns}
// 					loading={isLoading}
// 					disableRowSelectionOnClick
// 					components={{
// 						// Toolbar: GridToolbar,
// 						// Pagination: CustomPagination,
// 						// noRowsOverlay: CustomNoRowsOverlay,
// 					}}
// 					// pageSize={100}
// 					// slotProps={{
// 					// 	toolbar: {
// 					// 		showQuickFilter: true,
// 					// 		csvOptions: {
// 					// 			fileName: generateExportFileName(fileNamePrefix),
// 					// 			utf8WithBom: true,
// 					// 			delimiter: ',',
// 					// 		},
// 					// 		printOptions: {
// 					// 			fileName: generateExportFileName(fileNamePrefix),
// 					// 			hideToolbar: true,
// 					// 			hideFooter: true,
// 					// 		},
// 					// 	},
// 					// }}
// 					// pagination
// 					// initialState={{
// 					// 	...DisputeTable.initialState,
// 					// 	pagination: { paginationModel: { pageSize: 25 } },
// 					// }}
// 					key={refreshTrigger}
// 				// columnVisibilityModel={columnVisibilityModel}
// 				// onColumnVisibilityModelChange={(newModel) => {
// 				// 	console.log("on change column model", newModel);
// 				// 	setColumnVisibilityModel(newModel)
// 				// }
// 				// }
// 				/>
// 			</div>

// 		</>
// 	)
// }

// export default DisputeModalDataTable



import React from 'react';
import { Table, Spinner } from 'react-bootstrap';
import axiosInstance from "../../../axios";
import Swal from "sweetalert2";
import PopMesseges from "../../../Utils/popMesseges.json"
import { formatDate } from "../../../Utils/DateTimeUtils";

const DisputeModalDataTable = ({ DisputeTable, setDisputeTableUpdated, isLoading }) => {

	const DisputeActiveStatus = (id) => {
		// console.log(Id);
		Swal.fire({
			title: PopMesseges.DISPACHER_DISPUTES_STATUS_UPDATED.TITLE,
			text: PopMesseges.DISPACHER_DISPUTES_STATUS_UPDATED.TEXT,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, resolved it!'
		}).then((result) => {
			if (result.isConfirmed) {
				disputeActive(id)

			}
		})
	}
	const disputeActive = (id) => {
		axiosInstance.put(`/adminOrders/UpdateDisputeStatusById/dispute_id/${id}`)
			.then(function (response) {
				if (response.data.status === 1) {
					console.log("disputeActive>>>>>>>>>>", response.data);
					setDisputeTableUpdated(response.data);
					Swal.fire(PopMesseges.DISPACHER_DISPUTES_STATUS_UPDATED.SUCCESS, '', 'success');
				} else {
					Swal.fire(response.data.message.message, '', 'warning',
						document.addEventListener('keydown', function (event) {
							if (event.key === "Enter") {
								Swal.close();
							}
						}));
				}
			})
			.catch(function (error) {
				console.log(error);
				Swal.fire({
					title: PopMesseges.DISPACHER_DISPUTES_STATUS_UPDATED.DELETE_ERROR_TITLE,
					icon: 'error',
					text: error.response.data,
				});
			});
	}

	const rows = DisputeTable && DisputeTable.map((row, index) => ({
		...row,
		serialNumber: index + 1,
	}));

	return (
		<div style={{ padding: '20px' }}>
			{isLoading ? (
				<div className='d-flex justify-content-center align-items-center'>
					<Spinner animation="border" />
				</div>
			) : (
				<Table bordered hover responsive style={{ backgroundColor: '#E6F5F5' }}>
					<thead style={{ backgroundColor: '#E6F5F5', color: '#333' }}>
						<tr style={{ fontSize: '14px' }}>
							<th style={{ minWidth: '50px' }}>Sr.No</th>
							<th style={{ minWidth: '80px' }}>Id</th>
							<th style={{ minWidth: '400px' }}>Description</th>
							<th style={{ minWidth: '150px' }}>Created By</th>
							<th style={{ minWidth: '150px' }}>Type</th>
							<th style={{ minWidth: '150px' }}>Status</th>
							<th style={{ minWidth: '150px' }}>Created On</th>
							<th style={{ minWidth: '100px' }}>Action</th>
						</tr>
					</thead>
					<tbody>
						{rows && rows.length > 0 ? rows.map((row, index) => (
							<tr key={row.id} style={{ fontSize: '13px' }}>
								<td>{index + 1}</td>
								<td>{row.id}</td>
								<td>{row.description}</td>
								<td>{row.created_by}</td>
								<td>{row.type}</td>
								<td>{row.status}</td>
								<td>{row.created_at ? formatDate(row.created_at) : ''}</td>
								<td>
									<div className={row.status === "CREATED" ? "admin-active-status custom-cursor-pointer" : "admin-inactive-status"}
										onClick={() => {
											if (row.status === "CREATED") {
												DisputeActiveStatus(row.id);
											}
										}}
									>
										{row.status === "CREATED" ? 'Resolve' : 'Resolved'}
									</div>
								</td>
							</tr>
						)) : (
							<tr>
								<td colSpan="8" className="text-center">No rows available</td>
							</tr>
						)}
					</tbody>
				</Table>
			)}
		</div>
	);
};

export default DisputeModalDataTable;
