// import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React, { memo, useEffect, useState } from "react";
// import { Col, Row } from "react-bootstrap";
// import { convertTo12Hour } from "../../../Utils/DateTimeUtils";






// const OrderPocessingCard = ({ orderStatus }) => {

// 	// ------------------ OrderDetails States--------------------
// 	const [mergedOrderStatusArray, setMergedOrderStatusArray] = useState([]);

// 	// ------------------ Inistial Array States--------------------
// 	const stagesarray = [
// 		{ order_status: "ORDERED", created_time: null, isActive: false },
// 		{ order_status: "RECEIVED", created_time: null, isActive: false },
// 		{ order_status: "ASSIGNED", created_time: null, isActive: false },
// 		{ order_status: "PROCESSING", created_time: null, isActive: false },
// 		{ order_status: "REACHED", created_time: null, isActive: false },
// 		{ order_status: "PICKEDUP", created_time: null, isActive: false },
// 		{ order_status: "ARRIVED", created_time: null, isActive: false },
// 		{ order_status: "COMPLETED", created_time: null, isActive: false },
// 		{ order_status: "CANCELLED", created_time: null, isActive: false },
// 	]

// 	// Find the index of the "CANCELLED" or "COMPLETED" status
// 	const stopIndex = mergedOrderStatusArray.findIndex(
// 		(order) => order.order_status === 'CANCELLED' || order.order_status === 'COMPLETED'
// 	);

// 	// Filter out statuses after "CANCELLED" or "COMPLETED" (if found)
// 	const filteredOrderStatusArray =
// 		stopIndex !== -1 ? mergedOrderStatusArray.slice(0, stopIndex + 1) : mergedOrderStatusArray;

// 	// ------------------------- Merge Dummy Array With Order Status Arayy ---------------------
// 	useEffect(() => {
// 		// Create a new array by mapping over stagesarray
// 		const updatedArray = stagesarray.map(stageItem => {
// 			// Find the corresponding item in orderStatus
// 			const testingItem = orderStatus.find(testItem => testItem.order_status === stageItem.order_status);

// 			// Update created_time and isActive based on testingItem
// 			return {
// 				...stageItem,
// 				created_time: testingItem ? testingItem.created_time : stageItem.created_time,
// 				isActive: testingItem ? true : stageItem.isActive,
// 			};
// 		});

// 		// Sort the mergedOrderStatusArray based on created_time
// 		updatedArray.sort((a, b) => {
// 			const timeA = a.created_time ? new Date(a.created_time) : null;
// 			const timeB = b.created_time ? new Date(b.created_time) : null;

// 			if (timeA && timeB) {
// 				return timeA - timeB;
// 			} else if (timeA === null && timeB !== null) {
// 				return 1; // move items with null created_time to the end
// 			} else if (timeA !== null && timeB === null) {
// 				return -1; // move items with null created_time to the end
// 			} else {
// 				return 0;
// 			}
// 		});

// 		// Set the merged array in the state
// 		setMergedOrderStatusArray(updatedArray);
// 	}, [orderStatus]);



// 	return (
// 		<div className="custom-resturatn-small-card">
// 			<Row>
// 				<Col md={12}>
// 					{/* ------------------------------ Order Status For Mobile ------------------------------- */}
// 					<div className="stepper-wrapper desktop-order-tracking">
// 						{filteredOrderStatusArray ? (
// 							filteredOrderStatusArray.map((value, index) => {
// 								let displayTime = '';
// 								if (value && value.created_time) {
// 									const [datePart, timePart] = value.created_time.split(' ');
// 									displayTime = convertTo12Hour(timePart);
// 								}

// 								return (
// 									<div
// 										className={`stepper-item ${value.isActive === true ? 'completed' : ''}`} key={index}>
// 										<div className="step-counter">
// 											{value.isActive === true && (<FontAwesomeIcon icon={faCheck} color="#fff" />)}
// 										</div>
// 										<div className="step-name restaurant-info-name">{value.order_status}</div>
// 										<div className="step-name restaurant-info-name">{displayTime ? displayTime : ''}</div>
// 									</div>
// 								);
// 							})
// 						) : (
// 							""
// 						)}
// 					</div>

// 					{/* ------------------------------ Order Status For Mobile ------------------------------- */}
// 					<div className="order-track mobile-order-tracking">
// 						{mergedOrderStatusArray ? mergedOrderStatusArray.map((value, index) => {
// 							let displayTime = '';
// 							if (value && value.created_time) {
// 								const [datePart, timePart] = value.created_time.split(' ');
// 								displayTime = convertTo12Hour(timePart);
// 							}
// 							return (
// 								<div className={`order-track-step ${value.isActive === true ? 'completed' : ''}`} key={index}>
// 									<div className="order-track-status">
// 										<span className={`order-track-status-dot ${value.isActive === true ? 'completed' : ''}`}>
// 											{value.isActive === true &&
// 												<FontAwesomeIcon icon={faCheck} color='#fff' />
// 											}
// 										</span>
// 										<span className={`order-track-status-line ${value.isActive === true ? 'completed' : ''}`}></span>
// 									</div>
// 									<div className="order-track-text">
// 										<div className="order-track-text-stat">{value.order_status} </div>
// 										<div className="order-track-text-sub">{displayTime ? displayTime : ''}</div>
// 									</div>
// 								</div>
// 							)
// 						}) : ""}
// 					</div>
// 				</Col>
// 			</Row>
// 		</div>
// 	)
// }

// export default memo(OrderPocessingCard)



import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { convertTo12Hour } from "../../../Utils/DateTimeUtils";

const OrderPocessingCard = ({ orderStatus }) => {


	// const tooltip = (
	// 	<Tooltip id="tooltip">
	// 		<strong>Order Assigned To :</strong> Rizwan Ahmad Shaikh
	// 	</Tooltip>
	// );

	return (
		<div className="custom-resturatn-small-card">
			<Row>
				<Col md={12}>
					{/* ------------------------------ Order Status For Desktop ------------------------------- */}
					<div className="stepper-wrapper desktop-order-tracking">
						{orderStatus.map((value, index) => {
							let displayTime = '';
							if (value && value.created_time) {
								const [datePart, timePart] = value.created_time.split(' ');
								displayTime = convertTo12Hour(timePart);
							}

							return (
								<div
									className={`stepper-item completed`} key={index}>
									{/* <OverlayTrigger placement="top" overlay={tooltip}>
										<FontAwesomeIcon icon={faInfoCircle} />
									</OverlayTrigger> */}
									<div className="step-counter mt-2">
										<FontAwesomeIcon icon={faCheck} color="#fff" />
									</div>
									<div className="step-name restaurant-info-name">{value.order_status}</div>
									<div className="step-name restaurant-info-name">{displayTime ? displayTime : ''}</div>
								</div>
							);
						})}
					</div>

					{/* ------------------------------ Order Status For Mobile ------------------------------- */}
					<div className="order-track mobile-order-tracking">
						{orderStatus.map((value, index) => {
							let displayTime = '';
							if (value && value.created_time) {
								const [datePart, timePart] = value.created_time.split(' ');
								displayTime = convertTo12Hour(timePart);
							}
							return (
								<div className={`order-track-step completed`} key={index}>
									{/* <OverlayTrigger placement="top" overlay={tooltip}>
										<FontAwesomeIcon icon={faInfoCircle} />
									</OverlayTrigger> */}
									<div className="order-track-status ms-2">
										<span className={`order-track-status-dot completed`}>
											<FontAwesomeIcon icon={faCheck} color='#fff' />
										</span>
										<span className={`order-track-status-line completed`}></span>
									</div>
									<div className="order-track-text">
										<div className="order-track-text-stat">{value.order_status} </div>
										<div className="order-track-text-sub">{displayTime ? displayTime : ''}</div>
									</div>
								</div>
							)
						})}
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default memo(OrderPocessingCard);
