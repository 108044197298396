import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Collapse } from "react-bootstrap";
import * as actionTypes from "../../ReduxManager/actions";
import InactiveSideBarDropdownIcon from "../../assets/images/svg/sidebar/icon_sidebar_inactive_dropdown.svg";
import ActiveSideBarDropdownIcon from "../../assets/images/svg/sidebar/icon_sidebar_active_dropdown.svg";
import DummyProfileImage from "../../assets/images/dummy_avtar.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import EditProfileIcon from "../../assets/images/png/icons8-edit-profile-96.png";
import ActiveEditProfileIcon from "../../assets/images/png/icons8-active-edit-profile.png";
import LogOutIcon from "../../assets/images/png/icons8-logout-96.png";
import { connect, useDispatch } from "react-redux";
import config from "../../config";
import { checkImageUrl } from "../../Utils/checkImageUrl";

const Sidebar = ({
  handlesetMobileToggelActive = () => { }, handleShowProfileEdit = () => { },
  onAdminLogout, navigation, toggelMobileActive, adminName, reduxAdminImage, reduxActiveMenuItemIndex,
  reduxToggleCollapse, reduxSetActiveMenuItemIndex, accessRights, adminEmail }) => {

  // ----------------------  navigation & location keys --------------------------
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const sidebarRef = useRef(null);

  // --------------- State ---------------
  const [profileOpen, setProfileOpen] = useState(false);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(reduxActiveMenuItemIndex);

  // ---------------------- access module keys --------------------------
  const accessModuleKeys = accessRights ? accessRights.map((valid) => valid.module_key) : [];

  // -------------- find matching index for initial time ---------------------
  useEffect(() => {
    const path = location.pathname;
    // Find the matching index in navigation.superItems
    const findMatchingIndex = (items, path) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].isChildren) {
          // Check if any child item matches the current path
          const matchingChild = items[i].children.find(child => child.url === path);
          if (matchingChild) {
            return i;
          }
        } else if (items[i].url === path) {
          return i;
        }
      }
      return -1; // Return -1 if no matching item is found
    };
    const matchedIndex = findMatchingIndex(navigation.superItems, path);
    setActiveMenuItemIndex(matchedIndex);
    reduxSetActiveMenuItemIndex(matchedIndex);
  }, [location.pathname, navigation.superItems, reduxSetActiveMenuItemIndex]);


  // --------------- With Redux Sidebar open list function---------------
  const toggleCollapse = (index) => {
    setActiveMenuItemIndex((prevIndex) => (prevIndex === index ? null : index));
    // If clicking on the same index, do nothing
    if (activeMenuItemIndex === index) {
      return;
    }
    // If clicking on a different index
    reduxToggleCollapse(index); // Open if closed
    reduxSetActiveMenuItemIndex(index);
    setProfileOpen(false);
  };

  const handleActiveProfileCollapse = () => {
    setProfileOpen(!profileOpen);
    // setActiveMenuItemIndex();
    // reduxSetActiveMenuItemIndex();
  };

  //-----------------------------------
  // Admin Logout functionality
  //--------------------------------------
  const adminLogout = () => {
    onAdminLogout(false, "");
    // console.log("admin logout functions");
    // Clear the entire Redux state
    dispatch({ type: actionTypes.CLEAR_REDUX_STATE });
    navigate("/login");
  };


  // Save scroll position before navigating
  const saveScrollPosition = () => {
    if (sidebarRef.current) {
      sessionStorage.setItem('sidebarScrollPosition', sidebarRef.current.scrollTop);
    }
  };

  // Restore scroll position after reload
  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('sidebarScrollPosition');
    if (savedScrollPosition && sidebarRef.current) {
      sidebarRef.current.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, []);


  //////////////////////////////////// OLD CODE WITHOUT REDUX AND ACCESS RIGHT ////////////////////////////////////
  // // Filtering the sidebar items based on access rights
  // const accessibleItems = navigation.superItems.filter((item) => {
  //   // If the item has children, check if any of the children are accessible
  //   if (item.isChildren) {
  //     item.children = item.children.filter((child) =>
  //       accessRights.some((right) => right.module_key === child.routerKey)
  //     );
  //     return item.children.length > 0;
  //   }
  //   // For items without children, directly check the routerKey
  //   return accessRights.some((right) => right.module_key === item.routerKey);
  // });

  // // --------------- Local Sidebar open list function---------------
  // const toggleCollapse = (index) => {
  //   if (activeMenuItemIndex === index) {
  //     setActiveMenuItemIndex(-1); // Close if already open
  //   } else {
  //     setActiveMenuItemIndex(index); // Open if closed
  //   }
  //   setProfileOpen(false);
  // };

  // const handleActiveProfileCollapse = () => {
  //   setProfileOpen(!profileOpen);
  //   setActiveMenuItemIndex(-1);
  // };

  // --------------------- Update local state when Redux state changes ---------
  // useEffect(() => {
  //   console.log("reduxActiveMenuItemIndex", reduxActiveMenuItemIndex);
  //   setActiveMenuItemIndex(reduxActiveMenuItemIndex);
  // }, [reduxActiveMenuItemIndex]);



  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar" ref={sidebarRef}>
      <ul>
        <li
          className={`side-bar-mobile-container ${profileOpen === true ? "active-sidebar-card" : ""
            }`}
        >
          <div
            className="sidebar-main-container"
            onClick={handleActiveProfileCollapse}
          >
            <div className="sidebar-container">
              <div className="sidebar-container-profile">
                <LazyLoadImage
                  src={
                    reduxAdminImage && reduxAdminImage
                      ? checkImageUrl(reduxAdminImage) === "Full URL"
                        ? reduxAdminImage
                        : `${config.serverImageUrl}${reduxAdminImage}`
                      : DummyProfileImage
                  }
                />
              </div>
              <div
                className={` ms-2 sidebar-module-title-text ${profileOpen === true && "sidebar-title-active-text"
                  }`}
              >
                {adminName}
              </div>
            </div>
            {profileOpen === true ? (
              <LazyLoadImage
                src={ActiveSideBarDropdownIcon}
                alt="ActiveSideBarDropdownIcon"
                className="sidebar-module-title-DropdownIcon"
              />
            ) : (
              <LazyLoadImage
                src={InactiveSideBarDropdownIcon}
                alt="InactiveSideBarDropdownIcon"
                className="sidebar-module-title-DropdownIcon"
              />
            )}
          </div>
          <Collapse in={profileOpen}>
            <div id="example-collapse-text" className="example-collapse-text">
              <ul className="collapse-title">
                <li
                  className="active-collapse-title"
                  onClick={handleShowProfileEdit}
                >
                  <div
                    className="profile-card-mobile"
                    onClick={handlesetMobileToggelActive}
                  >
                    {toggelMobileActive === true ? (
                      <LazyLoadImage src={ActiveEditProfileIcon} alt="" />
                    ) : (
                      <LazyLoadImage src={EditProfileIcon} alt="" />
                    )}
                    <div className="profile-card-mobile-text active">
                      Edit Profile
                    </div>
                  </div>
                </li>
                <li onClick={adminLogout}>
                  <div className="profile-card-mobile">
                    <LazyLoadImage src={LogOutIcon} alt="" />
                    <div className="profile-card-mobile-text active">
                      Logout
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        {navigation.superItems.map((value, index) => {
          // console.log("superMenuItems", value);

          // Determine if the parent item should be visible based on its children or parent route key
          let shouldDisplayParent = false;
          if (value.isChildren) {
            // Check if any child item is accessible
            shouldDisplayParent = value.children?.some(child =>
              accessModuleKeys.includes(child.routerKey)
            );
          } else {
            // Use parent route key for access control
            shouldDisplayParent = accessModuleKeys.includes(value.routerKey);
          }
          // If the parent item should not be displayed, skip rendering it
          if (!shouldDisplayParent) {
            return null;
          }

          return (
            <li
              className={
                activeMenuItemIndex === index ? "active-sidebar-card" : ""
              }
              key={index}
            >
              <div
                className="sidebar-main-container"
                onClick={() => toggleCollapse(index)}
                aria-controls="example-collapse-text"
                aria-expanded={activeMenuItemIndex === index}
              >
                <div className="sidebar-container">
                  {activeMenuItemIndex === index ? (
                    <NavLink
                      to={value.url}
                      onClick={(e) => {
                        handlesetMobileToggelActive();
                        saveScrollPosition();
                        window.location.href = value.url; // This will force a page reload
                      }}
                    >
                      <LazyLoadImage
                        src={value.active_icon.type}
                        alt="ActiveSideBarDispacher"
                      />
                    </NavLink>
                  ) : (
                    <NavLink
                      to={value.url}
                      onClick={handlesetMobileToggelActive}
                    >
                      <LazyLoadImage
                        src={value.inactive_icon.type}
                        alt="InactiveSideBarDispacher"
                      />
                    </NavLink>
                  )}

                  <div
                    className={` ms-2 sidebar-module-title-text ${activeMenuItemIndex === index &&
                      "sidebar-title-active-text"
                      }`}
                  >
                    {value.isChildren === true ? (
                      <div className="sidebar-only-title-value-text">
                        {value.title}
                      </div>
                    ) : (
                      <NavLink
                        to={value.url}
                        onClick={(e) => {
                          handlesetMobileToggelActive();
                          saveScrollPosition();
                          window.location.href = value.url; // This will force a page reload
                        }}
                      >
                        {value.title}
                      </NavLink>
                    )}
                  </div>
                </div>
                {value.isChildren === true ? (
                  activeMenuItemIndex === index ? (
                    <LazyLoadImage
                      src={ActiveSideBarDropdownIcon}
                      alt="ActiveSideBarDropdownIcon"
                      className="sidebar-module-title-DropdownIcon"
                    />
                  ) : (
                    <LazyLoadImage
                      src={InactiveSideBarDropdownIcon}
                      alt="InactiveSideBarDropdownIcon"
                      className="sidebar-module-title-DropdownIcon"
                    />
                  )
                ) : undefined}
              </div>
              {value.isChildren === true && (
                <Collapse in={activeMenuItemIndex === index}>
                  <div
                    id="example-collapse-text"
                    className="example-collapse-text"
                  >
                    <ul className="collapse-title sidebar-collapse-card">
                      {value.children &&
                        value.children.map((childValue, childIndex) => {
                          // console.log("childValue", childIndex);
                          if (!accessModuleKeys.includes(childValue.routerKey)) {
                            return null; // Hide child item if user doesn't have access
                          }

                          return (
                            <li
                              key={childIndex}
                              className="active-collapse-title"
                            >
                              <NavLink
                                to={childValue.url}
                                onClick={(e) => {
                                  handlesetMobileToggelActive();
                                  saveScrollPosition();
                                  window.location.href = childValue.url; // This will force a page reload
                                }}
                                title={childValue.title}
                              >
                                {childValue.title}
                              </NavLink>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </Collapse>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    adminName: state.adminName,
    adminEmail: state.adminEmail,
    reduxActiveMenuItemIndex: state.reduxActiveMenuItemIndex,
    reduxAdminImage: state.adminImage,
    accessRights: state.accessRights,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdminLogout: (authstate, userId) =>
      dispatch({
        type: actionTypes.ADMIN_AUTHORIZED,
        payload: { authState: authstate, userId: userId },
      }),
    reduxToggleCollapse: (index) =>
      dispatch({ type: actionTypes.TOGGLE_COLLAPSE, payload: index }),
    reduxSetActiveMenuItemIndex: (index) =>
      dispatch({
        type: actionTypes.SET_ACTIVE_MENU_ITEM_INDEX,
        payload: index,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
