import React, { memo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EditButton from '../../../assets/images/svg/dispacher-edit-btn.svg'
import DeleteButton from "../../../assets/images/svg/dispacher-delete-btn.svg";
import DummyAvtarImage from '../../../assets/images/dummy_avtar.png'
import CashfreeIcon from "../../../assets/images/svg/icon_dispacher_cashfree.svg";
import AddressIcon from "../../../assets/images/svg/icon_dispacher_adress.svg";
import NavigationIcon from "../../../assets/images/svg/icon_dispacher_navigation.svg";
import DeviceTypeIcon from "../../../assets/images/svg/icon_device_type.svg";
// import OrderTypeIcon from "../../../assets/images/svg/icon_order_type.svg";
import ClockIcon from "../../../assets/images/svg/icon_dispacher_clock.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking, faBookOpen, faComment, faFileInvoice, faGavel, faMapMarkedAlt, } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { convertTo12Hour, formatDate } from "../../../Utils/DateTimeUtils";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axiosInstance from "../../../axios";
import { CircularProgress } from "@mui/material";
import PopMesseges from "../../../Utils/popMesseges.json"
import OederEditedIcon from "../../../assets/images/svg/icon_edited_order.svg";
import InvoiceEditedIcon from "../../../assets/images/svg/icon_edited_invoice.svg";
import RiderEditedIcon from "../../../assets/images/svg/icon_edited_rider.svg"
import { imageOnErrorHandler } from "../../../Utils/imageErrorHandler";
import { checkImageUrl } from "../../../Utils/checkImageUrl";
import config from "../../../config";
import OutOfStock from "../../../assets/images/png/order_card_out_of_stock.png"
import { getRouterKeyByPath } from "../../../Routes/Routes";







const OrderStatusCardComponent = React.memo(({
  OrdersDetailsData, sectionWidth, handleShowMap, openDirections = () => { }, setNavigateToEditPage, GlobalOrderSearchModalOpen, setOrdersCancellation,
  handleAssignButtonClick = () => { }, handleShowAssingOrder = () => { }, handleShowLocationOrder = () => { }, onMediaCardAssingStatusChange = () => { },
  handleShowOrderDetails = () => { }, handleShowDispute = () => { }, getPendingOrders = () => { }, reduxActiveTabKey,
  isAccessRights, reduxCityName, reduxRestaurantName, setOrdersUpdated, GlobalOrderSearch
}) => {

  // Get the routerKey for the current path
  // const location = useLocation();
  // const currentPath = location.pathname;
  // const routerKey = getRouterKeyByPath(currentPath)

  // Find the access rights object for the current routerKey
  const accessRight = isAccessRights.find(right => right.module_key === "DISPATCHER_LIST");
  const permissions = accessRight ? accessRight.permissions.map(p => p.permission_key) : [];
  const canDelete = permissions.includes('DELETE');

  // Get the routerKey for the current path
  const addRouterKey = 'ORDER_DETAILS';
  // Find the access rights object for the current routerKey
  const addAccessRight = isAccessRights && isAccessRights.find(right => right.module_key === addRouterKey);



  // ---------- function cancellation -------------
  const handleCancellationClick = (id, canDelete) => {
    console.log("canDelete", canDelete);

    if (canDelete) {
      handleCancellationReasonShow(id);
    } else {
      Swal.fire({
        title: 'Action not allowed',
        text: 'You do not have permission to delete this item.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };


  // -------------------------- upload progress state ------------------------
  const [uploadProgress, setUploadProgress] = useState(0);

  // --------- navigation details page -------------------------
  const navigate = useNavigate()
  const navigateOrderEditPage = (id, hasAccessRight) => {
    if (!hasAccessRight) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have access to this module.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
    if (id) {
      navigate(`/order-details/${id}`)
      if (GlobalOrderSearchModalOpen) {
        setNavigateToEditPage(true)
      }
    } else {
      Swal.fire('Id Not Found', '', 'warning');
    }
  }



  /*-------------------------------------------------------------
  Order Cancel  Reason Modal States
  ---------------------------------------------------------------*/
  const [OrderId, setOrderId] = useState(null);
  const [cancellationReason, setCancellationReason] = useState("");
  const [showCancellationReason, setShowCancellationReason] = useState(false);
  const handleCancellationReasonClose = () => {
    setShowCancellationReason(false);
  };
  const handleCancellationReasonShow = (id) => {
    setShowCancellationReason(true);
    setOrderId(id)
  };

  // const handleCancelOrder = async () => {
  // 	if (!cancellationReason.trim()) {
  // 		Swal.fire('Please provide a reason for cancellation.', '', 'warning');
  // 	}
  // 	else {
  // 		try {
  // 			const response = await axiosInstance.put(`/adminOrders/updateOrderStatusToCancelled/order_id/${OrderId}`, { reason: cancellationReason });
  // 			console.log("respons");
  // 			// handleApiResponse(response);
  // 			// setOrderDetailsDataUpdated(response)
  // 			setShowCancellationReason(false);
  // 		} catch (error) {
  // 			// handleApiError(error);
  // 		}
  // 	}
  // };


  const [validation, setValidation] = useState(null)

  const handleCancelOrder = (e) => {
    e.preventDefault();
    if (!cancellationReason.trim()) {
      Swal.fire(PopMesseges.EMPTY_FILEDS_VALIDATION, '', 'warning',
        document.addEventListener('keydown', function (event) {
          if (event.key === "Enter") {
            Swal.close();
          }
        }));
      setValidation({
        cancellationReason: !cancellationReason.trim(),
      })
    }
    else {

      const data =
      {
        "reason": cancellationReason,
      }
      console.log("data", data);
      axiosInstance.put(`/adminOrders/updateOrderStatusToCancelled/order_id/${OrderId}`, data, {
        onUploadProgress: (progressEvent) => {
          // console.log("progressEvent", progressEvent);
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      })
        .then(function (response) {
          if (response.data.status === 1) {
            console.log("Resppp>>>>>>>>>>", response.data);
            Swal.fire({
              icon: 'success',
              title: 'Order Cancel Successfully',
              showConfirmButton: false,
              timer: 1000
            })
            // getPendingOrders(reduxCityName, reduxRestaurantName)
            setOrdersUpdated(response.data)
            setCancellationReason("")
            setShowCancellationReason(false);
            if (GlobalOrderSearchModalOpen) {
              setOrdersCancellation(true)
            }
            setUploadProgress(0)
          } else {
            Swal.fire(response.data.message.message, '', 'warning',
              document.addEventListener('keydown', function (event) {
                if (event.key === "Enter") {
                  Swal.close();
                }
              }));
            setUploadProgress(0)
          }

        })
        .catch(function (error) {
          console.log(error);
          Swal.fire(error.response.data.error || error.response.data.message, '', 'warning',
            document.addEventListener('keydown', function (event) {
              if (event.key === "Enter") {
                Swal.close();
              }
            }));
          setUploadProgress(0)
        });
    }
  }

  // console.log("sectionWidth", sectionWidth);



  return (
    <div>
      {
        OrdersDetailsData && OrdersDetailsData.map((value, index) => {
          // console.log("OrdersCardDetailsData", value.out_of_stock);
          // if (value.status) {
          //   onMediaCardAssingStatusChange(value.status);
          // }

          const timestamp = value.created_at && value.created_at;
          const [datePart, timePart] = timestamp.split(' ');

          return (
            <div key={index} className={`custom-card custom-dispacher-card mb-3 order-card-green-bg-color
              ${value.out_of_stock === "YES" && 'order-card-orange-bg-color'}
			${(value.status && value.status === "RECEIVED") && 'order-card-red-bg-color' ||
              (value.status === "ORDERED") && 'order-card-green-bg-color' ||
              (value.status === "PROCESSING" || value.status === "CANCELLED") && 'order-card-blue-bg-color'}
							`}
            >
              <div className='custom-card-body'>
                <div className="dispacher-card-header-section">
                  {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                  <div className={`inside-header-section desktop-header-section ${sectionWidth >= 489 && sectionWidth <= 835 ? "custome-inside-header-width" : ""}`}>
                    <div className="order-id-secction">
                      <div>#{value.id ? value.id : ""}</div>
                      <div>[#{value.new_order_id ? value.new_order_id : ""}]</div>
                      {(value.status === "RECEIVED" || value.status === "ORDERED") ? "" :
                        <a href={`tel:${value.transporter_phone ? value.transporter_phone : "#"}`} data-phone={value.transporter_phone ? value.transporter_phone : "No phone number available"}>{value.transporter_name ? value.transporter_name : "-----"}</a>
                      }
                    </div>
                    {/* <div className="order-id-secction">
										</div> */}

                    <div className="dispacher-card-timing">
                      <div className="me-2">
                        <LazyLoadImage src={ClockIcon} alt="error" />
                      </div>
                      <div>{timePart ? convertTo12Hour(timePart) : "-----"} </div>
                      <div className="ms-2"> {datePart ? formatDate(datePart) : ""}</div>
                    </div>
                  </div>
                  {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                  <div className="inside-header-section mobile-header-section">
                    <div className="order-id-secction">
                      <div>#{value.id ? value.id : ""}</div>
                      <div>[#{value.new_order_id ? value.new_order_id : ""}]</div>
                      {(value.status === "RECEIVED" || value.status === "ORDERED") ? "" :
                        <a href={`tel:${value.transporter_phone ? value.transporter_phone : "#"}`} data-phone={value.transporter_phone ? value.transporter_phone : "No phone number available"}>{value.transporter_name ? value.transporter_name : "-----"}</a>
                      }
                    </div>
                    <div className="dispacher-card-timing">
                      <div className="me-2">
                        <LazyLoadImage src={ClockIcon} alt="error" />
                      </div>
                      <div>{timePart ? convertTo12Hour(timePart) : "-----"} </div>
                      <div className="ms-2"> {datePart ? formatDate(datePart) : ""}</div>
                    </div>
                  </div>
                  <div className="custom-action-button">
                    <div className="edit-button" onClick={() => { navigateOrderEditPage(value.id && value.id, addAccessRight) }}>
                      <LazyLoadImage src={EditButton} alt="" />
                    </div>
                    <div className="delete-button" onClick={() => handleCancellationClick(value.id, canDelete)}>
                      <LazyLoadImage src={DeleteButton} alt="" />
                    </div>
                  </div>
                </div>
                <div className="dispacher-card-body-section">
                  <div className="dispacher-profile-section">
                    <div className="user-image-dispacher">
                      <LazyLoadImage
                        src={
                          value.avatar && value.avatar
                            ? checkImageUrl(value.avatar) === "Full URL"
                              ? value.avatar
                              : `${config.serverImageUrl}${value.avatar}`
                            : DummyAvtarImage
                        }
                        onError={imageOnErrorHandler} alt="error" />
                    </div>
                    <div className="dispacher-user-name">{value.name ? value.name : ""}</div>
                    <div className="dispacher-user-name">{value.user_phone ? value.user_phone : ""}</div>
                    {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                    <div className={`desktop-dispacher-navigation-inside-profile dispacher-navigation-section ${sectionWidth >= 489 && sectionWidth <= 1020 ? "custome-dispacher-navigation-width-inside-profile" : ""}`}>
                      <div className="navigation-card">
                        <span><LazyLoadImage src={CashfreeIcon} alt="error" /></span>
                        <span>{value.payment_mode ? value.payment_mode : "-----"}</span>
                      </div>
                      <div className="navigation-card">
                        <span><LazyLoadImage src={NavigationIcon} alt="error" /></span>
                        <span>{value.city ? value.city : "--------"}</span>
                      </div>
                      {/* <div className="navigation-card">
                        <span><LazyLoadImage src={OrderTypeIcon} alt="error" /></span>
                        <span>{value.order_type ? value.order_type : "--------"}</span>
                      </div> */}
                      <div className="navigation-card">
                        <span><LazyLoadImage src={DeviceTypeIcon} alt="error" /></span>
                        <span>{value.device ? value.device : "--------"}</span>
                      </div>
                    </div>
                    {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                    <div className="mobile-dispacher-navigation-inside-profile dispacher-navigation-section">
                      <div className="navigation-card">
                        <span><LazyLoadImage src={CashfreeIcon} alt="error" /></span>
                        <span>{value.payment_mode ? value.payment_mode : "-----"}</span>
                      </div>
                      <div className="navigation-card">
                        <span><LazyLoadImage src={NavigationIcon} alt="error" /></span>
                        <span>{value.city ? value.city : "--------"}</span>
                      </div>
                      {/* <div className="navigation-card">
                        <span><LazyLoadImage src={OrderTypeIcon} alt="error" /></span>
                        <span>{value.order_type ? value.order_type : "--------"}</span>
                      </div> */}
                      <div className="navigation-card">
                        <span><LazyLoadImage src={DeviceTypeIcon} alt="error" /></span>
                        <span>{value.device ? value.device : "--------"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dispacher-address-section">
                    <div className="">
                      <span className="dispacher-adress-title">
                        Restaurant:
                      </span>
                      <span> {value.shop_name ? value.shop_name : ""}</span>
                      <span> {value.shop_phone ? value.shop_phone : ""} {value.shop_alt_phone ? `/ ${value.shop_alt_phone}` : ""}</span>
                    </div>
                    <div>
                      <span className="dispacher-adress-title">
                        Address:
                      </span>
                      <span> {value.user_building ? value.user_building : ''}, </span>
                      <span> {value.user_landmark ? value.user_landmark : ''}, </span>
                      <span className="user-map-address-text"> {value.user_map_address ? value.user_map_address : ''}</span>
                      <span className="ms-2 custom-cursor-pointer"
                        onClick={() => { openDirections(value.shop_map_address, value.user_map_address) }}
                      >
                        <LazyLoadImage src={AddressIcon} alt="error" />
                      </span>
                    </div>
                    {
                      (value.is_order_edited === 1 || value.is_invoice_edited === 1 ||
                        value.is_rider_edited === 1) &&
                      (<div className="mt-2">
                        <span className="dispacher-adress-title">
                          Is Edited:
                        </span>
                        <span className="ms-2">
                          {value.is_order_edited === 1 &&
                            <LazyLoadImage src={OederEditedIcon} alt="error" width={30} className="ms-2" />}
                          {value.is_invoice_edited === 1 &&
                            <LazyLoadImage src={InvoiceEditedIcon} alt="error" width={30} className="ms-2" />
                          }
                          {value.is_rider_edited === 1 &&
                            <LazyLoadImage src={RiderEditedIcon} alt="error" width={30} className="ms-2" />}
                        </span>
                      </div>)
                    }
                    {/* <div className="mt-2">
											<span className="dispacher-adress-title">
												Order Type:
											</span>
											<span> {value.order_type ? value.order_type : ""} , </span>
											<span className="dispacher-adress-title">
												Device Type:
											</span>
											<span className="text-capitalize"> {value.device ? value.device : ""}</span>
										</div> */}
                    <div className="order-asing-btn-section mt-3">
                      {
                        value.status && value.status === "RECEIVED" &&
                        <div>
                          {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                          <Button className="desktop-asing-button dsp-assing-btn me-2"
                            onClick={() => { handleAssignButtonClick(value.city, value.id, value.shop_latitude, value.shop_longitude) }}
                          >
                            <span className={`assign-button 
                            ${(sectionWidth >= 489 && ((sectionWidth <= 625) || (sectionWidth <= 745))) ? "custome-assign-button-width" : ""}`}>
                              Assign
                            </span>
                            <FontAwesomeIcon icon={faBiking} className={`assign-icon 
                            ${(sectionWidth >= 489 && ((sectionWidth) <= 625 || (sectionWidth <= 745))) ? "custome-assign-icon-width" : ""}`} />
                          </Button>
                          {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                          <Button className="mobile-asing-button dsp-assing-btn me-2"
                            onClick={() => { handleShowAssingOrder(value.city, value.id, value.shop_latitude, value.shop_longitude) }}
                          >
                            <span className={`assign-button`}>
                              Assign
                            </span>
                            <FontAwesomeIcon icon={faBiking} className={`assign-icon`} />
                          </Button>
                        </div>
                      }
                      <div>
                        {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                        <Button className="desktop-asing-button dsp-order-detail-btn me-2"
                          onClick={() => { handleShowOrderDetails(value.id) }}
                        >
                          <span className={`order-button 
                        ${(sectionWidth >= 489 && (sectionWidth <= 770 || sectionWidth <= 1055)) ? "custome-order-button-width" : ""}`}>
                            Details
                          </span>
                          <FontAwesomeIcon icon={faBookOpen} className={`order-icon 
                        ${(sectionWidth >= 489 && (sectionWidth <= 770 || sectionWidth <= 1055)) ? "custome-order-icon-width" : ""}`} />
                        </Button>
                        {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                        <Button className={`mobile-asing-button dsp-order-detail-btn
                        ${(value.out_of_stock === "YES" && value.status === "PROCESSING") ? 'me-1' : 'me-2'}`}
                          onClick={() => { handleShowOrderDetails(value.id) }}
                        >
                          <span className={"order-button"}>
                            Details
                          </span>
                          <FontAwesomeIcon icon={faBookOpen} className={"order-icon"} />
                        </Button>
                      </div>

                      {
                        value.dispute && (value.dispute === "CREATED" || value.dispute === "RESOLVE") &&
                        <div>
                          {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                          <Button className="desktop-asing-button dsp-order-dispute-btn me-2"
                            onClick={() => { handleShowDispute(value.id) }}
                          >
                            <span className={`order-button 
                        ${(sectionWidth >= 489 && (sectionWidth <= 770 || sectionWidth <= 1055)) ? "custome-order-button-width" : ""}`}>
                              Dispute
                            </span>
                            <FontAwesomeIcon icon={faComment} className={`order-icon 
                        ${(sectionWidth >= 489 && (sectionWidth <= 770 || sectionWidth <= 1055)) ? "custome-order-icon-width" : ""}`} />
                          </Button>
                          {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                          <Button className={`mobile-asing-button dsp-order-dispute-btn
                        ${(value.out_of_stock === "YES" && value.status === "PROCESSING") ? 'me-1' : 'me-2'}`}
                            onClick={() => { handleShowDispute(value.id) }}
                          >
                            <span className={"order-button"}>
                              Dispute
                            </span>
                            <FontAwesomeIcon icon={faComment} className={"order-icon"} />
                          </Button>
                        </div>
                      }

                      {
                        value.status && value.status === "RECEIVED" &&
                        <div>
                          {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                          <Button className="desktop-asing-button dsp-orderd-btn me-2"
                            onClick={() => { handleShowMap(value.shop_latitude, value.shop_longitude, value.shop_name, value.city, value.id) }}
                          >
                            <span className={`show-map-button 
                            ${(sectionWidth >= 489 && (sectionWidth <= 860 || sectionWidth <= 1141)) ? "custome-show-map-button-width" : ""}`}>
                              Show Map
                            </span>
                            <FontAwesomeIcon icon={faMapMarkedAlt} className={`show-map-icon 
                            ${(sectionWidth >= 489 && (sectionWidth <= 860 || sectionWidth <= 1141)) ? "custome-show-map-icon-width" : ""}`} />
                          </Button>
                          {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                          <Button className="mobile-asing-button dsp-orderd-btn me-2"
                            onClick={() => { handleShowLocationOrder(value.shop_latitude, value.shop_longitude, value.shop_name, value.city, value.id) }}
                          >
                            <span className={"show-map-button"}>
                              Show Map
                            </span>
                            <FontAwesomeIcon icon={faMapMarkedAlt} className={"show-map-icon"} />
                          </Button>
                        </div>
                      }

                      {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                      {
                        value.out_of_stock === "YES" && (value.status && value.status === "RECEIVED" ?
                          <div>
                            <Button className="desktop-asing-button dsp-out-of-stock-btn custom-text-cursor">
                              <span className={`out-of-stock-button
                        ${(sectionWidth >= 489 && (sectionWidth <= 860 || sectionWidth <= 1252)) ? "out-of-stock-button-width" : ""}`}>
                                Out Of Stock
                              </span>
                              <LazyLoadImage src={OutOfStock}
                                className={`out-of-stock-icon 
                                ${(sectionWidth >= 489 && (sectionWidth <= 860 || sectionWidth <= 1252)) ? "out-of-stock-icon-width" : ""}`}
                              />
                              {/* <FontAwesomeIcon icon={faFileInvoice} className={`out-of-stock-icon 
                        ${(sectionWidth >= 489 && (sectionWidth <= 860 || sectionWidth <= 1252)) ? "out-of-stock-icon-width" : ""}`} /> */}
                            </Button>
                            {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                            <Button className="mobile-asing-button dsp-out-of-stock-btn custom-text-cursor">
                              <span className={"out-of-stock-button"}>
                                Out Of Stock
                              </span>
                              <LazyLoadImage src={OutOfStock} className={"out-of-stock-icon"} />
                            </Button>
                          </div>
                          :
                          <div>
                            <Button className="desktop-asing-button dsp-out-of-stock-btn custom-text-cursor me-2">
                              <span className={`out-of-stock-button
                        ${(sectionWidth >= 489 && sectionWidth <= 760) ? "out-of-stock-button-width" : ""}`}>
                                Out Of Stock
                              </span>
                              <LazyLoadImage src={OutOfStock} className={`out-of-stock-icon 
                        ${(sectionWidth >= 489 && sectionWidth <= 760) ? "out-of-stock-icon-width" : ""}`} />
                            </Button>
                            {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                            <Button className={`mobile-asing-button dsp-out-of-stock-btn custom-text-cursor
                            ${(value.out_of_stock === "YES" && value.status === "PROCESSING") ? 'me-1' : 'me-2'}`}>
                              <span className={"out-of-stock-withou-any-button"}>
                                Out Of Stock
                              </span>
                              <LazyLoadImage src={OutOfStock} className={"out-of-stock-withou-any-other-icon"} />
                            </Button>
                          </div>
                        )
                      }

                      {(value.status === "RECEIVED" || value.status === "ORDERED") ? "" :
                        value.status && value.status &&
                        <Button className={`order-status-card-btn custom-text-cursor}`}
                        >{value.status ? value.status : "-----"}</Button>
                      }
                      {/* {
												reduxActiveTabKey === 'Accepted Orders' &&
												<Button className="order-status-card-btn custom-text-cursor">	{value.status ? value.status : "-----"}</Button>
											} */}
                    </div>
                  </div>
                  {/* ---------------------------------- Desktop Screen --------------------------------------------- */}
                  <div className={`${GlobalOrderSearch && "global-order-search"} desktop-dispacher-navigation dispacher-navigation-section ${sectionWidth >= 489 && sectionWidth <= 1020 ? "custome-dispacher-navigation-width" : ""} `}>
                    <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={CashfreeIcon} alt="error" /></span>
                      <span>{value.payment_mode ? value.payment_mode : "-----"}</span>
                    </div>
                    <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={NavigationIcon} alt="error" /></span>
                      <span>{value.city ? value.city : "--------"}</span>
                    </div>
                    {/* <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={OrderTypeIcon} alt="error" /></span>
                      <span>{value.order_type ? value.order_type : "--------"}</span>
                    </div> */}
                    <div className="navigation-card">
                      <span><LazyLoadImage src={DeviceTypeIcon} alt="error" /></span>
                      <span>{value.device ? value.device : "--------"}</span>
                    </div>
                  </div>
                  {/* ---------------------------------- Mobile Screen --------------------------------------------- */}
                  <div className="mobile-dispacher-navigation dispacher-navigation-section">
                    <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={CashfreeIcon} alt="error" /></span>
                      <span>{value.payment_mode ? value.payment_mode : "-----"}</span>
                    </div>
                    <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={NavigationIcon} alt="error" /></span>
                      <span>{value.city ? value.city : "--------"}</span>
                    </div>
                    {/* <div className="navigation-card mb-2">
                      <span><LazyLoadImage src={OrderTypeIcon} alt="error" /></span>
                      <span>{value.order_type ? value.order_type : "--------"}</span>
                    </div> */}
                    <div className="navigation-card">
                      <span><LazyLoadImage src={DeviceTypeIcon} alt="error" /></span>
                      <span>{value.device ? value.device : "--------"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }

      {/*--------------------- Modal Order Cancellation Reason ------------------------------*/}
      <Modal
        show={showCancellationReason}
        onHide={handleCancellationReasonClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      // centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Reason</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleCancelOrder}>
          <Modal.Body>
            <Row>
              <Col md={12} className='mb-4'>
                <Form.Group>
                  <Form.Label>Cancellation Reason</Form.Label>
                  <Form.Control type="text" placeholder="Cancellation Reason"
                    value={cancellationReason} onChange={e => setCancellationReason(e.target.value)}
                    className={validation?.cancellationReason && "validation-border"}
                  />
                  {validation?.cancellationReason &&
                    <div className='form-control-validation-text '>Please provide a valid reason</div>
                  }
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="modal-close-button" onClick={handleCancellationReasonClose}>
              No
            </Button>
            {uploadProgress > 0 ?
              <Button className="upload-progress-button">
                <CircularProgress variant="determinate" value={uploadProgress} size={20} style={{ color: 'green' }} />
              </Button>
              : <Button className="modal-save-button" type='submit'>Yes</Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </div >
  )
})

const mapStateToProps = state => {
  return {

    reduxActiveTabKey: state.ordersTabKey,
    reduxCityName: state.orderCityName,
    reduxRestaurantName: state.ordersRestaurantName,
    isAccessRights: state.accessRights,

  }
};

export default connect(mapStateToProps, null)(memo(OrderStatusCardComponent)); 