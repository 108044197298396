import React, { useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import RestaurantLogo from '../../../assets/images/order-restaurant-address.png';
import DeliveryBoyIcon from '../../../assets/images/delivery-boy-location.png';

const DispacherRestoGoogleMap = ({ restaurantCoordinates, deliveryBoyCoordinates }) => {
	const [selectedRestaurant, setSelectedRestaurant] = useState(null);
	const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null);

	const containerStyle = {
		height: '300px',
		width: '100%',
	};

	const handleRestaurantMouseOver = (restaurant) => {
		setSelectedRestaurant(restaurant);
	};

	const handleDeliveryBoyMouseOver = (deliveryBoy) => {
		setSelectedDeliveryBoy(deliveryBoy);
	};

	const handleMouseOut = () => {
		setSelectedRestaurant(null);
		setSelectedDeliveryBoy(null);
	};

	return (
		<div>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={restaurantCoordinates.length > 0 ? restaurantCoordinates[0] : { lat: 19.294218, lng: 73.071533 }}
				zoom={12}
			>
				{restaurantCoordinates.map((restaurant, index) => (
					<Marker
						key={`restaurant-${index}`}
						position={restaurant}
						icon={{
							url: RestaurantLogo,
							scaledSize: new window.google.maps.Size(60, 60),
						}}
						onMouseOver={() => handleRestaurantMouseOver(restaurant)}
						onMouseOut={handleMouseOut}
					>
						{selectedRestaurant === restaurant && (
							<InfoWindow onCloseClick={handleMouseOut}>
								<div className="restaurant-info">
									<div className="restaurant-info-title text-dark text-capitalize fw-bold">{restaurant.shopName ? restaurant.shopName : ""}</div>
								</div>
							</InfoWindow>
						)}
					</Marker>
				))}

				{deliveryBoyCoordinates.map((deliveryBoy, index) => (
					<Marker
						key={`delivery-boy-${index}`}
						position={deliveryBoy}
						icon={{
							url: DeliveryBoyIcon,
							scaledSize: new window.google.maps.Size(60, 60),
						}}
						onMouseOver={() => handleDeliveryBoyMouseOver(deliveryBoy)}
						onMouseOut={handleMouseOut}
					>
						{selectedDeliveryBoy === deliveryBoy && (
							<InfoWindow onCloseClick={handleMouseOut}>
								<div className="restaurant-info">
									<div className="restaurant-info-title text-dark text-capitalize fw-bold">{deliveryBoy.name ? deliveryBoy.name : ""}</div>
								</div>
							</InfoWindow>
						)}
					</Marker>
				))}
			</GoogleMap>
		</div>
	);
};

export default DispacherRestoGoogleMap;
