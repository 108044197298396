import * as actionTypes from './actions';
// import config from './../config';

const initialState = {
    adminMasterLoader: true,
    orderNotificationData: null,
    pickdropOrderNotificationData: null,
    websocketDisconnected: null,
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    //   ...config,
    accessRights: [],
    reduxCityName: null,
    reduxOrderRestaurantData: null,
    reduxOrderDeliveryBoyData: null,
    isFullScreen: false, // static can't change
    isAuthorised: false,
    sideBarOpen: false,
    reduxActiveMenuItemIndex: 0,
    adminName: '',
    adminEmail: '',
    adminCity: '',
    userId: 0,
    userType: '',
    authToken: '',
    currencyLogo: '',
    currencyCode: '',
    adminImage: null,
    orderCityName: "ALL",
    ordersRestaurantName: "ALL",
    ordersDeliveryBoyId: "ALL",
    ordersDeliveryBoyReferenceNumber: "",
    userTabKey: 'Profile',
    partnerTabKey: "Profile",
    restaurantTabKey: "Restaurant",
    restaurantMenuTabKey: "Category",
    ordersTabKey: "Pending Orders",
    chatTabKey: 'Pending',
    dashboardTabKey: 'Crisfood',
    userDisputeTabKey: 'Cancelled',
    dashboardZoneName: "ALL",
    isReduxZoneName: true,
    reduxPendingOrdersCount: null,
    reduxOngoingOrdersCount: null,
    reduxCompletedOrdersCount: null,
    reduxAcceptedOrdersCount: null,
    reduxCancelledOrdersCount: null,
    reduxScheduledOrdersCount: null,


    reduxAssignedOrdersCount: null,
    reduxProcessingOrdersCount: null,
    reduxFoodReadyOrdersCount: null,
    reduxArrivedOrdersCount: null,
    reduxReachedOrdersCount: null,
    reduxPickedupOrdersCount: null,



};

const reducer = (state = initialState, action) => {


    switch (action.type) {

        case actionTypes.CLEAR_REDUX_STATE:
            return initialState; // Reset to the initial state

        case actionTypes.ADMIN_MASTER_LOADER:
            return {
                ...state,
                adminMasterLoader: action.payload.adminMasterLoader,
            }
        case actionTypes.ORDER_NOTIFICATION_DATA:
            return {
                ...state,
                orderNotificationData: action.payload.orderNotificationData,
            }
        case actionTypes.PICKDROP_ORDER_NOTIFICATION_DATA:
            return {
                ...state,
                pickdropOrderNotificationData: action.payload.pickdropOrderNotificationData,
            }
        case actionTypes.WEBSOCKET_DISCONNECTED:
            return {
                ...state,
                websocketDisconnected: action.payload.websocketDisconnected,
            }
        case actionTypes.ADMIN_AUTHORIZED:
            return {
                ...state,
                isAuthorised: action.payload.authState,
                userId: action.payload.userId,
                adminName: action.payload.adminName,
                adminEmail: action.payload.adminEmail,
                authToken: action.payload.authToken,
                currencyLogo: action.payload.currencyLogo,
                currencyCode: action.payload.currencyCode,
                adminImage: action.payload.adminImage,
                adminCity: action.payload.adminCity,
                accessRights: action.payload.accessRights,
            }
        case actionTypes.REDUX_CITY_DATA:
            return {
                ...state,
                reduxCityName: action.payload.reduxCityName,
            }
        case actionTypes.UPDATE_ADMIN:
            return {
                ...state,
                adminName: action.payload.adminName,
                adminEmail: action.payload.adminEmail,
                adminImage: action.payload.adminImage
            }
        case actionTypes.TOGGLE_COLLAPSE:
            return {
                ...state,
                reduxActiveMenuItemIndex: state.reduxActiveMenuItemIndex === action.payload ? 0 : action.payload,
            };
        case actionTypes.SET_ACTIVE_MENU_ITEM_INDEX:
            return {
                ...state,
                reduxActiveMenuItemIndex: action.payload,
            };
        case actionTypes.UPDATE_SIDEBAR_STATUS:
            return {
                ...state,
                sideBarOpen: action.payload.sideBarOpen,
            }
        case actionTypes.ORDERED_CITY_NAME:
            return {
                ...state,
                orderCityName: action.payload.orderCityName
            }
        case actionTypes.ORDERED_RESTAURANT_NAME:
            return {
                ...state,
                ordersRestaurantName: action.payload.ordersRestaurantName
            }
        case actionTypes.PENDING_ORDERS_COUNT:
            return {
                ...state,
                reduxPendingOrdersCount: action.payload.reduxPendingOrdersCount
            }
        case actionTypes.REDUX_ORDER_DELIVERY_BOY_DATA:
            return {
                ...state,
                reduxOrderDeliveryBoyData: action.payload.reduxOrderDeliveryBoyData,
            }
        case actionTypes.REDUX_ORDER_RESTAURANT_DATA:
            return {
                ...state,
                reduxOrderRestaurantData: action.payload.reduxOrderRestaurantData,
            }
        case actionTypes.ONGOING_ORDERS_COUNT:
            return {
                ...state,
                reduxOngoingOrdersCount: action.payload.reduxOngoingOrdersCount
            }
        case actionTypes.COMPLETED_ORDERS_COUNT:
            return {
                ...state,
                reduxCompletedOrdersCount: action.payload.reduxCompletedOrdersCount
            }
        case actionTypes.ACCEPTED_ORDERS_COUNT:
            return {
                ...state,
                reduxAcceptedOrdersCount: action.payload.reduxAcceptedOrdersCount
            }
        case actionTypes.CANCELLED_ORDERS_COUNT:
            return {
                ...state,
                reduxCancelledOrdersCount: action.payload.reduxCancelledOrdersCount
            }
        case actionTypes.ASSIGNED_ORDERS_COUNT:
            return {
                ...state,
                reduxAssignedOrdersCount: action.payload.reduxAssignedOrdersCount
            }
        case actionTypes.PROCESSING_ORDERS_COUNT:
            return {
                ...state,
                reduxProcessingOrdersCount: action.payload.reduxProcessingOrdersCount
            }
        case actionTypes.FOODREADY_ORDERS_COUNT:
            return {
                ...state,
                reduxFoodReadyOrdersCount: action.payload.reduxFoodReadyOrdersCount
            }
        case actionTypes.ARRIVED_ORDERS_COUNT:
            return {
                ...state,
                reduxArrivedOrdersCount: action.payload.reduxArrivedOrdersCount
            }
        case actionTypes.REACHED_ORDERS_COUNT:
            return {
                ...state,
                reduxReachedOrdersCount: action.payload.reduxReachedOrdersCount
            }
        case actionTypes.PICKEDUP_ORDERS_COUNT:
            return {
                ...state,
                reduxPickedupOrdersCount: action.payload.reduxPickedupOrdersCount
            }
        case actionTypes.SCHEDULED_ORDERS_COUNT:
            return {
                ...state,
                reduxScheduledOrdersCount: action.payload.reduxScheduledOrdersCount
            }
        case actionTypes.ORDERED_DELIVERY_BOY_NAME:
            return {
                ...state,
                ordersDeliveryBoyId: action.payload.ordersDeliveryBoyId
            }
        case actionTypes.ORDERED_DELIVERY_BOY_REFERENCE_NUMBER:
            return {
                ...state,
                ordersDeliveryBoyReferenceNumber: action.payload.ordersDeliveryBoyReferenceNumber
            }
        case actionTypes.DASHBOARD_ZONE_NAME:
            return {
                ...state,
                dashboardZoneName: action.payload.dashboardZoneName,
                isReduxZoneName: action.payload.isReduxZoneName
            }
        case actionTypes.DASHBOARD_ZONE_NAME_AUTH:
            return {
                ...state,
                isReduxZoneName: action.payload.isReduxZoneName
            }
        case actionTypes.USER_TAB_KEY_SET:
            return {
                ...state,
                userTabKey: action.payload
            }
        case actionTypes.DELIVERYBOY_TAB_KEY_SET:
            return {
                ...state,
                partnerTabKey: action.payload
            }
        case actionTypes.RESTAURANT_TAB_KEY_SET:
            return {
                ...state,
                restaurantTabKey: action.payload
            }
        case actionTypes.RESTAURANT_MENU_TAB_KEY_SET:
            return {
                ...state,
                restaurantMenuTabKey: action.payload
            }
        case actionTypes.ORDERS_TAB_KEY_SET:
            return {
                ...state,
                ordersTabKey: action.payload
            }
        case actionTypes.CHAT_TAB_KEY_SET:
            return {
                ...state,
                chatTabKey: action.payload
            }
        case actionTypes.DASHBOARD_TAB_KEY_SET:
            return {
                ...state,
                dashboardTabKey: action.payload
            }
        case actionTypes.USER_DISPUTE_TAB_KEY_SET:
            return {
                ...state,
                userDisputeTabKey: action.payload
            }

        default:
            return state;
    }
};

export default reducer;