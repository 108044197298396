import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ErrorImage from '../../assets/images/eroor-page.png'
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import navigation from '../../menu-items';
import routes from '../../Routes/Routes';
// import PrivateRoute from '../../Routes/Routes';

const Error404Page = ({ isAccessRights }) => {

    // ---------- usenavigation to navigate page ---------
    const navigate = useNavigate();

    // ------------------------ Handle Cancel ----------------------
    const homePage = () => {
        //------------- Finding the first matching route path based on module_key -----------//
        const userAccessRights = isAccessRights;
        const accessRightsMap = new Map();

        // Create a map for access rights
        userAccessRights.forEach((right) => {
            accessRightsMap.set(right.module_key, right);
        });
        // console.log("Access Rights Map:", accessRightsMap);

        // Flatten the navigation structure
        const flattenNavigation = (items) => {
            let flatList = [];
            items.forEach((item) => {
                if (item.isChildren && item.children) {
                    flatList.push(...flattenNavigation(item.children));
                } else {
                    flatList.push(item);
                }
            });
            return flatList;
        };
        const flattenedItems = flattenNavigation(navigation.superItems);
        // console.log("Flattened Items:", flattenedItems);

        // Filter navigation items based on access rights and preserve original order
        const accessibleItems = flattenedItems.filter((item) => {
            const hasAccess = item.routerKey && accessRightsMap.has(item.routerKey);
            if (item.routerKey && !accessRightsMap.has(item.routerKey)) {
                // console.log(`No access for routerKey: ${item.routerKey}`);
            }
            return hasAccess;
        });
        // console.log("Accessible Items:", accessibleItems);

        // Check if accessible items match any superitem paths
        const accessibleSuperitem = accessibleItems.find((item) => accessRightsMap.has(item.routerKey));
        // console.log("Accessible Superitem:", accessibleSuperitem);

        if (accessibleSuperitem) {
            // console.log("Navigating to Superitem URL:", accessibleSuperitem.url);
            navigate(accessibleSuperitem.url);
        } else {
            // Check if accessible items match any route paths
            const accessibleRoute = routes.find((route) =>
                userAccessRights.some((right) => right.module_key === route.routerKey)
            );
            // console.log("Accessible Route:", accessibleRoute);
            if (userAccessRights.length === 0) {
                console.log("No access rights found, redirecting to contact-admin");
                navigate('/contact-admin');
            } else if (accessibleRoute) {
                console.log("Navigating to:", accessibleRoute.path);
                navigate(accessibleRoute.path);
            } else {
                console.log("No matching route found, redirecting to contact-admin");
                navigate('/contact-admin');
            }
        }

    }
    return (
        <div style={{
            backgroundColor: '#e6f5f5', height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <div style={{ height: '400px', width: '450px', objectFit: 'cover', overflow: 'hidden' }}>
                    <LazyLoadImage src={ErrorImage} style={{ height: '100%', width: '100%', backgroundColor: 'transparent' }} />
                </div>
                <Button className='mt-3 btn-orange' onClick={homePage}>Home Page</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAccessRights: state.accessRights,
    };
};

export default connect(mapStateToProps, null)(Error404Page);



// Finding the first matching route path based on module_key
// let accessRights = isAccessRights
// // Check if accessRights is defined and not empty
// if (accessRights && accessRights.length > 0) {
//     const firstAccessibleModuleKey = accessRights[0].module_key;
//     // Finding route with matching routerKey
//     const matchedRoute = PrivateRoute.find(route => route.routerKey === firstAccessibleModuleKey);
//     const routePath = matchedRoute ? matchedRoute.path : '/dashboard';
//     navigate(routePath);
// } else {
//     // Navigate to the "Contact Admin" page
//     navigate('/contact-admin');
// }

// // // // Finding the first matching route path based on module_key
// let accessRights = isAccessRights;
// // Create a map for access rights
// const accessRightsMap = new Map();
// accessRights && accessRights.forEach((right) => {
//     accessRightsMap.set(right.module_key, right);
// });

// // Function to flatten the navigation structure
// const flattenNavigation = (items) => {
//     let flatList = [];
//     items.forEach(item => {
//         if (item.isChildren && item.children) {
//             flatList.push(...flattenNavigation(item.children));
//         } else {
//             flatList.push(item);
//         }
//     });
//     return flatList;
// };
// // Flatten the superItems list
// const flattenedItems = flattenNavigation(navigation.superItems);

// // Filter navigation items based on access rights and preserve original order
// const accessibleItems = flattenedItems.filter(item =>
//     item.routerKey && accessRightsMap.has(item.routerKey)
// );

// // Navigate to the URL of the first accessible item
// if (accessibleItems.length > 0) {
//     const firstItem = accessibleItems[0];
//     // console.log("Navigating to:", firstItem.url);
//     navigate(firstItem.url);
// } else {
//     navigate('/contact-admin');
// }