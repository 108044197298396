import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../ReduxManager/actions";

const ContactAdmin = ({ adminMasterLoader }) => {

    useEffect(() => {
        adminMasterLoader(false)
    }, [])
    return (
        <div>
            <h1>Access Denied</h1>
            <p>You do not have any access rights. Please contact the admin for assistance.</p>
        </div>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        adminMasterLoader: (adminmasterloader) => dispatch({ type: actionTypes.ADMIN_MASTER_LOADER, payload: { adminMasterLoader: adminmasterloader } }),

    }
};


export default connect(null, mapDispatchToProps)(ContactAdmin)
