import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import Sidebar from '../Sidebar'
import navigation from '../../menu-items';
import ProfileEditPage from './ProfileEditPage';
import { connect, useDispatch } from 'react-redux';
import * as actionTypes from '../../ReduxManager/actions'
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AdminMasterLoader from '../../assets/images/gif/admin-master-loader.gif'


const MainLayout = ({ adminAuthtoken, onAdminLogout,
	dispatchSidebarCollapse, reduxSideBarOpen, reduxMasterLoader }) => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	/*-------------------------------------------------------------
			Add ProfileEdit Modal States
---------------------------------------------------------------*/
	const [tableRowData, setTableRowData] = useState([])
	const [showProfileEdit, setShowProfileEdit] = useState(false);
	const handleCloseProfileEdit = () => setShowProfileEdit(false);
	const handleShowProfileEdit = () => {
		setShowProfileEdit(true);
	}

	// ------------------------- Logout When Token Is Expire ---------------------
	useEffect(() => {
		const tokenExpiry = new Date(adminAuthtoken);
		const currentDate = new Date();

		console.log("tokenExpiration =>", tokenExpiry);
		console.log("currentDate =>", currentDate);

		if (tokenExpiry > currentDate) {
			// console.log('Token is still valid.');
		} else {
			onAdminLogout(false, '', '',); // You may need to provide the appropriate arguments here
			dispatch({ type: actionTypes.CLEAR_REDUX_STATE });
			navigate('/login');
			// console.log('Token has expired.');
		}
	}, [navigate]);

	// ---------------------------- Side Bar Mobile Active State OR Function ---------------
	const [toggelMobileActive, setToggelMobileActive] = useState(false);
	const [sideBardOpen, setSideBardOpen] = useState(reduxSideBarOpen);

	const handleSideBardOpen = () => {
		setSideBardOpen(!sideBardOpen)
		dispatchSidebarCollapse(!sideBardOpen)
	}

	useEffect(() => {
		setSideBardOpen(reduxSideBarOpen)
	}, [])

	const handlesetMobileToggelActive = () => {
		setToggelMobileActive(!toggelMobileActive)
	}



	return (
		<div>
			{
				reduxMasterLoader &&
				<div className='admin-master-loader'>
					<LazyLoadImage src={AdminMasterLoader} />
				</div>
			}
			<div className={`container-scroller ${sideBardOpen === true ? "sidebar-icon-only" : toggelMobileActive === true ? "sidebar-mobile-screen" : ""}`}>
				<Header
					sideBardOpen={sideBardOpen}
					handleSideBardOpen={handleSideBardOpen}
					handlesetMobileToggelActive={handlesetMobileToggelActive}
					handleShowProfileEdit={handleShowProfileEdit}
					setTableRowData={setTableRowData}
				/>
				<div className="container-fluid page-body-wrapper">
					<div className={toggelMobileActive === true ? 'mobile-navbar-hide-show' : ""}
						onClick={handlesetMobileToggelActive}
					>
					</div>
					<Sidebar navigation={navigation}
						handleSideBardOpen={handleSideBardOpen}
						toggelMobileActive={toggelMobileActive}
						handlesetMobileToggelActive={handlesetMobileToggelActive}
						handleShowProfileEdit={handleShowProfileEdit}
					/>

					{showProfileEdit && <ProfileEditPage
						showProfileEdit={showProfileEdit}
						handleCloseProfileEdit={handleCloseProfileEdit}
						tableRowData={tableRowData}
					/>}

					<div className='main-panel'>
						<div className='content-wrapper'>
							<Outlet />
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}




const mapStateToProps = state => {
	return {

		reduxAdminUserId: state.userId,
		adminAuthtoken: state.authToken,
		reduxSideBarOpen: state.sideBarOpen,
		reduxMasterLoader: state.adminMasterLoader

	}
};

const mapDispatchToProps = dispatch => {
	return {
		onAdminLogout: (authstate, userId, authtoken) => dispatch({ type: actionTypes.ADMIN_AUTHORIZED, payload: { authState: authstate, userId: userId, authToken: authtoken } }),
		dispatchSidebarCollapse: (sidebaropen) => dispatch({ type: actionTypes.UPDATE_SIDEBAR_STATUS, payload: { sideBarOpen: sidebaropen } }),

	}
};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);



