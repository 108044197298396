import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionTypes from "../../ReduxManager/actions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CrissFoodLogo from "../../assets/images/svg/crisfood_logo_text.svg";
import CrissFoodImage from "../../assets/images/svg/crisfood_digiverse_crisfood.svg";
import HamburgerMenuIcon from "../../assets/images/svg/icon_navbar_hamberger.svg";
import InactiveLogsIcon from "../../assets/images/svg/icon_navbar_inactive_book.svg";
import ArrowDownIcon from "../../assets/images/svg/icon_arrow_down.svg";
import DummyProfileImage from "../../assets/images/dummy_avtar.png";
import EditProfileIcon from "../../assets/images/png/icons8-edit-profile-96.png";
import LogOutIcon from "../../assets/images/png/icons8-logout-96.png";
import Dropdown from "react-bootstrap/Dropdown";
import config from "../../config";
import { imageOnErrorHandler } from "../../Utils/imageErrorHandler";
import { checkImageUrl } from "../../Utils/checkImageUrl";
import GlobalOrderSearch from "../../Components/GlobalComponents/GlobalOrderSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { io } from "socket.io-client";
import { mapOrderStatusToTabKey } from "../../Utils/OrderStatusDataForSocket";

const Header = ({
  onAdminLogout,
  sideBardOpen,
  handleSideBardOpen = () => { },
  handleShowProfileEdit = () => { },
  handlesetMobileToggelActive = () => { },
  setTableRowData,
  adminEmail,
  adminName,
  reduxAdminImage,
  isAccessRights,
  // reduxSocketError,
  dispatchOrderNotificationData = () => { },
  dispatchPickDropOrderNotificationData = () => { },
  reduxActiveTabKey,
  dispatchWebSocketDisconnected = () => { }
}) => {
  // console.log("reduxSocketError>>>>>>>>>>", reduxSocketError);

  // Get the routerKey for the current path
  const addRouterKey = "GLOBAL_ORDER_SEARCH";
  // Find the access rights object for the current routerKey
  const addAccessRight =
    isAccessRights &&
    isAccessRights.find((right) => right.module_key === addRouterKey);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const profileCardRef = useRef(null);

  // ------------------- access rights state ----------------------
  const [accessRightsMap, setAccessRightsMap] = useState(new Map());

  // ------------------- profile opne ----------------------
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [profileButtonClicked, setProfileButtonClicked] = useState(false);

  // ------------------- toggle profile  ----------------------------
  const toggleProfile = () => {
    setProfileButtonClicked(!profileButtonClicked);
    setIsProfileOpen(!isProfileOpen);
  };

  // ------------------- access rights set ----------------------------
  useEffect(() => {
    // Create a map for access rights from the provided `accessRights` array
    const map = new Map();
    isAccessRights &&
      isAccessRights.forEach((right) => {
        map.set(right.module_key, right);
      });
    setAccessRightsMap(map);
  }, [isAccessRights]);

  //-----------------------------------
  // Admin Logout functionality
  //--------------------------------------
  const adminLogout = () => {
    onAdminLogout(false, "", "");
    console.log("admin logout functions");
    // Clear the entire Redux state
    dispatch({ type: actionTypes.CLEAR_REDUX_STATE });
    navigate("/login");
  };

  const handleClickOutside = (event) => {
    // event.priventdifu()
    // event.stopPropagation();
    // console.log("==>", profileCardRef.current);
    // if (
    // 	profileCardRef.current &&
    // 	!profileCardRef.current.contains(event.target) &&
    // 	!profileButtonClicked // Check if the profile button was not clicked
    // ) {
    // 	setIsProfileOpen(false);
    // }
  };
  // const handleClickOutside = (event) => {
  // 	if (!profileCardRef.current) return;

  // 	// Check if the event target is not the profile card itself or a descendant of the profile card.
  // 	if (!profileCardRef.current.contains(event.target)) {
  // 	  setIsProfileOpen(false);
  // 	}
  //   };

  useEffect(() => {
    if (isProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileOpen, profileButtonClicked]);



  /////////////////////////  SOCKET  \\\\\\\\\\\\\\\\\\\\\\\\\\\
  const [isSocketError, setIsSocketError] = useState(false);


  useEffect(() => {
    let socket;
    // const socket = io(`http://testadminsocket.crisfood.com`);
    if (config.adminEnv == "PRODUCTION") {
      console.log("PRODUCTION====>");
      socket = io(`${config.dispatcherWebSocketUrl}`, {
        path: "/adminSocket",
        transports: ["websocket"],
        secure: true,// reconnectionAttempts: 5, // Retry 5 times on failure
      });
    } else {
      console.log("TEST====>");
      socket = io(`${config.dispatcherWebSocketUrl}`);
    }
    console.log("WebSocket connected");
    let intervalId;
    // Listen for the ORDERED_NOTIFICATIONS event
    socket.on("connect", () => {
      setIsSocketError(false);
      dispatchWebSocketDisconnected(null);
      // props.showSocketError(false)
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    })
    socket.on("ORDERED_NOTIFICATIONS", (data) => {
      console.log("Received notification:", data);
      if (data.order_type === "shop") {
        const newNotification = {
          date: new Date().toLocaleString(),
          status: data.status,
          dispute: data.dispute,
          out_of_stock: data.out_of_stock,
          order_id: parseInt(data.order_id),
          order_type: data.order_type
        };
        console.log("header_shop_notificatio_log", newNotification);
        dispatchOrderNotificationData(newNotification);
        dispatchPickDropOrderNotificationData(null)
      }
      if (data.order_type === "pickdrop") {
        const newNotification = {
          date: new Date().toLocaleString(),
          status: data.status,
          dispute: data.dispute,
          out_of_stock: data.out_of_stock,
          order_id: data.order_id,
          order_type: data.order_type
        };
        console.log("header_pickdrop_notificatio_log", newNotification);
        dispatchPickDropOrderNotificationData(newNotification);
        dispatchOrderNotificationData(null)
      }

    });
    const handleSocketError = () => {
      console.log("WebSocket error occurred. Starting API requests every second.");
      // toast.error("WebSocket disconnected!");
      setIsSocketError(true);
      if (!intervalId) {
        intervalId = setInterval(() => {
          console.log("inside intervalId", intervalId);
          dispatchWebSocketDisconnected(new Date())
        }, 15000);
      }
    };
    socket.on("connect_error", handleSocketError);
    socket.on("error", handleSocketError);
    socket.on("disconnect", () => {
      console.log("WebSocket disconnected. Stopping API calls.");
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    });
    return () => {
      console.log("Component unmounting, disconnecting WebSocket");
      socket.disconnect();
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [reduxActiveTabKey]);





  return (
    <nav className="navbar default-layout-navbar">
      <div className="text-center navbar-brand-wrapper">
        <a href="#" className="navbar-header brand-logo">
          {sideBardOpen === true ? (
            <LazyLoadImage
              src={CrissFoodImage}
              alt="navbar-logo"
              className="small-logo"
            />
          ) : (
            <LazyLoadImage
              src={CrissFoodLogo}
              alt="navbar-logo"
              className="big-logo"
            />
          )}
        </a>
        <a className="navbar-header brand-logo-mini" href="#">
          <LazyLoadImage src={CrissFoodImage} alt="navbar-logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper">
        <div className="navbar-right-wrapper d-flex justify-content-between align-items-center">
          <div className="navbar-search-hamburger">
            <LazyLoadImage
              src={HamburgerMenuIcon}
              alt="HamburgerMenuIcon"
              className="me-3 hamburger-menu-icon"
              onClick={handleSideBardOpen}
            />
            {addAccessRight && <GlobalOrderSearch />}
          </div>
          <div className="navbar-profile-side-container">
            <ul>
              {isSocketError ? (
                <li>
                  <div className="side-container side-container-websocket d-flex gap-2">
                    <FontAwesomeIcon icon={faBullhorn} />
                    <div className="side-container-text"> Socket Disconnected</div>
                  </div>
                </li>
              ) : (
                ""
              )}
              <li>
                <div className="side-container">
                  <LazyLoadImage
                    src={InactiveLogsIcon}
                    alt="InactiveLogsIcon"
                  />
                  <div className="side-container-text">Logs</div>
                </div>
              </li>
              <li>
                <LazyLoadImage
                  src={HamburgerMenuIcon}
                  alt="HamburgerMenuIcon"
                  className="me-2 profile-hamburger-menu-icon"
                  onClick={handlesetMobileToggelActive}
                />
              </li>
              <li>
                <div className="custom-dopdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div
                        className="profile-side-container"
                        onClick={toggleProfile}
                      >
                        <div className="header-profile-image">
                          {/* <LazyLoadImage src={reduxAdminImage ? `${config.serverImageUrl}${reduxAdminImage}` : DummyProfileImage}
														onError={imageOnErrorHandler} /> */}
                          <LazyLoadImage
                            src={
                              reduxAdminImage && reduxAdminImage
                                ? checkImageUrl(reduxAdminImage) === "Full URL"
                                  ? reduxAdminImage
                                  : `${config.serverImageUrl}${reduxAdminImage}`
                                : DummyProfileImage
                            }
                            onError={imageOnErrorHandler}
                          />
                        </div>
                        <div className="header-admin-name-text">
                          {adminName}
                        </div>
                        <LazyLoadImage
                          src={ArrowDownIcon}
                          alt="ArrowDownIcon"
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <div className="profile-card">
                          <div className="inside-profile-card">
                            <LazyLoadImage
                              src={
                                reduxAdminImage && reduxAdminImage
                                  ? checkImageUrl(reduxAdminImage) ===
                                    "Full URL"
                                    ? reduxAdminImage
                                    : `${config.serverImageUrl}${reduxAdminImage}`
                                  : DummyProfileImage
                              }
                              onError={imageOnErrorHandler}
                            />
                          </div>
                          <div className="profile-image-card">
                            <div className="profile-card-text">{adminName}</div>
                            <div className="profile-card-text gmail-text">
                              {adminEmail}
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="#" onClick={handleShowProfileEdit}>
                        <div className="profile-card">
                          <LazyLoadImage src={EditProfileIcon} alt="" />
                          <div className="profile-card-text">Edit Profile</div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className="profile-card last-child"
                          onClick={adminLogout}
                        >
                          <LazyLoadImage src={LogOutIcon} alt="" />
                          <div className="profile-card-text">Logout</div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxActiveTabKey: state.ordersTabKey,
    reduxAdminUserId: state.userId,
    adminName: state.adminName,
    adminEmail: state.adminEmail,
    reduxAdminImage: state.adminImage,
    isAccessRights: state.accessRights,
    // reduxSocketError: state.socketError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdminLogout: (authstate, userId) =>
      dispatch({
        type: actionTypes.ADMIN_AUTHORIZED,
        payload: { authState: authstate, userId: userId },
      }),
    dispatchOrderNotificationData: (notificationdata) => dispatch({ type: actionTypes.ORDER_NOTIFICATION_DATA, payload: { orderNotificationData: notificationdata } }),
    dispatchPickDropOrderNotificationData: (pickdropnotificationdata) => dispatch({ type: actionTypes.PICKDROP_ORDER_NOTIFICATION_DATA, payload: { pickdropOrderNotificationData: pickdropnotificationdata } }),
    dispatchWebSocketDisconnected: (websocketdisconnected) => dispatch({ type: actionTypes.WEBSOCKET_DISCONNECTED, payload: { websocketDisconnected: websocketdisconnected } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
