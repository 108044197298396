import ActiveSideBarSetting from "../src/assets/images/svg/sidebar/icon_sidebar_active_setting.svg";
import InActiveSideBarSetting from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_setting.svg";

import ActiveAccessControlIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_profileuser.svg";
import InactiveAccessControlIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_profileuser.svg";

import ActiveWalletOffersIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_wallet_offers.svg";
import InactiveWalletOffersIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_wallet_offers.svg";

import ActiveWebsiteOffers from "../src/assets/images/svg/sidebar/icon_sidebar_active_website_offer.svg";
import InactiveWebsiteOffers from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_website_offer.svg";

// import ActiveResturantBanners from "../src/assets/images/svg/sidebar/icon_sidebar_active_resturant_banners.svg";
// import InactiveResturantBanners from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_resturant_banners.svg";

import InactiveSideBarDispacher from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_dispacher.svg";
import ActiveSideBarDispacher from "../src/assets/images/svg/sidebar/icon_sidebar_active_dispacher.svg";

import InactiveSideBarAnnouncement from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_wallet_microphone.svg";
import ActiveSideBarAnnouncement from "../src/assets/images/svg/sidebar/icon_sidebar_active_wallet_microphone.svg";

import InactiveSideBarCuisines from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_cuisines.svg";
import ActiveSideBarCuisines from "../src/assets/images/svg/sidebar/icon_sidebar_active_cuisines.svg";

import InactiveSideDeliveryPartner from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_delivery_partner.svg";
import ActiveSideDeliveryPartner from "../src/assets/images/svg/sidebar/icon_sidebar_active_delivery_partner.svg";

import InactiveCityManager from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_city_manager.svg";
import ActiveCityManager from "../src/assets/images/svg/sidebar/icon_sidebar_active_city_manager.svg";

import InactiveSideRestaurant from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_create_resturant.svg";
import ActiveSideRestaurant from "../src/assets/images/svg/sidebar/icon_sidebar_active_create_resturant.svg";

import InactiveInfoPagesIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_info_pages.svg";
import ActiveInfoPagesIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_info_pages.svg";

import InactiveSideBarDeliveries from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_deliveries.svg";
import ActiveSideBarDeliveries from "../src/assets/images/svg/sidebar/icon_sidebar_active_deliveries.svg";

import InactiveSideBarSpaceDashboard from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_space_dashboard.svg";
import ActiveSideBarSpaceDashboard from "../src/assets/images/svg/sidebar/icon_sidebar_active_space_dashboard.svg";

import ActivePromocodesIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_promocodes.svg";
import InactivePromocodesIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_promocodes.svg";

import ActiveSubscriptionPlans from "../src/assets/images/svg/sidebar/icon_sidebar_active_subscription.svg";
import InactiveSubscriptionPlans from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_subscription.svg";

import ActivePaymentIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_payment_settings.svg";
import InactivePaymentIcon from "../src/assets/images/svg/icon_navbar_inactive_payment.svg";

import ActiveEmailTemplateIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_email_template.svg";
import InactiveEmailTemplateIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_email_template.svg";

import ActiveTranslationIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_translation.svg";
import InactiveTranslationIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_translation.svg";

import ActiveForceupdateIcon from "../src/assets/images/svg/icon_navbar_active_forceupdate.svg";
import InactiveForceupdateIcon from "../src/assets/images/svg/icon_navbar_inactive_forceupdate.svg";

import ActivePickDropIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_pick_drop_setting.svg";
import InactivePickDropIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_pick_drop_setting.svg";


import InactiveUserDisputeIcon from "../src/assets/images/svg/sidebar/icon_sidebar_inactive_dispute.svg";
import ActiveUserDisputeIcon from "../src/assets/images/svg/sidebar/icon_sidebar_active_dispute.svg";

export default {

  superItems: [
    // {
    //   id: 'Dashboard',
    //   title: 'Dashboard',
    //   type: 'group',
    //   url: '/dashboard',
    //   inactive_icon: <InactiveSideBarSpaceDashboard />,
    //   active_icon: <ActiveSideBarSpaceDashboard />,
    //   isChildren: false,
    //   routerKey: 'DASHBOARD',
    // },
    {
      id: 'Dispacher',
      title: 'Dispatcher',
      type: 'group',
      url: '/dispacher-list',
      inactive_icon: <InactiveSideBarDispacher />,
      active_icon: <ActiveSideBarDispacher />,
      isChildren: false,
      routerKey: 'DISPATCHER_LIST',
    },
    {
      id: 'AdminChat',
      title: 'Admin Chat',
      type: 'group',
      url: '/admin-chat',
      inactive_icon: <InactiveWalletOffersIcon />,
      active_icon: <ActiveWalletOffersIcon />,
      isChildren: false,
      routerKey: 'ADMIN_CHAT',

    },
    {
      id: 'Disputes',
      title: 'Disputes',
      type: 'group',
      inactive_icon: <InactiveUserDisputeIcon />,
      active_icon: <ActiveUserDisputeIcon />,
      isChildren: true,
      children: [
        {
          id: 'CrisfoodDispute',
          title: 'Crisfood Dispute',
          type: 'item',
          url: '/crisfood-dispute',
          routerKey: 'USER_DISPUTE',
        },
        {
          id: 'PickDropDispute',
          title: 'PickDrop Dispute',
          type: 'item',
          url: '/pickDrop-dispute',
          routerKey: 'USER_DISPUTE',

        },
      ]
    },

    {
      id: 'Restaurant',
      title: 'Restaurant',
      type: 'group',
      // url: '/delivery-partner',
      inactive_icon: <InactiveSideRestaurant />,
      active_icon: <ActiveSideRestaurant />,
      isChildren: true,

      children: [
        {
          id: 'AddRestaurant',
          title: 'Add Restaurant',
          type: 'item',
          url: '/add-restaurant',
          routerKey: 'ADD_RESTAURANT',

        },
        {
          id: 'RestaurantList',
          title: 'Restaurant List',
          type: 'item',
          url: '/restaurant-list',
          routerKey: 'RESTAURANT_LIST',

        },
        {
          id: 'ResturantBanners',
          title: 'Resturant Banners',
          type: 'item',
          url: '/resturant-banners',
          routerKey: 'RESTAURANT_BANNERS',

        },
        {
          id: 'RestaurantListingRequest',
          title: 'Restaurant Request',
          type: 'item',
          url: '/restaurantListingRequest',
          routerKey: 'RESTAURANT_LISTING_REQUEST',

        },
        {
          id: 'RestaurantReviews',
          title: 'Restaurant Reviews',
          type: 'item',
          url: '/restaurant-reviews',
          // routerKey: 'RESTAURANT_REVIEWS',
          routerKey: 'RESTAURANT_BANNERS',

        },
        {
          id: 'RestaurantTimingLogs',
          title: 'Timing Logs',
          type: 'item',
          url: '/restaurant-timing-logs',
          // routerKey: 'RESTAURANT_TIMING_LOGS',
          routerKey: 'RESTAURANT_BANNERS',
        },
        {
          id: 'Cuisines',
          title: 'Cuisines',
          type: 'item',
          url: '/cuisines',
          routerKey: 'CUISINES',

        },
      ]
    },
    {
      id: 'DeliveryPartner',
      title: 'Delivery Partner',
      type: 'group',
      inactive_icon: <InactiveSideDeliveryPartner />,
      active_icon: <ActiveSideDeliveryPartner />,
      isChildren: true,
      children: [
        {
          id: 'AddDeliveryPartner',
          title: 'Add Delivery Partner',
          type: 'item',
          url: '/add-delivery-boy',
          routerKey: 'ADD_DELIVERY_BOY',
        },
        {
          id: 'DeliveyPartnerList',
          title: 'Delivey Partner List',
          type: 'item',
          url: '/delivey-partner-list',
          routerKey: 'DELIVERY_PARTNER_LIST',
        },
        {
          id: 'AddHandover',
          title: 'Add Handover',
          type: 'item',
          url: '/add-handover',
          routerKey: 'ADD_HANDOVER',

        },
        {
          id: 'JobCandidate',
          title: 'Job Candidate',
          type: 'item',
          url: '/job-candidate',
          routerKey: 'JOB_CANDIDATE',

        },
      ]
    },
    {
      id: 'User',
      title: 'User',
      type: 'group',
      inactive_icon: <InactiveAccessControlIcon />,
      active_icon: <ActiveAccessControlIcon />,
      // url: '/',
      isChildren: true,
      children: [
        {
          id: 'UserList',
          title: 'User List',
          type: 'item',
          url: '/user-list',
          routerKey: 'USER_LIST',
        },
        {
          id: 'UserWalletRecord',
          title: 'User Wallet Record',
          type: 'item',
          url: '/user-wallet-record',
          routerKey: 'USER_LIST',
        },
      ]
    },
    {
      id: 'Deliveries',
      title: 'Deliveries',
      type: 'group',
      // url: '/delivery-partner',
      inactive_icon: <InactiveSideBarDeliveries />,
      active_icon: <ActiveSideBarDeliveries />,
      isChildren: true,
      children: [
        {
          id: 'Crisfood',
          title: 'Crisfood',
          type: 'item',
          url: '/crisfood-deliveries',
          routerKey: 'CRISFOOD_DELIVERIES',
        },
        {
          id: 'PickDrop',
          title: 'Pick-Drop',
          type: 'item',
          url: '/flip-deliveries',
          routerKey: 'FLIP_DELIVERIES',
        },
      ]
    },
    {
      id: 'PromoCodes',
      title: 'Promocodes',
      type: 'group',
      inactive_icon: <InactivePromocodesIcon />,
      active_icon: <ActivePromocodesIcon />,
      isChildren: true,
      children: [
        {
          id: 'PromocodesList',
          title: 'Promocodes List',
          type: 'item',
          url: '/promocodes-list',
          routerKey: 'PROMOCODES',

        },
        {
          id: 'PromoBanner',
          title: 'Promo Banners',
          type: 'item',
          url: '/promo-banners',
          routerKey: 'PROMO_BANNER',

        },
      ]
    },
    {
      id: 'subscriptions',
      title: 'Subscriptions',
      type: 'group',
      // url: '/delivery-partner',
      inactive_icon: <InactiveSubscriptionPlans />,
      active_icon: <ActiveSubscriptionPlans />,
      isChildren: true,
      children: [
        {
          id: 'SubscriptionPlans',
          title: 'Subscription Plans',
          type: 'item',
          url: '/subscription-plans',
          routerKey: 'SUBSCRIPTION_PLANS',

        },
        {
          id: 'SubscriptionBenefits',
          title: 'Subscription Benefits',
          type: 'item',
          url: '/subscription-benefits',
          routerKey: 'SUBSCRIPTION_BENEFITS',

        },
        {
          id: 'SubscriptionHistory',
          title: 'Subscription History',
          type: 'item',
          url: '/subscription-history',
          routerKey: 'SUBSCRIPTION_HISTORY',

        },

      ]
    },
    {
      id: 'WalletOffers',
      title: 'Wallet Offers',
      type: 'group',
      url: '/wallet-offers',
      inactive_icon: <InactiveWalletOffersIcon />,
      active_icon: <ActiveWalletOffersIcon />,
      isChildren: false,
      routerKey: 'WALLET_OFFERS',

    },
    {
      id: 'Announcement',
      title: 'Announcement',
      type: 'group',
      url: '/announcement',
      inactive_icon: <InactiveSideBarAnnouncement />,
      active_icon: <ActiveSideBarAnnouncement />,
      isChildren: false,
      routerKey: 'ANNOUNCEMENT',

    },
    {
      id: 'Website',
      title: 'Website',
      type: 'group',
      inactive_icon: <InactiveWebsiteOffers />,
      active_icon: <ActiveWebsiteOffers />,
      isChildren: true,

      children: [
        {
          id: 'WebsiteOffers',
          title: 'Website Offers',
          type: 'item',
          url: '/website-offers',
          routerKey: 'WEBSITE_OFFERS',

        },
        {
          id: 'WebsiteAds',
          title: 'Website Ads',
          type: 'item',
          url: '/website-ads',
          routerKey: 'WEBSITE_ADS',

        },
      ]
    },
    {
      id: 'InfoPages',
      title: 'Info Pages',
      type: 'group',
      inactive_icon: <InactiveInfoPagesIcon />,
      active_icon: <ActiveInfoPagesIcon />,
      isChildren: true,
      // routerKey: 'INFO_PAGES',
      children: [
        {
          id: 'AboutUs',
          title: 'About Us',
          type: 'item',
          url: '/about-page',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'ContactUs',
          title: 'Contact Us',
          type: 'item',
          url: '/contact-page',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'PrivacyPolicy',
          title: 'Privacy Policy',
          type: 'item',
          url: '/privacy-policy',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'TermsCondition',
          title: 'Terms And Condition',
          type: 'item',
          url: '/terms-conditions',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'CancellationAndRefund',
          title: 'Cancellation And Refund',
          type: 'item',
          url: '/cancellation-refund',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'PrivacyPolicyRestaurant',
          title: 'Restaurant Privacy Policy',
          type: 'item',
          url: '/restaurant-privacy-policy',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'TermsConditionRestaurant',
          title: 'Restaurant Terms And Condition',
          type: 'item',
          url: '/restaurant-terms-conditions',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'PrivacyPolicyDelivery',
          title: 'Delivery Privacy Policy',
          type: 'item',
          url: '/delivery-privacy-policy',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'TermsConditionDelivery',
          title: 'Delivery Terms And Condition',
          type: 'item',
          url: '/delivery-terms-conditions',
          routerKey: 'INFO_PAGES',

        },
        {
          id: 'FAQs',
          title: 'FAQ',
          type: 'item',
          url: '/frequently-asked-questions',
          routerKey: 'INFO_PAGES',

        },
      ]
    },
    {
      id: 'EmailTemplate',
      title: 'Email Template',
      type: 'group',
      // url: '/admin-chat',
      inactive_icon: <InactiveEmailTemplateIcon />,
      active_icon: <ActiveEmailTemplateIcon />,
      isChildren: false,
      routerKey: 'EMAIL_TEMPLATE',

    },
    {
      id: 'Translation',
      title: 'Translation',
      type: 'group',
      // url: '/admin-chat',
      inactive_icon: <InactiveTranslationIcon />,
      active_icon: <ActiveTranslationIcon />,
      isChildren: false,
      routerKey: 'TRANSLATION',

    },
    {
      id: 'ForceUpdate',
      title: 'Force Update',
      type: 'group',
      url: '/force-update',
      inactive_icon: <InactiveForceupdateIcon />,
      active_icon: <ActiveForceupdateIcon />,
      isChildren: false,
      routerKey: 'FORCE_UPDATE',

    },
    {
      id: 'cityManager',
      title: 'City Manager',
      type: 'group',
      url: '/city-manager',
      inactive_icon: <InactiveCityManager />,
      active_icon: <ActiveCityManager />,
      isChildren: false,
      routerKey: 'CITY_MANAGER',

    },

    // {
    //   id: 'Cuisines',
    //   title: 'Cuisines',
    //   type: 'group',
    //   url: '/cuisines',
    //   inactive_icon: <InactiveSideBarCuisines />,
    //   active_icon: <ActiveSideBarCuisines />,
    //   isChildren: false,
    //   routerKey: 'CUISINES',

    // },
    {
      id: 'PaymentSettings',
      title: 'Payment Settings',
      type: 'group',
      inactive_icon: <InactivePaymentIcon />,
      active_icon: <ActivePaymentIcon />,
      isChildren: true,

      children: [
        {
          id: 'PaymentOptions',
          title: 'Payment Options',
          type: 'item',
          url: '/payment-options',
          routerKey: 'PAYMENT_SETTINGS',

        },
        {
          id: 'PaymentGateway',
          title: 'Payment Gateway',
          type: 'item',
          url: '/payment-gateway',
          routerKey: 'PAYMENT_SETTINGS',

        },
        {
          id: 'PaymentActiveMethod',
          title: 'Payment Method',
          type: 'item',
          url: '/payment-active-method',
          routerKey: 'PAYMENT_SETTINGS',

        },
      ]
    },
    {
      id: 'AccessControl ',
      title: 'Access Control ',
      type: 'group',
      inactive_icon: <InactiveAccessControlIcon />,
      active_icon: <ActiveAccessControlIcon />,
      isChildren: true,
      children: [
        {
          id: 'EmployeeAccess',
          title: 'Employee Access',
          type: 'item',
          url: '/access-control',
          routerKey: 'ACCESS_CONTROL',

        },
        {
          id: 'EmployeeRole',
          title: 'Employee Role',
          type: 'item',
          url: '/employee-role',
          routerKey: 'EMPLOYEE_ROLE',

        }
      ]
    },
    {
      id: 'Setting',
      title: 'Setting',
      type: 'group',
      inactive_icon: <InActiveSideBarSetting />,
      active_icon: <ActiveSideBarSetting />,
      // url: '/',
      isChildren: true,
      children: [
        {
          id: 'PlatformFees',
          title: 'Platform Fees',
          type: 'item',
          url: '/platform-fees',
          routerKey: 'PLATFORM_FEES',

        },
        {
          id: 'ZoneSetting',
          title: 'Zone Setting',
          type: 'item',
          url: '/zone-setting',
          routerKey: 'ZONE_SETTING',

        },
        {
          id: 'DeliveryCharge',
          title: 'Delivery Charge',
          type: 'item',
          url: '/delivery-charge',
          routerKey: 'DELIVERY_CHARGE',

        },
        {
          id: 'DistanceSetting',
          title: 'Distance Setting',
          type: 'item',
          url: '/distance-setting',
          routerKey: 'DISTANCE_SETTING',

        },
        {
          id: 'DeliveryChargeSurge',
          title: 'Delivery Charge Surge',
          type: 'item',
          url: '/delivery-charge-surge',
          routerKey: 'DELIVERY_CHARGE_SURGE',

        },
        {
          id: 'AdminPanelSetting',
          title: 'Admin Panel Setting',
          type: 'item',
          url: '/admin-panel-setting',
          routerKey: 'ADMIN_PANEL_SETTING',

        },
      ]
    },
    {
      id: 'PickDropSettings ',
      title: 'Pick Drop Settings ',
      type: 'group',
      inactive_icon: <InactivePickDropIcon />,
      active_icon: <ActivePickDropIcon />,
      // url: '/',
      isChildren: true,
      children: [
        {
          id: 'PickDropZoneSetting',
          title: 'Zone Setting',
          type: 'item',
          url: '/pick-drop-zone-setting',
          routerKey: 'PICK_DROP_ZONE_SETTING',
        },
        {
          id: 'PickDropServiceCharge',
          title: 'Service Charge',
          type: 'item',
          url: '/pick-drop-service-charge',
          routerKey: 'PICK_DROP_SERVICE_CHARGE',
        },
        {
          id: 'PickDropDistanceSetting',
          title: 'Distance Setting',
          type: 'item',
          url: '/pick-drop-distance-setting',
          routerKey: 'PICK_DROP_DISTANCE_SETTING',
        },
        {
          id: 'PickDropDeliveryCharge',
          title: 'Delivery Charge',
          type: 'item',
          url: '/pick-drop-delivery-charge',
          routerKey: 'PICK_DROP_DELIVERY_CHARGE',
        },
        // {
        //   id: 'PickDropPlatformFees',
        //   title: 'Platform Fees',
        //   type: 'item',
        //   url: '/pick-drop-platform-fees',
        //   routerKey: 'PICK_DROP_PLATFORM_FEES',
        // },
        // {
        //   id: 'PickDropGST',
        //   title: 'GST',
        //   type: 'item',
        //   url: '/pick-drop-gst',
        //   routerKey: 'PICK_DROP_GST',
        // },
      ]
    },
    {
      id: 'Reports ',
      title: 'Reports ',
      type: 'group',
      inactive_icon: <InactiveAccessControlIcon />,
      active_icon: <ActiveAccessControlIcon />,
      isChildren: false,

    }

  ],

  frItems: [

  ]
}