import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import axiosInstance from "../../../axios";
import Swal from "sweetalert2";
import PopMesseges from "../../../Utils/popMesseges.json"
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import SingleImageDropzone from "../../../Components/GlobalComponents/CustomImageDropzone/SingleImageDropzone";
import EyeHideSvg from "../../../assets/images/svg/eye-hide.svg"
import EyeSvg from "../../../assets/images/svg/eye.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as actionTypes from "../../../ReduxManager/actions";
import config from "../../../config";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';



const ProfileEditPage = ({
	reduxUpdatedAdmin,
	reduxAdminUserId,
	showProfileEdit,
	handleCloseProfileEdit = () => { },
}) => {

	// ------------------ city states --------------------
	const [cityname, setCityName] = useState("");

	// ----------- error validation ----------
	const [validation, setValidation] = useState(null);
	const [errors, setErrors] = useState({});

	// ------------------ add users states --------------------
	const [UserName, setUserName] = useState('');
	const [MobileNo, setMobileNo] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [userStatus, setUserStatus] = useState(false);

	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	// -------------------------- upload progress state ------------------------
	const [uploadProgress, setUploadProgress] = useState(0);

	// -------------------------- singel image upload state ------------------------
	const [selectedImage, setSelectedImage] = useState(null);
	const [image, setImage] = useState([])


	console.log("image", selectedImage);



	const getAdminUserDetails = async () => {
		try {
			const response = await axiosInstance.get(`/admin/getAdminUserDetailsById/${reduxAdminUserId}`);
			console.log("adminUserData=====>>>", response.data.data);
			setUserName(response.data.data.name)
			setMobileNo(response.data.data.phone)
			setEmail(response.data.data.email)
			setCityName(response.data.data.city)
			setUserStatus(response.data.data.status)
			if (response.data.data.avatar) {
				const imageCollectionObject = { "imageName": response.data.data.avatar, "imagePath": `${config.serverImageUrl}${response.data.data.avatar}` }
				setImage(imageCollectionObject);
			}
		} catch (error) {
			console.log("e=>", error);
		}
	}
	useEffect(() => {
		getAdminUserDetails()
	}, [])




	// ---------- add new user ------------
	const updateAdminUser = async (e) => {
		e.preventDefault();
		const validationErrors = {};
		let emailFlag = false;
		let passwordFlag = false;
		let confirmPasswordFlag = false;

		if (!email) {
			validationErrors.email = 'Email is required';
		} else {
			if (!/\S+@\S+\.\S+/.test(email)) {
				validationErrors.email = 'Invalid email format';
			} else {
				emailFlag = true;
			}
		}

		if (password) {
			if (password.length < 6) {
				validationErrors.password = 'Password must be at least 6 characters long';
			} else {
				if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)) {
					validationErrors.password =
						'Password must contain at least one capital letter, one number, and one special character';
				} else {
					passwordFlag = true;
				}
			}
		}

		if (password.length > 1) {
			validationErrors.confirmPassword = 'Confirm Password is required';
			if (password !== confirmPassword) {
				validationErrors.confirmPassword = 'Passwords do not match';
			} else {
				confirmPasswordFlag = true;
			}
		}
		if (!UserName.trim() || !MobileNo.trim()) {
			Swal.fire(PopMesseges.EMPTY_FILEDS_VALIDATION, '', 'warning');
			setValidation({
				UserName: !UserName.trim(),
				MobileNo: !MobileNo.trim(),
			});
		} else {
			if (Object.keys(validationErrors).length === 0 || (passwordFlag && confirmPasswordFlag)) {
				try {
					const body =
					{
						"name": UserName,
						"email": email,
						"phone": MobileNo,
						"city": cityname,
						"status": userStatus === false ? 0 : 1,
						"password": password
					};
					console.log("body", body);
					const response = await axiosInstance.put(`/admin/updateAdminUserById/${reduxAdminUserId}`, body, {
						onUploadProgress: (progressEvent) => {
							const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							setUploadProgress(progress);
						},
					})
					console.log("addAdminUser===>", response.data)
					if (response.data.status === 1) {
						axiosInstance.get(`/admin/getAdminUserDetailsById/${reduxAdminUserId}`).then((response) => {
							console.log("adminUserData=====>>>", response.data.data);
							// setAdminUserData(response.data.data)
							if (selectedImage === null) {
								reduxUpdatedAdmin(response.data.data.name, response.data.data.email, response.data.data.avatar)
								Swal.fire({
									icon: 'success',
									title: PopMesseges.ADMIN_UPDATED,
									showConfirmButton: false,
									timer: 1000
								})
								setPassword("")
								setConfirmPassword("")
								handleCloseProfileEdit()
							}
							else {
								handleImageUpload(response.data.data.id, response.data.data.name, response.data.data.email)
								reduxUpdatedAdmin(response.data.data.name, response.data.data.email)

							}
						})
					} else {
						Swal.fire(response.data.message.code === "ER_DUP_ENTRY" ? 'Email Already Exists' : "", '', 'warning');
					}
				} catch (error) {
					// Handle the error here...
					console.log('err catch', error);
					Swal.fire(error.response.data.error || error.response.data.message, '', 'warning');
				}
			}
		}
		setErrors(validationErrors);
	}


	// ------------------------------------------- Singel Image Upload Function -----------------------------------
	const handleImageUpload = (id, name, email,) => {
		console.log("AdminfilesId=>", id);
		const image = new FormData();
		console.log("Admin image name", image)
		image.append("image", selectedImage)
		axiosInstance.post(`/admin/saveAdminImageById/${id}`, image, {
			onUploadProgress: (progressEvent) => {
				const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setUploadProgress(progress);
			},
		})
			.then(function (response) {
				console.log("admin image===========>", response.data.data.image_url)
				Swal.fire({
					icon: 'success',
					title: PopMesseges.ADMIN_UPDATED,
					showConfirmButton: false,
					timer: 1000
				})
				const fullUrl = response.data.data.image_url;
				const imageUrl = fullUrl.substring(fullUrl.indexOf('admins'));
				reduxUpdatedAdmin(name, email, imageUrl)
				handleCloseProfileEdit()
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	/*--------------------------------------------------
				 Delete Static Banner 
--------------------------------------------------*/
	const onImageDelete = (imageId) => {
		console.log("onImageDelete", imageId);
		Swal.fire({
			title: PopMesseges.IMAGE_DELETE.TITLE,
			text: PopMesseges.IMAGE_DELETE.TEXT,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				deleteImage(imageId)
			}
		})
	}
	const deleteImage = imageId => {
		axiosInstance.delete(`/admin/deleteAdminImageById/${imageId}`).then((response) => {
			console.log("delete=============>", response)
			Swal.fire({
				icon: 'success',
				title: PopMesseges.IMAGE_DELETE.SUCCESS,
				showConfirmButton: false,
				timer: 1000
			})
			setImage(response);
		})
	}





	return (
		<>
			<Modal
				show={showProfileEdit}
				onHide={handleCloseProfileEdit}
				backdrop="static"
				keyboard={false}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Profile</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={12} className='mb-3'>
							<Form.Group>
								<Form.Label>Name</Form.Label>
								<Form.Control type="text" placeholder="Name"
									value={UserName} onChange={e => setUserName(e.target.value)}
									className={validation?.UserName && "validation-border"}
								/>
								{validation?.UserName &&
									<div className='form-control-validation-text '>Please provide a valid name</div>
								}
							</Form.Group>
						</Col>
						<Col md={12} className='mb-3'>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control type="text" placeholder="Email"
									value={email} onChange={e => setEmail(e.target.value)}
									className={errors?.email && "validation-border"}
								/>
								{errors.email && <span className="form-control-validation-text">{errors.email}</span>}
							</Form.Group>
						</Col>
						{/* <Col md={12} className='mb-3'>
							<Form.Group>
								<Form.Label>Password</Form.Label>
								<Form.Control type="text" placeholder="Password"
									value={password} onChange={e => setPassword(e.target.value)}
									className={errors?.password && "validation-border"}
								/>
								{errors.password && <span className="form-control-validation-text">{errors.password}</span>}
							</Form.Group>
						</Col>
						<Col md={12} className='mb-3'>
							<Form.Group>
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control type="text" placeholder="Password"
									value={password} onChange={e => setPassword(e.target.value)}
									className={errors?.password && "validation-border"}
								/>
								{errors.password && <span className="form-control-validation-text">{errors.password}</span>}
							</Form.Group>
						</Col> */}
						<Col md={6} className='mb-3'>
							<Form.Group>
								<Form.Label>Password</Form.Label>
								<div className='position-relative'>
									<Form.Control
										placeholder="Enter Your Password"
										type={showPassword ? 'text' : 'password'}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className={errors?.password && "validation-border"}
									/>
									<div
										className="eyeimage-logo"
										type="button"
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <LazyLoadImage src={EyeHideSvg} alt="Hide" width={25} /> : <LazyLoadImage src={EyeSvg} alt="Show" />}
									</div>
								</div>
								{errors.password && <span className="form-control-validation-text">{errors.password}</span>}
							</Form.Group>
						</Col>
						<Col md={6} className='mb-3'>
							<Form.Group>
								<Form.Label>Confirm Password</Form.Label>
								<div className='position-relative'>
									<Form.Control
										placeholder="Enter Your Confirm Password"
										type={showConfirmPassword ? 'text' : 'password'}
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										className={errors?.confirmPassword && "validation-border"}
									/>
									<div
										className="eyeimage-logo"
										type="button"
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									>
										{showConfirmPassword ? <LazyLoadImage src={EyeHideSvg} alt="Hide" width={25} /> : <LazyLoadImage src={EyeSvg} alt="Show" />}
									</div>
								</div>
								{errors.confirmPassword && <span className="form-control-validation-text">{errors.confirmPassword}</span>}
							</Form.Group>
						</Col>

						<Col md={12}>
							<Form.Group>
								<Form.Label>Image</Form.Label>
								<SingleImageDropzone
									selectedImage={selectedImage}
									setSelectedImage={setSelectedImage}
									image={image}
									onImageDelete={onImageDelete}
									imageId={reduxAdminUserId}
								/>
							</Form.Group>
						</Col>

						<Col md={12} className='mt-3'>
							<Form.Group>
								<Form.Label>Mobile No</Form.Label>
								<Form.Control type="text" placeholder="Mobile No"
									value={MobileNo} onChange={e => setMobileNo(e.target.value.replace(/[^0-9.]/g, ''))}
									className={validation?.MobileNo && "validation-border"}
								/>
								{validation?.MobileNo &&
									<div className='form-control-validation-text '>Please provide a valid mobile number</div>
								}
							</Form.Group>
						</Col>
						<Col md={12} className="my-2">
							{uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />}
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button className="modal-close-button" onClick={handleCloseProfileEdit}>
						Close
					</Button>
					<Button className="modal-save-button" onClick={updateAdminUser}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapStateToProps = state => {
	return {

		reduxAdminUserId: state.userId,

	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		reduxUpdatedAdmin: (adminname, adminemail, adminimage) =>
			dispatch({
				type: actionTypes.UPDATE_ADMIN,
				payload: { adminName: adminname, adminEmail: adminemail, adminImage: adminimage },
			}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditPage);
