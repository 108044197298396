import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRouter = (props) => {
    const { isAuthorised } = props;
    console.log("user authentication validation", isAuthorised);
    let auth = { 'token': isAuthorised }
    return (
        auth.token ? <Outlet /> : <Navigate to="/login" />
    )
}




const mapStateToProps = state => {
    return {
        isAuthorised: state.isAuthorised,
    }
};

export default connect(mapStateToProps)(PrivateRouter)