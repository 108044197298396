import React, { memo, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import axiosInstance from "../../../axios";
import { formatDate, convertTo12Hour } from "../../../Utils/DateTimeUtils";
import NonVegIcon from "../../../assets/images/order-non-veg-icon.png";
import VegIcon from "../../../assets/images/order-veg-icon.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import OrderPocessingCard from "./OrderPocessingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import { checkImageUrl } from "../../../Utils/checkImageUrl";
import { imageOnErrorHandler } from "../../../Utils/imageErrorHandler";
import NoMenuImageFound from '../../../assets/images/no-menu.png'
import { Grid, LinearProgress } from "@mui/material";
import DeleteButton from "../../../assets/images/svg/icon_delete.svg";
import OederEditedIcon from "../../../assets/images/svg/icon_edited_order.svg";
import Swal from "sweetalert2";
import DeletedItemsFromCart from "../EditOrders/DeletedItemsFromCart";





const OrderDetailsModal = ({
  showOrderDetails, handleCloseOrderDetails, selectedOrderId, reduxCurrencyLogo
}) => {

  // ------------------ OrderDetails States--------------------
  const [OrderDetailsList, setOrderDetailsList] = useState({});
  const [orderCartItem, setOrderCartItem] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [DeliveredTime, setDeliveredTime] = useState({});
  const [orderDate, setOrderDate] = useState('');
  const [orderTime, setOrderTime] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');


  /*--------------------------------------------------
                        OrderDetails Dropdown
    --------------------------------------------------*/
  const getOrderDetails = async (orderId) => {
    console.log("===>");
    try {
      const response = await axiosInstance.get(`/adminOrders/getOrderDetailsByOrderId/${orderId}`);
      console.log("OrderDetails=>", response.data);
      setOrderDetailsList(response.data.data)
      setOrderCartItem(response.data.data?.user_cart_items)
      setOrderStatus(response.data.data.order_status_timings)
      setDeliveredTime(response.data.data.delivered_time)

      /////////////
      const deliverytimestamp = response.data.data.delivery_date && response.data.data.delivery_date;
      const [deliverydatePart, deliverytimePart] = deliverytimestamp && deliverytimestamp.split(' ');
      setDeliveryDate(deliverydatePart)
      setDeliveryTime(deliverytimePart)
      ////////////////////////
      const timestamp = response.data.data.created_at && response.data.data.created_at;
      const [datePart, timePart] = timestamp && timestamp.split(' ');
      setOrderDate(datePart)
      setOrderTime(timePart)

    } catch (e) {
      console.log("e=>", e);
    }
  }

  useEffect(() => {
    if (selectedOrderId) {
      getOrderDetails(selectedOrderId)
    }
  }, [selectedOrderId])


  const calculateTotalDiscount = (subscriptionData, baseDiscount) => {
    if (!subscriptionData || subscriptionData.length === 0) {
      return baseDiscount;
    }

    return subscriptionData.reduce((acc, subDetailsValue) => {
      return acc - (subDetailsValue.discount_amount || 0);
    }, baseDiscount);
  };

  const refundAmountWalletAndCash = (OrderDetailsList.wallet_amount - OrderDetailsList.total_amount - OrderDetailsList.order_edit_refund)


  // ------------------- refund history  -----------------
  const [RefundHistory, setRefundHistory] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = useState(0);

  const getRefundHistoryByOrderId = async (Id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/adminOrders/getEditedOrderRefundHistoryByOrderId/${Id}`,
        {
          onDownloadProgress: (progressEvent) => {
            const progressValue = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setProgress(progressValue);
          },
          // timeout: 10000,
        });
      // console.log("getRefundHistoryByOrderId=====>>>", response.data.data);
      setRefundHistory(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log("e=>", error);
      const errorMsg = error?.message || 'An error occurred';
      Swal.fire({
        title: errorMsg,
        icon: "error",
        text: error.data,
      });
      setIsLoading(false)
      setProgress(0);
    }
  }

  // ---------------- useEffect ---------------
  useEffect(() => {
    if (selectedOrderId && OrderDetailsList.is_order_edited === 1 && OrderDetailsList.is_invoice_edited === 1) {
      getRefundHistoryByOrderId(selectedOrderId);
    }
  }, [OrderDetailsList, selectedOrderId]);


  return (
    <>
      <Modal
        show={showOrderDetails}
        onHide={handleCloseOrderDetails}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="order-custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        </Modal.Body>
        <div className="order-details-modal">
          <Row className="mb-2">
            <Col xl={6} md={12} sm={12} sx={12} className="same-col-height">
              <div className="custom-card resturatn-small-card">
                <div className="resturatn-small-card-title">
                  Order Detail
                </div>
                <div className="custom-resturatn-small-card">
                  <Row>
                    <Col md={12}>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Order ID</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.order_id ? OrderDetailsList.order_id : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">New Order ID</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.new_order_id ? OrderDetailsList.new_order_id : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Restaurant Name</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.shop_name ? OrderDetailsList.shop_name : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Customer Name</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.customer_name ? OrderDetailsList.customer_name : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Customer Mobile</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.user_phone_no ? OrderDetailsList.user_phone_no : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Address</div>
                        <div className="restaurant-info-name">:
                          <span>{OrderDetailsList.customer_building ? OrderDetailsList.customer_building : ''}, </span>
                          <span> {OrderDetailsList.customer_landmark ? OrderDetailsList.customer_landmark : ''}, </span>
                          <span> {OrderDetailsList.customer_map_address ? OrderDetailsList.customer_map_address : ''}</span></div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Delivery Boy</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.transporter_name ? OrderDetailsList.transporter_name : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Delivery Mobile</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.transporter_phone ? OrderDetailsList.transporter_phone : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Note</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.note ? OrderDetailsList.note : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Order Amount</div>
                        <div className="restaurant-info-name">: {reduxCurrencyLogo}{OrderDetailsList.total_amount ? OrderDetailsList.total_amount : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Payment Mode</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.payment_mode ? OrderDetailsList.payment_mode : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Order Type</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.order_type ? OrderDetailsList.order_type : ""}</div>
                      </div>
                      {
                        OrderDetailsList.transaction_id !== '0' &&
                        <div className="restaurant-info">
                          <div className="restaurant-info-title">Transaction Id</div>
                          <div className="restaurant-info-name">: {OrderDetailsList.transaction_id ? OrderDetailsList.transaction_id : ""}</div>
                        </div>
                      }
                      {
                        OrderDetailsList.subscription_data &&
                        OrderDetailsList.subscription_data.map((subValue, sunIndex) => {
                          return (
                            <div className="restaurant-info" key={sunIndex}>
                              <div className="restaurant-info-title">Subscription Id</div>
                              <div className="restaurant-info-name">: {subValue.user_subscription_id ? subValue.user_subscription_id : ""}</div>
                            </div>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xl={6} md={12} sm={12} sx={12} className="same-col-height">
              <div className="custom-card resturatn-small-card">
                <div className="resturatn-small-card-title">
                  Other Detail
                </div>
                <div className="custom-resturatn-small-card">
                  <Row>
                    <Col md={12}>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Create Date Time</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.created_at ? `${formatDate(orderDate)} ${convertTo12Hour(orderTime)}` : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Preparation Time</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.preparation_time ? OrderDetailsList.preparation_time : ""} min</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Delivered Time</div>
                        <div className="restaurant-info-name">: {DeliveredTime ? <span>{DeliveredTime.hours && DeliveredTime.hours}h </span> : ""}
                          {DeliveredTime && <span> {DeliveredTime.minutes && DeliveredTime.minutes}m</span>}
                        </div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Delivery Distance</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.delivery_distance ? `${Math.round(OrderDetailsList.delivery_distance)} KM` : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Delivery Date Time</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.delivery_date ? `${formatDate(deliveryDate)} ${convertTo12Hour(deliveryTime)}` : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Payment Status</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.payment_status ? OrderDetailsList.payment_status : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Total Amount</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.total_amount ? `${reduxCurrencyLogo} ${OrderDetailsList.total_amount}` : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Device Type</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.device ? OrderDetailsList.device : ""}</div>
                      </div>
                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Device Version</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.version ? OrderDetailsList.version : ""}</div>
                      </div>

                      <div className="restaurant-info">
                        <div className="restaurant-info-title">Schedule Status</div>
                        <div className="restaurant-info-name">: {OrderDetailsList.schedule_status === 1 ? 'Yes' : 'No'}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} md={12} sm={12} sx={12} className="mt-3 same-col-height">
              <div className="custom-card resturatn-small-card">
                <div className="resturatn-small-card-title">
                  Order Status
                </div>
                {orderStatus &&
                  <OrderPocessingCard orderStatus={orderStatus} />
                }
              </div>
            </Col>
            <Col md={12} className="mt-3 same-col-height">
              <div className="custom-card resturatn-small-card">
                <div>
                  <Grid container className="resturatn-small-card-title other-details-product-title mb-2">
                    <Grid item md={6} sm={4.5} xs={4.5}>
                      <div className="dsp-dish-quantity">Product Name</div>
                      <div className="is-out-of-stock">Name</div>
                    </Grid>
                    <Grid item md={2} sm={1.3} xs={1.3}>
                      <div className="dsp-dish-quantity text-center">Is Out Of Stock</div>
                      <div className="is-out-of-stock text-end text-center">Stock</div>
                    </Grid>
                    <Grid item md={1} sm={1} xs={1}>
                      <div className="dsp-dish-quantity text-center">Quantity</div>
                      <div className="is-out-of-stock text-end text-center">Qty</div>
                    </Grid>
                    <Grid item md={1} sm={1.5} xs={1.5}>
                      <div className="dsp-dish-quantity text-center">Item Price</div>
                      <div className="is-out-of-stock text-end text-center">IPrice</div>
                    </Grid>
                    <Grid item md={1} sm={1.8} xs={1.8}>
                      <div className="dsp-dish-quantity text-center">Addon Price</div>
                      <div className="is-out-of-stock text-end text-center">APrice</div>
                    </Grid>
                    <Grid item md={1} sm={1.8} xs={1.8}>
                      <div className="dsp-dish-quantity text-end text-center">Total Price</div>
                      <div className="is-out-of-stock text-end text-center">TPrice</div>
                    </Grid>
                  </Grid>

                  {
                    orderCartItem &&
                    orderCartItem.sort((a, b) => {
                      if (a.out_of_stock === "YES" && b.out_of_stock !== "YES") return 1;
                      if (a.out_of_stock !== "YES" && b.out_of_stock === "YES") return -1;
                      return 0;
                    }).map((value, index) => {

                      // const formattedText = value.cart_edit_type.replace(/_/g, ' ').toLowerCase();

                      // ---------- OTY Increment Count ----------------------------
                      const incrementDecrementOty = (value.product_options === null ? value.quantity : value.product_options &&
                        value.product_options.map(val => val.product_option_quantity || "0"))

                      return (
                        <div key={index}>
                          {
                            value.customization_addons === null && value.customization_variant === null
                              ?
                              // ---------------------------------- OLD PRODUCT----------------------------------------- //
                              <div className="px-2">
                                <Grid container className={`position-relative ${value.out_of_stock === "YES" ? "disabled-order-product-row" : ""}`}>
                                  <Grid item md={6} sm={4.5} xs={4.5}>
                                    <div className="order-menu-card-img-section clearfix">
                                      <div className='order-menu-image'>
                                        <LazyLoadImage
                                          src={
                                            value.product_images && value.product_images
                                              ? checkImageUrl(value.product_images) === "Full URL"
                                                ? value.product_images
                                                : `${config.serverImageUrl}${value.product_images}`
                                              : NoMenuImageFound
                                          }
                                          onError={imageOnErrorHandler}
                                        />
                                      </div>
                                      <div>
                                        <div className="category-product-menu-title menu-title-name">
                                          <span>{value.product_category}</span>
                                          {value.product_subcategory && <span>, {value.product_subcategory}</span>}
                                        </div>
                                        <div className="menu-title-name old-product-opstions ps-1">
                                          {value.product_name ? value.product_name : ""}
                                          <span className="ms-1">
                                            {value.product_options && value.product_options.map((val) =>
                                              (`(${val.product_option_name})`)
                                            )}
                                          </span>
                                          <div className="order-price text-start">{value.product_description}</div>
                                          {value.cart_edit_type && (
                                            (value.cart_edit_type === "QTY_INCREMENT" || value.cart_edit_type === "QTY_DECREMENT") &&
                                              (incrementDecrementOty - value.qty_before_edit !== 0) ? (
                                              <span className="product-out-of-stock-status">
                                                {value.cart_edit_type === "QTY_INCREMENT" ? "Quantity Added:" : "Quantity Removed:"}
                                                <span className="ms-1">{Math.abs(incrementDecrementOty - value.qty_before_edit)}</span>
                                              </span>
                                            ) : (
                                              value.cart_edit_type === "MODIFIED" ? (
                                                <span className="product-out-of-stock-status">Modified</span>
                                              ) : (
                                                value.cart_edit_type === "ADDED" ? (
                                                  <span className="product-out-of-stock-status">New Item Added</span>
                                                ) : null
                                              )
                                            )
                                          )}
                                          {value?.product_discount !== 0 && value.product_options === null && (<span className="product-percent-type">
                                            {value?.product_discount_type !== "percentage" && reduxCurrencyLogo}{value?.product_discount}{value?.product_discount_type === "percentage" && '%'} OFF
                                          </span>)}
                                          <span className="product-food-type-icon">
                                            {
                                              value.food_type && value.food_type === "non-veg" ?
                                                <LazyLoadImage src={NonVegIcon} /> :
                                                <LazyLoadImage src={VegIcon} />
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="my-1 ps-1">
                                      {value.product_addons &&
                                        <div className="order-details-addon-text">Addons :</div>
                                      }
                                      {value.product_addons && value.product_addons.map((valueAddon, index) => {
                                        return (
                                          <div className="addon-text-title" key={index}>{valueAddon.product_addon_name ? valueAddon.product_addon_name : ""}:
                                            <span className="ms-1">{valueAddon.product_addon_quantity ? valueAddon.product_addon_quantity : ""} </span>
                                            <span>x {reduxCurrencyLogo}{valueAddon.product_addon_price ? valueAddon.product_addon_price : ""} </span>
                                            <span>
                                              {
                                                valueAddon.Product_addon_out_of_stock === "YES" &&
                                                <FontAwesomeIcon icon={faDotCircle} Gridor="#d12127" />
                                              }
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </Grid>
                                  <Grid item md={2} sm={1.3} xs={1.3}>
                                    <div className="dsp-dish-price">
                                      <div>
                                        {value.product_options === null ?
                                          <div className={`${value.out_of_stock === "YES" && "out_of_stock_yes_oragen_text"}`}>{value.out_of_stock}</div>
                                          : value.product_options.map((val) =>
                                          (
                                            <div key={index} className={`${val.Product_option_out_of_stock === "YES" && "out_of_stock_yes_oragen_text"}`}>
                                              {val.Product_option_out_of_stock}
                                            </div>)
                                          )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1} xs={1}>
                                    <div className="dsp-dish-price">
                                      {value.product_options === null ? value.quantity : value.product_options &&
                                        value.product_options.map((val, index) => {
                                          return (
                                            <div key={index}>{val.product_option_quantity ? val.product_option_quantity : "0"}</div>
                                          )
                                        })}
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1.5} xs={1.5}>
                                    <div className={`dsp-dish-price ${value.product_discount !== 0 && value.product_options === null && "product-text-line-through"}`}>
                                      {value.product_options === null ? `${reduxCurrencyLogo}${value.product_price}` : value.product_options.map((val) => {
                                        return (
                                          <div key={index}>{reduxCurrencyLogo}{val.product_option_price}</div>
                                        )
                                      }) || '0'}
                                    </div>
                                    {value.product_discount !== 0 && value.product_options === null &&
                                      (<div className="dsp-dish-price">{reduxCurrencyLogo}{value?.product_original_price}</div>)
                                    }
                                  </Grid>
                                  <Grid item md={1} sm={1.8} xs={1.8}>
                                    <div className="dsp-dish-price">
                                      {reduxCurrencyLogo}
                                      {value.product_addons && value.product_addons.length > 0
                                        ? value.product_addons.reduce((total, valueAddon) => {
                                          return total + (valueAddon.product_addon_price ? parseFloat(valueAddon.product_addon_price) : 0);
                                        }, 0)
                                        : 0}
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1.8} xs={1.8}>
                                    <div className="dsp-dish-price">
                                      {
                                        value.product_discount !== 0 && value.product_options === null ? (
                                          (() => {
                                            const originalPrice = parseFloat(value.product_original_price) || 0;
                                            const quantity = parseFloat(value.quantity) || 0;
                                            const totalOriginalPrice = originalPrice * quantity;
                                            return (
                                              <div>{reduxCurrencyLogo}{totalOriginalPrice}</div>
                                            );
                                          })()
                                        ) : value.product_options === null ? (
                                          (() => {
                                            const productPrice = parseFloat(value.product_price) || 0;
                                            const quantity = parseFloat(value.quantity) || 0;
                                            const totalPrice = productPrice * quantity;
                                            return (
                                              <div>{reduxCurrencyLogo}{totalPrice}</div>
                                            );
                                          })()
                                        ) : (
                                          value.product_options.map((val, index) => {
                                            const optionPrice = parseFloat(val.product_option_price) || 0;
                                            const optionQuantity = parseFloat(val.product_option_quantity) || 0;
                                            const totalPrice = optionPrice * optionQuantity;
                                            return (
                                              <div key={index}>{reduxCurrencyLogo}{totalPrice}</div>
                                            );
                                          })
                                        )
                                      }
                                    </div>
                                  </Grid>
                                </Grid>
                                <hr />
                              </div>
                              :
                              // ---------------------------------- NEW PRODUCT----------------------------------------- //
                              <div className="px-2">
                                <Grid container className={`position-relative ${value.out_of_stock === "YES" ? "disabled-order-product-row" : ""}`}>
                                  <Grid item md={6} sm={4.5} xs={4.5}>
                                    <div className="order-menu-card-img-section clearfix">
                                      <div className={`order-menu-image me-2`}>
                                        <LazyLoadImage src={value.product_images && value.product_images
                                          ? checkImageUrl(value.product_images) === "Full URL"
                                            ? value.product_images
                                            : `${config.serverImageUrl}${value.product_images}`
                                          : NoMenuImageFound}
                                          onError={imageOnErrorHandler}
                                        />
                                      </div>
                                      <div>
                                        <div className="category-product-menu-title menu-title-name">
                                          <span>{value.product_category}</span>
                                          {value.product_subcategory && <span>, {value.product_subcategory}</span>}
                                        </div>
                                        <div className="menu-title-name">{value.product_name ? value.product_name : ""}
                                          {value.customization_variant === null ?
                                            <>
                                              <span className="product-percent-type">
                                                {value?.product_discount_type !== "percentage" && reduxCurrencyLogo}{value?.product_discount}{value?.product_discount_type === "percentage" && '%'} OFF
                                              </span>
                                              <span className="product-food-type-icon">
                                                {
                                                  value.food_type && value.food_type === "non-veg" ?
                                                    <LazyLoadImage src={NonVegIcon} /> :
                                                    <LazyLoadImage src={VegIcon} />
                                                }
                                              </span>
                                            </>
                                            :
                                            value.customization_variant.map((variantVal, index) => {
                                              return (
                                                <>
                                                  <span className="product-percent-type">
                                                    {value?.product_discount_type !== "percentage" && reduxCurrencyLogo}{value?.product_discount}{value?.product_discount_type === "percentage" && '%'} OFF
                                                  </span>
                                                  <span className="product-food-type-icon" key={index}>
                                                    {
                                                      variantVal.food_type && variantVal.food_type === "non-veg" ?
                                                        <LazyLoadImage src={NonVegIcon} /> :
                                                        <LazyLoadImage src={VegIcon} />
                                                    }
                                                  </span>
                                                </>
                                              )
                                            })}
                                        </div>
                                        <div className="order-price text-start">{value.product_description}</div>
                                      </div>
                                    </div>
                                    <div className="menu-variation ps-1 mt-1">
                                      {
                                        value.customization_variant &&
                                        <div className="order-details-addon-text">
                                          Variation :
                                          <div className="veg-non-veg-card ms-1">
                                            <div>
                                              {value.customization_variant &&
                                                value.customization_variant.map((variantVal, index) => {
                                                  return (
                                                    <div key={index}>
                                                      <div className="dsp-detail-dish-type" key={index}>Type: {variantVal.customization_option_name}</div>
                                                      <div className="dsp-detail-dish-type">Price: {reduxCurrencyLogo}{variantVal.customization_price}</div>
                                                    </div>
                                                  )
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    <div className="my-1 ps-1">
                                      {value.customization_addons &&
                                        <div className="order-details-addon-text">Addons :</div>
                                      }
                                      {value.customization_addons && value.customization_addons.map((valueAddon, index) => {
                                        return (
                                          <div className="addon-text-title" key={index}>{valueAddon.customization_option_name ? valueAddon.customization_option_name : ""}:
                                            <span className="ms-1">{valueAddon.customization_quantity ? valueAddon.customization_quantity : ""} </span>
                                            <span>x {reduxCurrencyLogo}{valueAddon.customization_price ? valueAddon.customization_price : ""} </span>
                                            <span>
                                              {
                                                valueAddon.out_of_stock === "YES" &&
                                                <FontAwesomeIcon icon={faDotCircle} Gridor="#d12127" />
                                              }
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </Grid>
                                  <Grid item md={2} sm={1.3} xs={1.3}>
                                    <div className="dsp-dish-price">
                                      <div>
                                        {value.product_options === null ?
                                          <div className={`${value.out_of_stock === "YES" && "out_of_stock_yes_oragen_text"}`}>{value.out_of_stock}</div>
                                          : value.product_options.map((val) =>
                                          (
                                            <div key={index} className={`${val.Product_option_out_of_stock === "YES" && "out_of_stock_yes_oragen_text"}`}>
                                              {val.Product_option_out_of_stock}
                                            </div>)
                                          )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1} xs={1}>
                                    <div className="dsp-dish-price">
                                      {value.customization_variant === null ? value.quantity : value.customization_variant &&
                                        value.customization_variant.map((val, index) => {
                                          return (
                                            <div key={index}>{val.customization_quantity ? val.customization_quantity : "0"}</div>
                                          )
                                        })}
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1.5} xs={1.5}>
                                    <div className="dsp-dish-price">
                                      {value.customization_variant === null ? (`${reduxCurrencyLogo}${value.product_price}`) : value.customization_variant &&
                                        value.customization_variant.map((variantVal, index) => {
                                          return (
                                            <div key={index}>{reduxCurrencyLogo}{variantVal.customization_price ? variantVal.customization_price : "0"}</div>
                                          )
                                        })}
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1.8} xs={1.8}>
                                    <div className="dsp-dish-price">
                                      {reduxCurrencyLogo}{value.customization_addon_total ? value.customization_addon_total : "0"}
                                    </div>
                                  </Grid>
                                  <Grid item md={1} sm={1.8} xs={1.8}>
                                    <div className="dsp-dish-price">
                                      {value.customization_variant === null ?
                                        (() => {
                                          const itemPrice = parseFloat(value.product_price) || 0;
                                          const addonPrice = parseFloat(value.customization_addon_total) || 0;
                                          const quantity = parseFloat(value.quantity) || 0;
                                          const totalPrice = (itemPrice + addonPrice) * quantity;
                                          return (
                                            <div>{reduxCurrencyLogo}{totalPrice}</div>
                                          );
                                        })()
                                        :
                                        value.customization_variant.map((variantVal, index) => {
                                          const itemPrice = parseFloat(variantVal.customization_price) || 0;
                                          const addonPrice = parseFloat(value.customization_addon_total) || 0;
                                          const quantity = parseFloat(variantVal.customization_quantity) || 0;
                                          const totalPrice = (itemPrice + addonPrice) * quantity;
                                          return (
                                            <div key={index}>{reduxCurrencyLogo}{totalPrice}</div>
                                          );
                                        })
                                      }
                                    </div>
                                  </Grid>
                                </Grid>
                                <hr />
                              </div>
                          }
                        </div>
                      )
                    })
                  }

                  {/* ------------------------ Old Product Deleted Item --------------------- */}
                  {
                    OrderDetailsList.is_order_edited === 1 && OrderDetailsList.is_invoice_edited === 1 &&
                    <DeletedItemsFromCart
                      orderId={selectedOrderId}
                      reduxCurrencyLogo={reduxCurrencyLogo}
                      sourcePage="detailsPage"
                    />
                  }
                </div>



                <Row className="main-price-section">
                  <Col md={6} className="order-price-section my-2">
                    <div className="px-4">
                      <div className="order-price-inside-section">
                        <div className="order-ammount-title">Items Total:</div>
                        <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList?.sub_total + OrderDetailsList?.total_product_discount}</div>
                      </div>
                      <div className="order-price-inside-section">
                        <div className="order-ammount-title">Subtotal:</div>
                        <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList.sub_total ? Math.round(OrderDetailsList.sub_total) : "0"}</div>
                      </div>
                      {/* <div className="order-price-inside-section">
                        <div className="order-ammount-title">Custom Discount:</div>
                        <div className="order-price">
                          -{reduxCurrencyLogo}
                          {calculateTotalDiscount(OrderDetailsList.subscription_data, OrderDetailsList.discount)}
                        </div>
                      </div> */}
                      {OrderDetailsList?.total_product_discount !== 0 && <div className="order-price-inside-section">
                        <div className="order-ammount-title order-discount-ammount-title">Product Discount:</div>
                        <div className="order-price order-discount-ammount-title">
                          -{reduxCurrencyLogo}{Math.round(OrderDetailsList?.total_product_discount)}
                        </div>
                      </div>}
                      {OrderDetailsList?.promocode_discount !== 0 &&
                        <div className="order-price-inside-section">
                          <div className="order-ammount-title order-discount-ammount-title">Discount ({OrderDetailsList.promo_code}):</div>
                          <div className="order-price order-discount-ammount-title">
                            -{reduxCurrencyLogo}{Math.round(OrderDetailsList?.promocode_discount)}
                          </div>
                        </div>}
                      <div className="order-price-inside-section">
                        <div className="order-ammount-title">GST/TAX:</div>
                        <div className="order-price">+{reduxCurrencyLogo}{OrderDetailsList.tax ? Math.round(OrderDetailsList.tax) : "0"}</div>
                      </div>
                      <div className="order-price-inside-section">
                        <div className="order-ammount-title">Platform Fee:</div>
                        <div className="order-price">+{reduxCurrencyLogo}{OrderDetailsList.platform_fee ? Math.round(OrderDetailsList.platform_fee) : "0"}</div>
                      </div>
                      <div>
                        {
                          OrderDetailsList.subscription_data && OrderDetailsList.subscription_data.length > 0 ?
                            (OrderDetailsList.subscription_data.map((subDetailsValue, sunDetailsIndex) => {
                              const freedeliveryWithSubscription = OrderDetailsList?.delivery_charge - subDetailsValue?.discount_amount
                              return (
                                <div className="subscription-payment-card text-black fs-14 mb-2" key={sunDetailsIndex}>
                                  <div className="dsp-total-amount-card">
                                    <div className="order-ammount-title payment-clickable">Delivery Charge:</div>
                                    <div className="order-price">{freedeliveryWithSubscription === 0 ? 'FREE' :
                                      freedeliveryWithSubscription ? `+${reduxCurrencyLogo}${Math.round(freedeliveryWithSubscription)}` : `+
                                    ${reduxCurrencyLogo}${OrderDetailsList?.delivery_charge && Math.round(OrderDetailsList?.delivery_charge)}`}</div>
                                  </div>
                                  {
                                    <div className="payment-card-hover-card">
                                      <div className="delivery-fee-breakup-text order-ammount-title">Delivery fee breakup for this order</div>
                                      <div className="dsp-total-amount-card">
                                        <div className="dsp-dish-price">Delivery Charge:</div>
                                        <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList?.delivery_charge ? Math.round(OrderDetailsList?.delivery_charge) : "0"}</div>
                                      </div>
                                      {
                                        subDetailsValue?.discount_amount &&
                                        <div className="dsp-total-amount-card">
                                          <div className="order-ammount-title subscription-discount-text">Subscription Discount:</div>
                                          <div className="order-price subscription-discount-text">-{reduxCurrencyLogo}{subDetailsValue?.discount_amount ? Math.round(subDetailsValue?.discount_amount) : ""}</div>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              )
                            })
                            )
                            :
                            (<div className="order-price-inside-section">
                              <div className="order-ammount-title">Delivery Charge:</div>
                              <div className="order-price">+{reduxCurrencyLogo}{OrderDetailsList.delivery_charge ? Math.round(OrderDetailsList.delivery_charge) : "0"}</div>
                            </div>)
                        }
                      </div>
                      {
                        OrderDetailsList?.wallet_amount !== 0 &&
                        <div>
                          <div className="order-price-inside-section">
                            <div className="order-ammount-title">Wallet Deduction:</div>
                            <div className="order-price">-{reduxCurrencyLogo}{OrderDetailsList.wallet_amount ? Math.round(OrderDetailsList.wallet_amount) : "0"}</div>
                          </div>
                        </div>
                      }

                      <div className="order-price-inside-section">
                        <div className="order-ammount-title">Paid Via {(OrderDetailsList?.payment_mode || "").replace("cashfree", "online")}:</div>
                        <div className="order-price">
                          {reduxCurrencyLogo}{OrderDetailsList.payment_mode === "wallet" ?
                            Math.round(OrderDetailsList.wallet_amount) : Math.round(OrderDetailsList.payable)}
                        </div>
                      </div>

                      {/* ------------------------------------------------- Refunnd Amount ---------------------------------------------- */}
                      <div>
                        {OrderDetailsList.is_order_edited === 1 && OrderDetailsList.is_invoice_edited === 1 &&
                          OrderDetailsList.order_edit_refund > 0 &&
                          <div className="subscription-payment-card text-black fs-14 mb-2">
                            <div className="dsp-total-amount-card">
                              <div className="order-ammount-title payment-clickable">Refunded:</div>
                              <div className="order-price">-{reduxCurrencyLogo}{Math.round(OrderDetailsList.order_edit_refund)}</div>
                            </div>
                            {
                              <div className="payment-card-hover-card refund-to-wallet">
                                <div className="delivery-fee-breakup-text order-ammount-title">Refund Added To Wallet</div>
                                {isLoading && <LinearProgress variant="determinate" value={progress} />}
                                {
                                  RefundHistory && RefundHistory.map((refundValue, refundIndex) => {
                                    const deletedAt = refundValue.created_at;
                                    const [date, time] = deletedAt ? deletedAt.split(" ") : ["", ""];
                                    return (
                                      <div className="dsp-total-amount-card" key={refundIndex}>
                                        <div className="dsp-dish-price">{formatDate(date)} {convertTo12Hour(time)}</div>
                                        <div className="order-price">{reduxCurrencyLogo}{refundValue.amount ? Math.round(refundValue.amount) : "0"}</div>
                                      </div>
                                    )
                                  })
                                }
                                <div className="dsp-total-amount-card">
                                  <div className="dsp-dish-price">Total Amount:</div>
                                  <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList.order_edit_refund ? Math.round(OrderDetailsList.order_edit_refund) : "0"}</div>
                                </div>
                              </div>
                            }
                          </div>
                        }
                      </div>

                      {/* ------------------------------------------------- Collect Cash ------------------------------------------------ */}
                      <div>
                        {OrderDetailsList.is_order_edited === 1 && OrderDetailsList.is_invoice_edited === 1 &&
                          (OrderDetailsList.is_collect_cash_applicable === 1 &&
                            OrderDetailsList.applicable_collect_cash_amount !== null &&
                            OrderDetailsList.applicable_collect_cash_amount !== 0 &&
                            <div className="subscription-payment-card text-black fs-14 mb-2">
                              <div className="dsp-total-amount-card">
                                <div className="order-ammount-title payment-clickable collect-cash-text">Collect Cash: </div>
                                <div className="order-price collect-cash-text">{reduxCurrencyLogo}
                                  {OrderDetailsList.applicable_collect_cash_amount ? Math.round(Math.abs(OrderDetailsList.applicable_collect_cash_amount)) : "0"}
                                </div>
                              </div>
                              <div className="payment-card-hover-card refund-to-wallet">
                                <div className="delivery-fee-breakup-text order-ammount-title collect-cash-text">Collect Cash For Quantity Added</div>
                                <div className="dsp-total-amount-card">
                                  <div className="dsp-dish-price collect-cash-text">Amount:</div>
                                  <div className="order-price collect-cash-text">{reduxCurrencyLogo}
                                    {OrderDetailsList.applicable_collect_cash_amount ? Math.round(Math.abs(OrderDetailsList.applicable_collect_cash_amount)) : "0"}
                                  </div>
                                </div>
                              </div>
                            </div>)
                        }
                      </div>

                      <div className="horizontal-line mt-2"></div>
                      <div className="order-price-inside-section mt-3">
                        <div className="order-ammount-title">Total Paid:</div>
                        <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList.total_amount ? Math.round(OrderDetailsList.total_amount) : "0"}</div>
                      </div>

                      {
                        OrderDetailsList.amount_before_order_edit !== null &&
                        <div className="order-price-inside-section mt-2">
                          <div className="order-ammount-title">Payable:</div>
                          <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList.amount_before_order_edit ? Math.round(OrderDetailsList.amount_before_order_edit) : "0"}</div>
                        </div>
                      }

                      {/* ------------------------------------------------- Reaming Amount ------------------------------------------------ */}
                      {
                        OrderDetailsList.is_order_edited === 1 &&
                        OrderDetailsList.is_invoice_edited === 1 &&
                        OrderDetailsList.is_refund_applicable !== null &&
                        OrderDetailsList.is_refund_applicable !== 0 &&
                        <div className="order-price-inside-section mt-3">
                          <div className="order-ammount-title">Reaming Amount:</div>
                          <div className="order-price">{reduxCurrencyLogo}{OrderDetailsList.applicable_refund_amount ? Math.round(OrderDetailsList.applicable_refund_amount) : "0"}</div>
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Modal.Footer>
          <Button className="modal-close-button" onClick={handleCloseOrderDetails}>
            Close
          </Button>
          {/* <Button className="modal-save-button" onClick={handleCloseOrderDetails}>Save</Button> */}
        </Modal.Footer>
      </Modal >
    </>
  )
}


const mapStateToProps = state => {
  return {

    reduxCurrencyLogo: state.currencyLogo,

  }
};

export default connect(mapStateToProps, null)(memo(OrderDetailsModal))
