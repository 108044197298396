import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DisputeModalDataTable from "./DisputeModalDataTable";
import Swal from "sweetalert2";
import axiosInstance from "../../../axios";




const DisputeModal = ({ showDispute, handleCloseDispute = () => { }, selectedOrderId }) => {


    // ------------------- useStates -----------------
    const [DisputeTable, setDisputeTable] = useState([]);
    const [DisputeTableUpdated, setDisputeTableUpdated] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const getDisputes = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/adminOrders/getDisputeDetailsByOrderId/${selectedOrderId}`);
            console.log("Disputes=====>>>", response.data.data);
            setDisputeTable(response.data.data || []);
            setIsLoading(false);
        } catch (error) {
            console.log("e=>", error);
            const errorMsg = error?.message || 'An error occurred';
            Swal.fire({
                title: errorMsg,
                icon: "error",
                text: error.data,
            });
            setIsLoading(false);
        }
    }

    // ---------------- useEffect
    useEffect(() => {
        if (selectedOrderId) {
            getDisputes();
        }
    }, [DisputeTableUpdated]);


    return (
        <Modal
            show={showDispute}
            onHide={handleCloseDispute}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="order-custom-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Disputes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            </Modal.Body>
            <div className="order-details-modal">
                <DisputeModalDataTable DisputeTable={DisputeTable}
                    setDisputeTableUpdated={setDisputeTableUpdated}
                    isLoading={isLoading} />
            </div>

            <Modal.Footer>
                <Button className="modal-close-button" onClick={handleCloseDispute}>
                    Close
                </Button>
                {/* <Button className="modal-save-button" onClick={handleCloseDispute}>Save</Button> */}
            </Modal.Footer>
        </Modal >
    )
}


export default DisputeModal 