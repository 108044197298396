// dateUtils.js
import moment from 'moment';



export function formatDate(dateString) {
    return moment(dateString).format("DD-MM-YYYY");
}

export function formatTime(timeString) {
    return moment(timeString).format("LTS");
}

export function formatHourMinutTime(hourMinutString) {
    return hourMinutString.format("HH:mm")
}

export function formatHourMinutSecondTime(hourMinutString) {
    return hourMinutString.format("HH:mm:ss")
}


export function convertTo12Hour(time24h) {
    const [hoursStr, minutesStr] = time24h.split(':');
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    const amPm = hours >= 12 ? 'PM' : 'AM';

    if (hours === 0) {
        hours = 12;
    } else if (hours > 12) {
        hours -= 12;
    }

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
}

// Example usage:
// const time24Hour = '19:56'; // Replace this with your 24-hour time
// const time12Hour = convertTo12Hour(time24Hour);



// Example usage:
// const time24Hour = convertTo24Hour(time12Hour);
// console.log(`Converted to 24-hour format: ${time24Hour}`);
