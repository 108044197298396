
import Swal from "sweetalert2";
import axiosInstance from "../axios";

// Utility function to fetch city data and dispatch to Redux
export const fetchAndUpdateCityData = async (setCityDataInRedux) => {
    try {
        const response = await axiosInstance.get(`/admin/getActiveZones`);
        const formattedCityOptions = response.data.data && response.data.data.map(cityvalue => ({
            value: cityvalue.city,
            label: cityvalue.city.toLowerCase(),
            id: cityvalue.id
        }));
        // Add the "All" option
        const allOption = { label: "All", value: "ALL", id: "ALL" };
        const cityNameOptionsAll = [allOption, ...formattedCityOptions];

        // Dispatch to Redux
        setCityDataInRedux(cityNameOptionsAll);

    } catch (error) {
        console.error("Error fetching updated zones/cities:", error);
        Swal.fire({
            title: error.message || 'Error fetching updated data',
            icon: "error",
        });
    }
};
