import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DropzoneIcon from '../../../assets/images/svg/icon-Image-dropzone.svg';
import DropzonePlusIcon from '../../../assets/images/svg/icon-image-dropzone-plusbtn.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row } from 'react-bootstrap';
import config from '../../../config';
import Swal from 'sweetalert2';

const SingleImageDropzone = ({ onImageDelete = () => { }, selectedImage, setSelectedImage, image, imageId }) => {
	const fileInputRef = useRef(null);

	const handleDrop = (e) => {
		console.log("test-5");
		if (image.imagePath) {
			console.log("errror");
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'You have already selected an image. Please remove it before adding a new one.',
			});
		} else {
			e.preventDefault();
			const file = e.dataTransfer.files[0];
			if (file) {
				setSelectedImage(file);
			}
		}
	};

	const handleDragOver = (e) => {
		console.log("test-1");
		e.preventDefault();
	};

	const handleFileInputChange = (e) => {
		console.log("test-2");
		const file = e.target.files[0];
		if (file) {
			setSelectedImage(file);
		}
	};

	const handleRemove = (e) => {
		e.preventDefault()
		console.log("test-3");
		setSelectedImage(null);
	};



	const openFileInput = () => {
		console.log("test-4");
		if (image.imagePath) {
			console.log("errror");
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'You have already selected an image. Please remove it before adding a new one.',
			});
		} else {
			fileInputRef.current.click();
		}
	};




	return (
		<div className='image-drop-zone-card'>
			<div
				id="drop-zone"
				onDragOver={handleDragOver}
				onDrop={handleDrop}
				onClick={openFileInput}
			>
				<div>
					<LazyLoadImage src={DropzoneIcon} />
					<div className='image-dragdrop-text'>Browse and choose the file you want to upload from your computer</div>
					<LazyLoadImage src={DropzonePlusIcon} />
				</div>
				<input
					type="file"
					id="file-input"
					accept="image/*"
					onChange={handleFileInputChange}
					ref={fileInputRef}
					style={{ display: 'none' }}
				/>
			</div>
			{selectedImage && (
				<Row className='mt-4'>
					<Col md={12} className='d-flex justify-content-center'>
						<div className='drop-zone-image-card'>
							<div className='drop-zone-image singal-imgae-dropzone'>
								<img
									src={URL.createObjectURL(selectedImage)}
									alt={`Selected Image`}
								/>
							</div>
							<Button className='drop-zone-image-cancel-button' onClick={(e) => { handleRemove(e) }}>
								<FontAwesomeIcon icon={faXmark} />
							</Button>
						</div>
					</Col>
				</Row>
			)
			}
			{
				image.imagePath &&
				<Row className='mt-4'>
					<Col md={12} className='d-flex justify-content-center'>
						<div className='drop-zone-image-card'>
							<div className='drop-zone-image singal-imgae-dropzone'>
								<img
									src={image.imagePath}
									alt={`Selected Image`}
								/>
							</div>
							<Button className='drop-zone-image-cancel-button' onClick={() => onImageDelete(imageId)}>
								<FontAwesomeIcon icon={faXmark} />
							</Button>
						</div>
					</Col>
				</Row>
			}
		</div >


	);
};

export default SingleImageDropzone;

