
export const ADMIN_AUTHORIZED = 'ADMIN_AUTHORIZED';
export const REDUX_CITY_DATA = 'REDUX_CITY_DATA';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_SIDEBAR_STATUS = 'UPDATE_SIDEBAR_STATUS';
export const ADMIN_MASTER_LOADER = '  ADMIN_MASTER_LOADER';



//////////////// Tab Actions ///////////////////
export const USER_TAB_KEY_SET = 'USER_TAB_KEY_SET';
export const DELIVERYBOY_TAB_KEY_SET = 'DELIVERYBOY_TAB_KEY_SET';
export const RESTAURANT_TAB_KEY_SET = 'RESTAURANT_TAB_KEY_SET';
export const RESTAURANT_MENU_TAB_KEY_SET = 'RESTAURANT_MENU_TAB_KEY_SET';
export const ORDERS_TAB_KEY_SET = 'ORDERS_TAB_KEY_SET';
export const CHAT_TAB_KEY_SET = 'CHAT_TAB_KEY_SET';
export const DASHBOARD_TAB_KEY_SET = 'DASHBOARD_TAB_KEY_SET';
export const USER_DISPUTE_TAB_KEY_SET = 'USER_DISPUTE_TAB_KEY_SET';


//////////////// Order Managment Actions ///////////////////
export const REDUX_ORDER_DELIVERY_BOY_DATA = 'REDUX_ORDER_DELIVERY_BOY_DATA';
export const REDUX_ORDER_RESTAURANT_DATA = 'REDUX_ORDER_RESTAURANT_DATA';

export const ORDERED_CITY_NAME = 'ORDERED_CITY_NAME';
export const ORDERED_RESTAURANT_NAME = 'ORDERED_RESTAURANT_NAME';
export const ORDERED_DELIVERY_BOY_NAME = 'ORDERED_DELIVERY_BOY_NAME';
export const ORDERED_DELIVERY_BOY_REFERENCE_NUMBER = 'ORDERED_DELIVERY_BOY_REFERENCE_NUMBER';
export const PENDING_ORDERS_COUNT = 'PENDING_ORDERS_COUNT';
export const ONGOING_ORDERS_COUNT = 'ONGOING_ORDERS_COUNT';
export const COMPLETED_ORDERS_COUNT = 'COMPLETED_ORDERS_COUNT';
export const ACCEPTED_ORDERS_COUNT = 'ACCEPTED_ORDERS_COUNT';
export const CANCELLED_ORDERS_COUNT = 'CANCELLED_ORDERS_COUNT';
export const SCHEDULED_ORDERS_COUNT = 'SCHEDULED_ORDERS_COUNT';





export const ASSIGNED_ORDERS_COUNT = 'ASSIGNED_ORDERS_COUNT';
export const PROCESSING_ORDERS_COUNT = 'PROCESSING_ORDERS_COUNT';
export const FOODREADY_ORDERS_COUNT = 'FOODREADY_ORDERS_COUNT';
export const ARRIVED_ORDERS_COUNT = 'ARRIVED_ORDERS_COUNT';
export const REACHED_ORDERS_COUNT = 'REACHED_ORDERS_COUNT';
export const PICKEDUP_ORDERS_COUNT = 'PICKEDUP_ORDERS_COUNT';








//////////////// Dashboard Managment Actions ///////////////////
export const DASHBOARD_ZONE_NAME = 'DASHBOARD_ZONE_NAME';
export const DASHBOARD_ZONE_NAME_AUTH = 'DASHBOARD_ZONE_NAME_AUTH';




export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';
export const SET_ACTIVE_MENU_ITEM_INDEX = 'SET_ACTIVE_MENU_ITEM_INDEX';
export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';



//////////////// Socket Error ///////////////////
export const ORDER_NOTIFICATION_DATA = 'ORDER_NOTIFICATION_DATA';
export const PICKDROP_ORDER_NOTIFICATION_DATA = 'PICKDROP_ORDER_NOTIFICATION_DATA';
export const WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED';













