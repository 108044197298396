import React, { createContext, useContext, useState, useEffect } from 'react';

const RefreshContext = createContext();

export function RefreshProvider({ children }) {
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const updateRefreshTrigger = () => {
        setRefreshTrigger(!refreshTrigger);
    };

    useEffect(() => {
        // Listen for events that should trigger a refresh
        const handleResize = () => {
            updateRefreshTrigger();
        };

        // Attach event listeners
        window.addEventListener('resize', handleResize);

        // Clean up event listeners
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [refreshTrigger]);

    return (
        <RefreshContext.Provider value={{ refreshTrigger, updateRefreshTrigger }}>
            {children}
        </RefreshContext.Provider>
    );
}

export function useRefresh() {
    return useContext(RefreshContext);
}
